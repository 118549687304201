/* eslint-disable */
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
//
import BlogPage from './Learner/BlogPage';
import UserPage from './pages/UserPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import HostLogin from './pages/HostLogin';
import LearnerLogin from './pages/LearnerLogin';
import StudentLogin from "./pages/StudentLogin";
import AdminLogin from './pages/AdminLogin';
import { getToken } from './services/LocalStorageService';
import Register from './pages/Register';
import ProfileForm from './pages/ProfileForm';
import Home from './pages/Home';
import AdminLayout from './layouts/adminDashboard/AdminLayout';
import HostLayout from './layouts/hostDashboard/HostLayout';
import LearnerLayout from './layouts/learnerDashboard/LearnerLayout';
import TC from './pages/T&C';
import AllUsers from './Admin/AllUsers';
import AllCourses from './Admin/AllCourses';
import CourseDetails from './Learner/CourseDetails';
import AddCourse from './Admin/AddCourse';
import ApplyCourse from './Learner/ApplyCourse';
import Courses from './Host/Courses';
import Requests from './Admin/Requests';
import CreateCourse from './Host/CreateCourse';
import AttendanceMarking from './Learner/Attendance';
import Applicant from './Host/AttendanceMarking';
import ProfileData from './pages/ProfileData';
import SelectedProfile from './Admin/SelectedProfile';
import SendPasswordResetEmail from './pages/SendPasswordResetEmail';
import RequestPending from './Learner/RequestPending';
import MyProfile from './Learner/MyProfile';
import AdminDashboard from './Admin/AdminDashboard';
import LearnerDashboard from './Learner/LearnerDashboard';
import HostDashboard from './Host/HostDashboard';
import ContactForm from './pages/Contact';
import AllLearners from './Admin/AllLearners';
import AllHosts from './Admin/AllHosts';
import AllClients from './Admin/AllClients';
import AboutUs from './pages/AboutUs';
import NewUsers from './Admin/NewUsers';
import SelectedUser from './Admin/SelectedUser';
import HostDetails from './Admin/HostDetails';
import ClientDetails from './Admin/ClientDetails';
import LearnerDetails from './Admin/LearnerDetails';
import ResetPassword from './pages/ResetPassword';
import MaintainCourse from './Admin/MaintainCourse';
import ContinueLearning from './Learner/ContinueLearning';
import AttendanceMonitoring from './Host/AttendanceMonitoring';
import ManageLearners from './Host/ManageLearners';
import HostProfile from './Host/HostProfile';
import AdminProfile from './Admin/AdminProfile';
import AllSubhosts from './Admin/AllSubhosts';
import SubHostDetails from './Admin/SubHostDetails';
import AllAcademies from './Admin/AllAcademies';
import AcademyDetails from './Admin/AcademyDetails';
import UserGroups from './Admin/UserGroups';
import ClientLogin from './pages/ClientLogin';
import AcademicLogin from './pages/AcademyLogin';
import ClientLayout from './layouts/clientLayout/ClientLayout';
import AcademyLayout from './layouts/academyLayout/AcademyLayout';
import ListHosts from './Academy/ListHosts';
import ListCourse from './Academy/ListCourse';
import ListLearners from './Academy/ListLearners';
import Applicants from './Academy/Applicants';
import NewCourse from './Academy/NewCourse';
import MyHosts from './Client/MyHosts';
import MyLearners from './Client/MyLearners';
import Projects from './Client/Projects';
import HostSettings from './Host/HostSettings';
import LearnerSettings from './Learner/LearnerSettings'
import HostsLearners from './Host/HostsLearners'
import ClientProfile from './Client/ClientProfile'
import AttemptQuiz from './Learner/AttemptQuiz'
import EditQuiz from './Admin/EditQuiz'
import UpdateModule from './Admin/UpdateModule'
import { Suspense } from 'react'
import { SplashScreen } from './components/loading-screen'
import MainLayout from './layouts/main'
import SubhostProfile from './subhost/SubhostProfile';
import SubhostLayout from './layouts/subhostLayout';
import SubhostsLearners from './subhost/SubhostLearners';
import ClientCourses from './Client/ClientCourse';
import ClientCourseMaintain from './Client/ClientCourseMaintain';
import ManageCourse from './Host/ManageCourse';
import ClientDashboard from './Client/ClientDashboard';
import AcademyDashboard from './Academy/AcademyDashboard';
import AcademyCourses from './Academy/AcademyCourses';
import AcademyCourseDetails from './Academy/AcademyCourseDetails';
import AcademyProfile from './Academy/AcademyProfile';

export default function Router() {
  // const routes = useRoutes([
    // const CourseRoutes = () => {
    //   const routes = useRoutes ([
    //     {
    //       path: '/learnerpage',
    //       element: <LearnerLayout />,
    //       children: [
    //         { element: <Navigate to="my-course/:slug" />, index: true },
    //         { path: 'app', element: <ContinueLearning /> },
    //       ]
    //   }
    //   ])
    // }
  return (
    <>
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          {/* <Route path="/" exact element={<SimpleLayout />}> */}
          <Route path="/" exact element={<MainLayout />}>
            {/* <Route index element={<Home />} /> */}
            <Route path="" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="contact" element={<ContactForm />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<LearnerLogin />} />
            <Route path="student-login" element={<StudentLogin />} />
            <Route path="host" element={<HostLogin />} />
            <Route path="admin" element={<AdminLogin />} />
            <Route path="client" element={<ClientLogin />} />
            <Route path="academy" element={<AcademicLogin />} />
            {/* <Route path="host" element={<HostLogin />} /> */}
            <Route path="profileDetails" element={<ProfileForm />} />
            {/* <Route path="adminDashboard" element={<AdminDashboard />} /> */}
            {/* <Route path="login" element={!access_token ? <LoginReg /> : <Navigate to="/learner/dashboard" />} /> */}
            <Route path="T&C" element={<TC />} />
            <Route path="sendPasswordResetMail" element={<SendPasswordResetEmail />} />
            <Route path="api/user/reset/:id/:token" element={<ResetPassword />} />
          </Route>

          <Route path="/adminpage" element={<AdminLayout />}>
            <Route path="app" element={<AdminDashboard />} />
            <Route path="learners" element={<AllLearners />} />
            <Route path="learners/:id" element={<LearnerDetails />} />
            <Route path="hosts" element={<AllHosts />} />
            <Route path="subhosts" element={<AllSubhosts />} />
            <Route path="hosts/:id" element={<HostDetails />} />
            <Route path="subhosts/:id" element={<SubHostDetails />} />
            <Route path="editquiz/:id" element={<EditQuiz />} />
            <Route path="updatemodule/:slug/:id" element={<UpdateModule />} />
            <Route path="clients" element={<AllClients />} />
            <Route path="projects" element={<Projects />} />
            <Route path="clients/:id" element={<ClientDetails />} />
            <Route path="academy" element={<AllAcademies />} />
            <Route path="academy/:id" element={<AcademyDetails />} />
            <Route path="users" element={<AllUsers />} />
            <Route path="user/:id" element={<SelectedUser />} />
            <Route path="userGroups" element={<UserGroups />} />
            <Route path="courses" element={<AllCourses />} />
            <Route path="addCourse" element={<AddCourse />} />
            <Route path="course/:slug" element={<MaintainCourse />} />
            <Route path="createCourse" element={<CreateCourse />} />
            <Route path="requests" element={<Requests />} />
            <Route path="userRequests" element={<NewUsers />} />
            <Route path="selectedProfile/:id" element={<SelectedProfile />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="adminProfile" element={<AdminProfile />} />
          </Route>

          <Route path="/clientpage" element={<ClientLayout />}>
            <Route path="app" element={<ClientDashboard />} />
            <Route path="learners" element={<MyLearners />} />
            <Route path="learners/:id" element={<LearnerDetails />} />
            <Route path="courses" element={<ClientCourses />} />
            <Route path="course/:slug" element={<ClientCourseMaintain />} />
            <Route path="updatemodule/:slug/:id" element={<UpdateModule />} />
            <Route path="createCourse" element={<CreateCourse />} />
            <Route path="blog" element={<Applicant />} />
            <Route path="projects" element={<Projects />} />
            <Route path="hosts" element={<MyHosts />} />
            <Route path="hosts/:id" element={<HostDetails />} />
            <Route path="profile" element={<ClientProfile />} />

            {/* <Route path="verifiedUsers" element={<VerifiedUsers/> } />
            <Route path="selectedProfile/:id" element={<SelectedProfile/> } /> */}
          </Route>

          <Route path="/hostpage" element={<HostLayout />}>
            <Route path="app" element={<HostDashboard />} />
            <Route path="user" element={<ManageLearners />} />
            <Route path="courses" element={<Courses />} />
            <Route path="course/:slug" element={<ManageCourse />} />
            <Route path="updatemodule/:slug/:id" element={<UpdateModule />} />
            <Route path="createCourse" element={<CreateCourse />} />
            <Route path="blog" element={<Applicant />} />
            <Route path="learners" element={<HostsLearners />} />
            <Route path="learners/:id" element={<LearnerDetails />} />
            <Route path="monitoring" element={<AttendanceMonitoring />} />
            <Route path="course/:slug" element={<CourseDetails />} />
            <Route path="hostProfile" element={<HostProfile />} />
            <Route path="setting" element={<HostSettings />} />

            {/* <Route path="verifiedUsers" element={<VerifiedUsers/> } />
            <Route path="selectedProfile/:id" element={<SelectedProfile/> } /> */}
          </Route>

          <Route path="/subhost" element={<SubhostLayout />}>
            <Route path="app" element={<HostDashboard />} />
            <Route path="user" element={<ManageLearners />} />
            <Route path="courses" element={<Courses />} />
            <Route path="course/:slug" element={<MaintainCourse />} />
            <Route path="createCourse" element={<CreateCourse />} />
            <Route path="blog" element={<Applicant />} />
            <Route path="learners" element={<SubhostsLearners />} />
            <Route path="learners/:id" element={<LearnerDetails />} />
            <Route path="monitoring" element={<AttendanceMonitoring />} />
            {/* <Route path="course/:slug" element={<CourseDetails />} /> */}
            <Route path="subhostProfile" element={<SubhostProfile />} />
            {/* <Route path="setting" element={<HostSettings />} /> */}

            {/* <Route path="verifiedUsers" element={<VerifiedUsers/> } />
            <Route path="selectedProfile/:id" element={<SelectedProfile/> } /> */}
          </Route>

          <Route path="/learnerpage" element={<LearnerLayout />}>
            <Route path="app" element={<LearnerDashboard />} />
            <Route path="requestPending" element={<RequestPending />} />
            <Route path="joinCourse" element={<ApplyCourse />} />
            <Route path="myProfile" element={<MyProfile />} />
            <Route path="products" element={<ProductsPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="attendance" element={<AttendanceMarking />} />
            <Route path="attemptQuiz/:id" element={<AttemptQuiz />} />
            {/* <Route path="user" element={<AttendanceMarking />} /> */}
            <Route path="course/:slug" element={<CourseDetails />} />

            <Route path="my-course/:slug/learn" element={<ContinueLearning />} />
            <Route path="my-course/:slug/learn/:videoId" element={<ContinueLearning />} />
            {/* <Route path="my-course/:slug" >
            <CourseRoutes /> */}
            {/* <Route path="learn/:id" element={<ContinueLearning />} /> */}
            {/* </Route>   */}
            <Route path="setting" element={<LearnerSettings />} />
          </Route>

          <Route path="/academy" element={<AcademyLayout />}>
            <Route path="app" element={<AcademyDashboard />} />
            <Route path="user" element={<ManageLearners />} />
            <Route path="courses" element={<AcademyCourses />} />
            <Route path="course/:slug" element={<AcademyCourseDetails />} />
            <Route path="updatemodule/:slug/:id" element={<UpdateModule />} />
            <Route path="createCourse" element={<NewCourse />} />
            <Route path="hosts" element={<ListHosts />} />
            <Route path="hosts/:id" element={<HostDetails />} />
            <Route path="applicants" element={<Applicants />} />
            <Route path="learners" element={<ListLearners />} />
            <Route path="learners/:id" element={<LearnerDetails />} />
            <Route path="academyprofile" element={<AcademyProfile />} />
            <Route path="selectedProfile/:id" element={<SelectedProfile />} />

            {/* <Route path="verifiedUsers" element={<VerifiedUsers/> } />
            <Route path="selectedProfile/:id" element={<SelectedProfile/> } /> */}
          </Route>

          {/* <Route path="learner"  element={<Layout />}>
            <Route path="profileDetails" element={<ProfileDetails /> } />
            <Route path="dashboard" element={access_token ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="welcome" element={access_token ? <Welcome /> : <Navigate to="/login" />} />
          </Route> */}
          {/* <Route path="/dashboard" element={access_token ? <Dashboard /> : <Navigate to="/login" />} /> */}
          {/* Route pages here that should not have navbar  */}
          <Route path="*" element={<h1>Error 404 Page not found !!</h1>} />
        </Routes>
      </Suspense>
    </>
  );
  // ])
  

}
