import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
// @mui
import axios from 'axios';

import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Modal,
  CircularProgress,
  LinearProgress,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { useGetLoggedUserQuery } from '../../../services/userAuthApi';
import { getToken } from '../../../services/LocalStorageService';
import currentServer from '../../../_mock/server';

const style = {
  backgroundColor: 'red',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

MyCourseCard.propTypes = {
  product: PropTypes.object,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function MyCourseCard({ course }) {
  // eslint-disable-next-line
  const { id, name, slug, academy, description, image_course, small_description } = course.course;
  const { accessToken } = getToken();
  const { data, isSuccess } = useGetLoggedUserQuery(accessToken);
  const navigate = useNavigate();
  // eslint-disable-next-line
  let smallDescription = small_description;
  // eslint-disable-next-line
  let imageCourse = image_course;
  const status = 'sale';
  const colors = ['green', 'red', 'blue'];
  const [openOTP, setOpenOTP] = useState(false);

  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const url = `${currentServer}/api/courses/enrollCourse/${id}`;
  const handleEnroll = async () => {
    // console.log("id", data.email, id)
    const courseData = { learner: data.id, course: id };
    const enroll = await axios
      .post(url, courseData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      // handleOpen();
      .then((response) => {
        console.log(response);
        handleOpen();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleContinue = (e, id) => {
    // navigate(`/learnerpage/my-course/${slug}/learn/`)
    navigate(`/learnerpage/my-course/${slug}/learn/${course.last_video_watched.id}`);
    // window.location(`/learnerpage/my-course/${slug}/learn/`)
  };

  return (
    <>
      <Card>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
           <StyledProductImg alt={name} src={`${currentServer}/${imageCourse}`} />  
        {/*   <StyledProductImg alt={name} src={`${imageCourse}`} /> */}
        </Box>

        <Stack  sx={{ px: 1, mt:1 }}>
          <Link color="inherit" underline="hover" component={NavLink} to={`/learnerpage/course/${slug}`}>
            <Typography variant="h6" noWrap>
              {name}
            </Typography>
          </Link>
          <Typography variant="subtitle2" noWrap>
            {academy}
          </Typography>
          {/* <CircularProgress  variant="determinate" value={67} /> */}
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={course.progress_percent} />
          </Box>
          {/* <Button type='submit' id={id} variant='contained' sx={{ mt: 1, mb: 1, px: 1 }} color="info" onClick={handleEnroll}>Enroll now</Button> */}

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors} /> */}
            <Rating name="read-only" value={4} readOnly size="small" />
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                }}
              >
                <Button
                  id={id}
                  variant="contained"
                  sx={{ mt: 1, mb: 1, px: 1 }}
                  color="success"
                  onClick={(e) => handleContinue(e, id)}
                >
                  Continue
                </Button>
                {/* {priceSale && fCurrency(priceSale)}
              {smallDescription && fCurrency(smallDescription)} */}
              </Typography>
              {/* &nbsp;
            {fCurrency(total_module)} */}
            </Typography>
          </Stack>
          
        </Stack>
      </Card>

      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="notification"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'blur' }}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Request has been sent to the Admin for your Enrollment of the course...
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            You will be notified when your Enrollment is Approved...
          </Typography>
          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
