/* eslint-disable */
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
// @mui
import { Box, Card, Link, Typography, Stack, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import currentServer from '../../../_mock/server'


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

AcademyCourseCard.propTypes = {
  product: PropTypes.object,
};

export default function AcademyCourseCard({ course }) {
  // eslint-disable-next-line
  const { id, name, slug, description,image_course, academy, small_description, tags, rating, host, total_module , total_video } = course;
   
  const status = "sale"
  const colors = ["green", "red", "blue"]
  // console.log("🚀 ~ file: ProductCard.js:59 ~ ClientCourseCard ~ currentServer:", currentServer)

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
      {/* {status && (  
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}  */}
        <StyledProductImg alt={name} src={`${currentServer}/${image_course}`} />  
    {/*     <StyledProductImg alt={name} src={`${image_course}`} /> */}
      </Box>

      <Stack sx={{ p: 1 }}>
        <Link color="inherit" underline="hover" component={NavLink} to={`/academy/course/${slug}`}>
          <Typography variant="h6" noWrap>
            {name}
          </Typography>
        </Link>
        <Typography variant="subtitle2" >
        {academy}
          </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <em><small>{description}</small></em>

          {/* <Typography variant="subtitle1"> */}
            {/* <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {academy}
            </Typography> */}
            {/* &nbsp;
            {fCurrency(total_module)} */}
          {/* </Typography> */}
        </Stack>
        <Rating name="read-only" value={rating} readOnly size="small" /> 

      </Stack>
    </Card>

    
  );
}
