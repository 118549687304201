/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../services/LocalStorageService'
import { useGetHostProfileQuery, useGetHostsLearnersQuery, useGetUserDetailsQuery, useUpdateUserDetailsMutation } from '../services/userAuthApi'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import currentServer from '../_mock/server'
import ProfileCover from '../sections/user/profile-cover'
import CustomBreadcrumbs from '../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../components/iconify'
import { Controller, useForm } from 'react-hook-form'

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'personal',
    label: 'Personal Details',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  {
    value: 'learners',
    label: 'Learners ',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  // {
  //   value: 'uploads',
  //   label: 'Uploads',
  //   icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
  // },
]
const HostDetails = () => {
// console.log("🚀 ~ file: SelectedProfile.js:9 ~ currentServer:", currentServer)

  const { id } = useParams()
  const [newBio, setBio] = useState()
  const [newDob, setDob] = useState()
  const [newDepartment, setDepartment] = useState()
  const [newAcademy, setAcademy] = useState()
  const [newClient, setClient] = useState()
  const [newQualification, setQualification] = useState()
  const [newResarchInterests, setSearchInterests] = useState()

  const navigate = useNavigate()
  const {accessToken} = getToken()
  // const [userData, setUserData] = useState({})
  const [hostData, setHostData] = useState({})
  const [hostsLearners, setHostsLearners] = useState([])
  const [currentTab, setCurrentTab] = useState('profile');
  const [updateUserDetails] = useUpdateUserDetailsMutation()
  
  const getHostProfile = useGetHostProfileQuery({id, accessToken})
  // const hostUser = useGetUserDetailsQuery({id, accessToken})
  const getHostsLearners = useGetHostsLearnersQuery({id, accessToken})
  // console.log("🚀 ~ file: HostDetails.js:11 ~ HostDetails ~ hostData:", getHostProfile)

  // Store User Data in Local State
  // useEffect(() => {
  //   if (hostUser.data && hostUser.isSuccess) {
  //       setUserData(hostUser.data)
  //   }
  // }, [hostUser.data, hostUser.isSuccess])
  useEffect(() => {
    if (getHostProfile.data && getHostProfile.isSuccess) {
      setHostData(getHostProfile.data)
    }
  }, [getHostProfile.data, getHostProfile.isSuccess])

  useEffect(() => {
    if (getHostsLearners.data && getHostsLearners.isSuccess) {
        setHostsLearners(getHostsLearners.data)
    }
  }, [getHostsLearners.data, getHostsLearners.isSuccess])


  // const {user,  bio , date_of_birth, department, client, academy, qualification, research_interests, address, region, report_status} = hostData
  // console.log(user)
  // const {  email,  image,is_active, is_admin, is_client, is_host, is_learner, name, phone, } = hostData.user

  const [expanded, setExpanded] = useState(false);
  const [updateError, setUpdateError] = useState({})
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const handleSubmit = () => {}

  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const fileInput = document.getElementById('idFile');
        // console.log("fileInput", id_upload)
        fileInput.innerHTML =id_upload; // This line will trigger the error.
  };

  const UserProfileDetails = () => {
    const { control, handleSubmit, setValue } = useForm()

    const onSubmit = async (data) => {
      console.log(data);
      const res = await updateUserDetails({id, data, accessToken});
      if (res.error) {
        // setServerError(res.error.data.errors);
        // console.log(res.error);
        setUpdateError(res.error.data.errors);
      }
      if (res.data) {
        // console.log(typeof res.data);
        console.log(res.data);
        window.location.reload();
      }
    };

    if (getHostProfile.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Upload Data was found. Please Inform Learner to update...
        </Typography>
      )
    }else if (getHostProfile.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (getHostProfile.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Personal Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                <Controller 
                  control={control}
                  name="name"
                  defaultValue={hostData?.user?.name}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField  {...field} fullWidth  margin='normal' id="name" name="name" /> 
              )}
              />
                 <Typography sx={{ color: 'text.secondary', mt:2  }}>Email</Typography>
                <Controller 
                  control={control}
                  name="email"
                  defaultValue={hostData?.user?.email}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                // <TextField value={field.email} fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }} onChange={(e) => setField({...field, email: e.target.value})}/>
                <TextField  {...field}  fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }}/>
              )}
              />
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Phone</Typography>
                <Controller 
                  control={control}
                  name="phone"
                  defaultValue={hostData?.user?.phone}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField {...field}   fullWidth id="phone" name="phone" InputLabelProps={{ shrink: true, }} />
              )}
              />
                <Box textAlign='center'>
                    <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                </Box>
                {updateError.non_field_errors ? <Alert severity='error'>{updateError.non_field_errors[0]}</Alert> : ''}
                </form>
            </AccordionDetails>
        </Accordion>
      )
    }
  }
  const PersonalDetails = () => {
    if (getHostProfile.isError) {
        return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No  Data was found. Please Inform  to update...
          </Typography>
        )
      }else if (getHostProfile.isLoading){
        return (
          <p>Loading...</p>
        )
      }
      else if (getHostProfile.isSuccess){
        return (
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Personal Details
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Title</Typography>
                    <TextField fullWidth value={hostData.bio} margin='normal' id="title" name="title"  onChange={(e) => setBio(e.target.value)}>
                    </TextField>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Data of Birth:</Typography>
                    <TextField value={hostData.date_of_birth} type="date" required fullWidth id='date_of_birth' name='date_of_birth' onChange={(e) => setDob(e.target.value)}/>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Department</Typography>
                    <TextField value={hostData.department} fullWidth id="department" name="department" InputLabelProps={{ shrink: true, }} onChange={(e) => setDepartment(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Client</Typography>
                    <TextField value={hostData.client} fullWidth id="client" name="client" InputLabelProps={{ shrink: true, }} onChange={(e) => setClient(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Academy</Typography>
                    <TextField value={hostData.academy} required fullWidth id='academy' name='academy' onChange={(e) => setAcademy(e.target.value)}/>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Qualification</Typography>
                    <TextField fullWidth value={hostData.qualification} id="qualification" name="qualification" onChange={(e) => setQualification(e.target.value)}/>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Research Interests</Typography>
                    <TextField fullWidth value={hostData.research_interests} id="research_interests" name="research_interests"onChange={(e) => setSearchInterests(e.target.value)}/>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Address</Typography>
                    <TextField id="address" value={hostData.address} required fullWidth onChange={(e) => setGender(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Region</Typography>
                    <TextField id="region" value={hostData.region} required fullWidth onChange={(e) => setGender(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Report Status</Typography>
                    <TextField id="report_status" value={hostData.report_status} required fullWidth onChange={(e) => setGender(e.target.value)} />
                       
                            {/* <Box textAlign='center'>
                                <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                            </Box> */}
                </AccordionDetails>
            </Accordion>
        )
      }
  }

  const HostsLearners = () => {
    if (getHostsLearners.isError) {
        return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No Upload Data was found. Please Inform Learner to update...
          </Typography>
        )
      }else if (getHostsLearners.isLoading){
        return (
          <p>Loading...</p>
        )
      }
      else if (getHostsLearners.isSuccess){
        return (
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel2bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Learners Details
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}> Learners details: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* LearnersList: {hostsLearners.map((Learners) => (<p key={Learners.id} value={Learners.id}>{Learners.user}</p>))} */}
                    <List>
                        {hostsLearners.map((Learners) => (
                            <ListItem key={Learners.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={Learners.id} secondary={Learners.user.email} sx={{ opacity: open ? 1 : 0 }} >        </ListItemText>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        )
    }
  }

  if (getHostProfile.isLoading){
    return (
      <p>Loading...</p>
    )
  }
  else if (getHostProfile.isSuccess){
    return (<>
    <Grid container>
    <Grid item sm={10}  mb={4} mx="auto">
    <CustomBreadcrumbs heading="Profile" links={[
          { name: 'Dashboard', href: "/adminpage" },
          { name: 'User', href: "/adminpage/myProfile" },
          // { name: user.displayName },
          { name: hostData?.user?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <Card sx={{ mb: 3, height: 290, }} > 
        <ProfileCover 
          // role={name}
          // name={name}
          avatarUrl={`http://127.0.0.1:8000/${hostData?.user?.image}`}
          // coverUrl="https://source.unsplash.com/random?wallpapers"
          coverUrl="/assets/profile-header.jpg"
          />
          <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>
      {currentTab === 'profile' && <UserProfileDetails />}
      {currentTab === 'personal' && <PersonalDetails />} 
      {/* {/* {currentTab === 'professional' && <ProfessionalDetails />} */}
      {currentTab === 'learners' && <HostsLearners />}  

        </Grid> 
        {/* <Grid item sm={9}  mb={4} mx="auto" sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}> */}
        {/* <Item>  */}
        {/* <Stack direction="row" spacing={2}>
        <Avatar
            alt="Remy Sharp"
            src={`${image}`}
            sx={{ width: 80, height: 80 , mb:2, mt:2, ms:10}}
        />
        <Typography variant='h2' sx={{ flexShrink: 0 }}>
                    Name:  {name}
        </Typography>
        </Stack> */}
        {/* <Typography sx={{  flexShrink: 0 }}>
                    Email:  {email}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
        </Typography>
        <Typography sx={{  flexShrink: 0 }}>
                    Date of Birth:  {date_of_birth}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
                    Status:  {account_activated? "Active" : "Not Active"}
        </Typography> */}
        {/* </Grid> */}

        <Grid item sm={10} mx="auto" mb={10}>
        <Box sx={{  flexShrink: 0 }} >
            {/* <Typography variant='h4' sx={{  flexShrink: 0 }}>
                   Any Correction in the below data requires admin approval:
            </Typography> */}
        </Box>
        <Box component="form" sx={{ p: 3 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}>
            {/* Accordion 1 */}
            {/* <PersonalDetails/> */}
            
            
            {/* Accordion 2 */}
            {/* <HostsLearners /> */}

            {/* {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="success">Submit</Button>} */}
            {/* <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="info">Edit Details</Button> */}

        </Box>
        </Grid>
      </Grid>
    </>)
}
}

export default HostDetails 