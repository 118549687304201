/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../services/LocalStorageService'
import { useAcademyDetailsQuery, useAcademysLearnersQuery, useGetAcademysLearnersQuery,  useGetHostsLearnersQuery, useGetUserDetailsQuery, useUpdateUserDetailsMutation } from '../services/userAuthApi'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import currentServer from '../_mock/server'
import Iconify from '../components/iconify'
import CustomBreadcrumbs from '../components/custom-breadcrumbs/custom-breadcrumbs'
import ProfileCover from '../sections/user/profile-cover'
import { Controller, useForm } from 'react-hook-form'

const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'personal',
      label: 'Personal Details',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    {
      value: 'learners',
      label: 'Learners',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
  ]
const AcademyDetails = () => {
// console.log("🚀 ~ file: SelectedProfile.js:9 ~ currentServer:", currentServer)

  const { id } = useParams()
  const [newBio, setBio] = useState()
  const [newDob, setDob] = useState()
  const [newDepartment, setDepartment] = useState()
  const [newOrganization, setOrganization] = useState()
  const [newQualification, setQualification] = useState()
  const [newResarchInterests, setSearchInterests] = useState()

  const navigate = useNavigate()
  const {accessToken} = getToken()
  const [userData, setUserData] = useState({})
  const [academyData, setAcademyData] = useState({})
  const [academysLearners, setAcademysLearners] = useState([])
  const [currentTab, setCurrentTab] = useState('profile');
  const [updateUserDetails] = useUpdateUserDetailsMutation()
  const getAcademyDetails = useAcademyDetailsQuery({id, accessToken})
  const getLearners = useAcademysLearnersQuery({id, accessToken})
 
  console.log("🚀 ~ file: getAcademyDetails.js:11 ~ getAcademyDetails ~ getAcademyDetails:", getLearners)

  // Store User Data in Local State
  useEffect(() => {
    if (getLearners.data && getLearners.isSuccess) {
        setAcademysLearners(getLearners.data)
    }
  }, [getLearners.data, getLearners.isSuccess])
  useEffect(() => {
    if (getAcademyDetails.data && getAcademyDetails.isSuccess) {
        setAcademyData(getAcademyDetails.data)
    }
  }, [getAcademyDetails.data, getAcademyDetails.isSuccess])
 
//   const { user, name, registration_no, vat_no, bee_level, description, location, website, founded_year, employees, learners_active, learners_not_placed} = academyData

  const [expanded, setExpanded] = useState(false);
  const [updateError, setUpdateError] = useState({})
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const handleSubmit = () => {}

  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const fileInput = document.getElementById('idFile');
        // console.log("fileInput", id_upload)
        fileInput.innerHTML =id_upload; // This line will trigger the error.
  };


    const UserProfileDetails = () => {
    const { control, handleSubmit, setValue } = useForm()

    const onSubmit = async (data) => {
        console.log(data);
        const res = await updateUserDetails({id, data, accessToken});
        if (res.error) {
        // setServerError(res.error.data.errors);
        // console.log(res.error);
        setUpdateError(res.error.data.errors);
    }
        if (res.data) {
        // console.log(typeof res.data);
        console.log(res.data);
        window.location.reload();
        }
    };

    if (getAcademyDetails.isError) {
        return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Upload Data was found. Please Inform Learner to update...
        </Typography>
        )
    }else if (getAcademyDetails.isLoading){
        return (
        <p>Loading...</p>
        )
    }
    else if (getAcademyDetails.isSuccess){
        return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Personal Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                <Controller 
                    control={control}
                    name="name"
                    defaultValue={academyData?.user?.name}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <TextField  {...field} fullWidth  margin='normal' id="name" name="name" /> 
                )}
                />
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Email</Typography>
                <Controller 
                    control={control}
                    name="email"
                    defaultValue={academyData?.user?.email}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                // <TextField value={field.email} fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }} onChange={(e) => setField({...field, email: e.target.value})}/>
                <TextField  {...field}  fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }}/>
                )}
                />
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Phone</Typography>
                <Controller 
                    control={control}
                    name="phone"
                    defaultValue={academyData?.user?.phone}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField {...field}   fullWidth id="phone" name="phone" InputLabelProps={{ shrink: true, }} />
                )}
                />
                <Box textAlign='center'>
                    <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                </Box>
                {updateError.non_field_errors ? <Alert severity='error'>{updateError.non_field_errors[0]}</Alert> : ''}
                </form>
            </AccordionDetails>
        </Accordion>
        )
    }
    }
    const AcademyData = () =>{
    if (getAcademyDetails.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Upload Data was found. Please Inform Learner to update...
            </Typography>
        )
    }else if (getAcademyDetails.isLoading){    
        return (
            <p>Loading...</p>
        )
    }else if (getAcademyDetails.isSuccess){
        return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Academy Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Academy details: </Typography>
            </AccordionSummary>
            <AccordionDetails> 

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Website</Typography>
                <TextField fullWidth value={academyData.website} id="website" name="website"  onChange={(e) => setBio(e.target.value)}>
                </TextField>

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Founded Year</Typography>
                <TextField value={academyData.founded_year} type="date" required fullWidth id='founded_year' name='founded_year' onChange={(e) => setDob(e.target.value)}/>

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Registration No</Typography>
                <TextField value={academyData.registration_no} fullWidth id="registration_no" name="registration_no" InputLabelProps={{ shrink: true, }} onChange={(e) => setDepartment(e.target.value)} />

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Vat No</Typography>
                <TextField value={academyData.vat_no} fullWidth id="vat_no" name="vat_no" InputLabelProps={{ shrink: true, }} onChange={(e) => setDepartment(e.target.value)} />

                <Typography sx={{ color: 'text.secondary', mt:2  }}>BEE Level</Typography>
                <TextField value={academyData.bee_level} required fullWidth id='bee_level' name='bee_level' onChange={(e) => setOrganization(e.target.value)}/>

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Description</Typography>
                <TextField fullWidth value={academyData.description} id="description" name="description" onChange={(e) => setQualification(e.target.value)}>   </TextField>

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Location</Typography>
                <TextField fullWidth value={academyData.location} id="location" name="location"onChange={(e) => setSearchInterests(e.target.value)}></TextField>

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Total Employees</Typography>
                <TextField id="employees" value={academyData.employees} required fullWidth onChange={(e) => setGender(e.target.value)} />

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Learners Active</Typography>
                <TextField id="learners_active" value={academyData.learners_active} required fullWidth onChange={(e) => setGender(e.target.value)} />

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Learners Not Placed</Typography>
                <TextField id="learners_not_placed" value={academyData.learners_not_placed} required fullWidth onChange={(e) => setGender(e.target.value)} />
            {/* <Box textAlign='center'>
                <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
            </Box> */}
            </AccordionDetails>
        </Accordion>
        )
        }
    }

    const LearnersDetails = () =>{
        if (getLearners.isError) {
            return (
                <Typography variant='h6' sx={{ flexShrink: 0 }}>
                            No Upload Data was found. Please Inform Learner to update...
                </Typography>
            )
        }else if (getLearners.isLoading){    
            return (
                <p>Loading...</p>
            )
        }else if (getLearners.isSuccess){
            return (
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Learners Details
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}> Learners details: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* LearnersList: {academysLearners.map((Learners) => (<p key={Learners.id} value={Learners.id}>{Learners.user}</p>))} */}
                    <List>
                        {academysLearners?.map((Learners) => (
                            <ListItem key={Learners.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={Learners.id} secondary= {Learners.user.email} sx={{ opacity: open ? 1 : 0 }} >        </ListItemText>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            )
        }
    }


  return (<>
   <Grid container>
    <Grid item sm={10}  mb={4} mx="auto">
    <CustomBreadcrumbs heading="Profile" links={[
          { name: 'Dashboard', href: "/adminpage" },
          { name: 'User', href: "/adminpage/myProfile" },
          // { name: user.displayName },
          { name: academyData?.user?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <Card sx={{ mb: 3, height: 290, }} > 
        <ProfileCover
          // role={name}
          // name={name}
          avatarUrl={`http://127.0.0.1:8000/${academyData?.user?.image}`}
          // coverUrl="https://source.unsplash.com/random?wallpapers"
          coverUrl="/assets/profile-header.jpg"
          />
          <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>
      {currentTab === 'profile' && <UserProfileDetails />}
      {currentTab === 'personal' && <AcademyData />} 
      {/* {/* {currentTab === 'professional' && <ProfessionalDetails />} */}
      {currentTab === 'learners' && <LearnersDetails />}  

        </Grid> 
    {/* <Grid container>
    <Grid item sm={9}  mb={4} mx="auto" sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}>
        <Stack direction="row" spacing={2}> */}
        {/* <Avatar
            alt="Remy Sharp"
            src={`${image}`}
            sx={{ width: 80, height: 80 , mb:2, mt:2, ms:10}}
        /> */}
        {/* <Typography variant='h2' sx={{ flexShrink: 0 }}>
                    Name:  {name}
        </Typography>
        </Stack> */}
        {/* <Typography sx={{  flexShrink: 0 }}>
                    Email:  {email}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
        </Typography>
        <Typography sx={{  flexShrink: 0 }}>
                    Date of Birth:  {date_of_birth}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
                    Status:  {account_activated? "Active" : "Not Active"}
        </Typography> */}
        {/* </Grid> */}

        <Grid item sm={10} mx="auto" mb={10}>
        <Box sx={{  flexShrink: 0 }} >
            {/* <Typography variant='h4' sx={{  flexShrink: 0 }}>
                   Any Correction in the below data requires admin approval:
            </Typography> */}
        </Box>
        <Box component="form" sx={{ p: 3 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}>
            {/* Accordian 1 */}
            {/* <AcademyData/>  */}
            
            {/* Accordian 2 */}
            {/* <LearnersDetails/> */}


            

            {/* {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="success">Submit</Button>} */}
            {/* <Button type='submit'  variant='contained' sx={{ mt: 3, mb: 2, px: 5, alignContent: "right" }} color="info">Edit Details</Button> */}

        </Box>
        </Grid>
      </Grid>
    </>)
}

export default AcademyDetails 