import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { userAuthApi } from '../services/userAuthApi';
import authReducer from '../features/authSlice';
import userReducer from '../features/userSlice';
import courseReducer from '../features/courseSlice';
import attendanceReducer from '../features/attendanceSlice';
import { coursesApi } from '../services/coursesApi';
import { attendanceApi } from '../services/attendanceApi'
// import adminReducer from '../features/adminContext'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

// const reducer = combineReducers({
//   [userAuthApi.reducerPath]: userAuthApi.reducer,
//   [coursesApi.reducerPath]: coursesApi.reducer,
//   auth: authReducer,
//   user: userReducer,
//   course: courseReducer,
// });

// const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: {
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer,
    auth: authReducer,
    user: userReducer,
    course: courseReducer,
    attendance: attendanceReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(userAuthApi.middleware)
    .concat(coursesApi.middleware)
    .concat(attendanceApi.middleware),
})

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(userAuthApi.middleware).concat(coursesApi.middleware),
// });

setupListeners(store.dispatch);
