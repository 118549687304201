/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import {
    Alert,
  Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react'
import { grey } from '@mui/material/colors';
import { useCreateQuestionMutation, useGetQuizDetailsQuery } from '../services/coursesApi';
import { getToken } from '../services/LocalStorageService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  'a',
  'b',
  'c',
  'd',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EditQuiz = () => {
  const { id } = useParams();
  const { accessToken } = getToken();
  const theme = useTheme();
  const [optionsa, setOptions] = useState([])
  const [correctOption, setCorrectOption] = useState([]);
  const getQuizDetails = useGetQuizDetailsQuery({ id, accessToken });
  const [createQuestion, {isLoading}] = useCreateQuestionMutation()
  const [questionList, setQuestionList] = useState([]);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  console.log('🚀 ~ EditQuiz ~ questionList:', questionList);

  useEffect(() => {
    if (getQuizDetails.data && getQuizDetails.isSuccess) {
      setQuestionList(getQuizDetails.data);
    }
  }, [getQuizDetails.data, getQuizDetails.isSuccess]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleDropDownChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(event.target.value)
    // setOptions(event.target.value)
    // // let myList = ['apple', 'banana', 'orange'];
    // const myString = optionsa.join(', ');
    // console.log("🚀 ~ handleDropDownChange ~ myString:", myString)
    setCorrectOption(
      
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
      //  event.target.value
    );
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

 
  const handleQuizSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      question: data.get('question'),
      choice1: data.get('choice1'),
      choice2: data.get('choice2'),
      choice3: data.get('choice3'),
      choice4: data.get('choice4'),
      correct_option: JSON.stringify(correctOption),
    }
    console.log("data", actualData)
    const res = await createQuestion({id, actualData, accessToken})
    if (res.error) {
        // console.log(typeof (res.error.data.errors))
        // console.log(res.error)
       //  console.log(res.error.data.errors) 
        setError(res.error.data.errors)
      }
      if (res.data) {
        // console.log("data", res.data)
       //  console.log("key", res.data.session_key)
        handleClose();
        window.location.reload();
        // navigate('/dashboard')
       }
    }

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={9}
          sx={{ backgroundColor: '#cfd8dc', p: 2, color: 'black', border: 'rounded', borderRadius: '25px' }}
        >
          <List>
            {questionList.map((quiz, index) => (
              <ListItem
                key={quiz.id}
                disablePadding
                sx={{
                  display: 'block',
                  // backgroundColor: videoI.d === quiz.id ? '#f0f0f0' : 'inherit',
                }}
              >
                <ListItemButton
                //   sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                // component={NavLink} to={`/adminpage/attemptQuiz/${quiz.id}`}
                // onClick={(e) => handleCurrentVideo(e, quiz.id, quiz.video)}
                >
                  <ListItemIcon
                    //   sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    sx={{ minWidth: 0, mr: 1, justifyContent: 'center' }}
                  >
                    {/* {quiz.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    {/* <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} /> */}
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={quiz.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    {/* <ListItemText primary={`Quiz ${index +1}`} */}
                    {/* <ListItemText primary={`Q.${index+1}  ${quiz.questions.text}`}
                    //  sx={{ opacity: open ? 1 : 0 }}>
                     sx={{ opacity: 1 }}>
                      {' '}
                    </ListItemText> */}
                    {/* {quiz?.choices?.map((item, index) => {
                      return (
                        <>
                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'black' } }}/>
                         <Typography variant="p" sx={{ flexShrink: 0 }}>
                      {item.text}
                    </Typography>
                        </>
                      )
                    })} */}
                    {/* <form onSubmit={handleFormSubmit}> */}
                      <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{color: "black"}}>{`Q.${index + 1}  ${quiz.questions.text}`}</FormLabel>
                        {quiz?.choices?.map((choice, index) => (
                      //   <RadioGroup name={`question_${quiz.questions.id}`} value={value} onChange={handleChange}>
                      //     {quiz?.choices?.map((choice, index) => (
                          
                      //         <FormControlLabel key={index} value={choice.is_correct} control={<Radio />} label={choice.text} /> 

                      //      ))}  
                      //  </RadioGroup>
                        <FormGroup>
                          {/* <FormControlLabel control={<Checkbox {choice.is_correct} ? defaultChecked: ""  />} label={choice.text} /> */}
                          <FormControlLabel control={choice.is_correct ? <Checkbox disabled sx={{color: grey[800], '&.Mui-checked': { color: grey[600],}}} defaultChecked /> : <Checkbox disabled/>} label={choice.text} />
                        </FormGroup> 
                        ))}
                      </FormControl>
                    {/* </form> */}
                  </div>
                  {/* <ListItemText primary={quiz.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
            <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={handleOpen}>
                {' '}
                Add Question
              </Button>
            </Box>
          </List>
        </Grid>
      </Grid>

      {/* Modal here... */}
      <Modal
        component="form"
        open={open}
        onClose={handleClose}
        id='otp-form' 
        onSubmit={handleQuizSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box
         
         noValidate
         sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Question
          </Typography>
          {/* <label htmlFor="phoneOtp" className="col-form-label">OTP on Phone:</label>
          <input type="text" className="form-control" id="phoneOtp" name="phoneOtp" /> */}
          <TextField type='textarea' margin='normal' required fullWidth id='question' name='question' label='Question'   />
          {/* {error.phoneOtp ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{error.phoneOtp[0]}</Typography> : ""} */}
          {/* <label htmlFor="emailOtp" className="col-form-label">OTP on Email:</label>
          <input type="text" className="form-control" id="emailOtp" name="emailOtp"/>  */}
          <TextField margin='normal' required fullWidth id='choice1' name='choice1' label='Choices 1'  />
          <TextField margin='normal' required fullWidth id='choice2' name='choice2' label='Choices 2'  />
          <TextField margin='normal' required fullWidth id='choice3' name='choice3' label='Choices 3'  />
          <TextField margin='normal' required fullWidth id='choice4' name='choice4' label='Choices 4'  />

          <div>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          multiple
          displayEmpty
          value={correctOption}
          onChange={handleDropDownChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Correct Options:</em>;
            }

            return selected.join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>You can select multiple correct options</em>
          </MenuItem>
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, correctOption, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>

          {/* {error.emailOtp ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{error.emailOtp[0]}</Typography> : ""} */}
          <Box textAlign='center'>
            <Button onClick={handleClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 2,  }}>Cancel</Button>
            {isLoading? <CircularProgress /> :<Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>Add Question</Button>}
          </Box>
          {error.non_field_errors ? <Alert severity='error'>{error.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>
    </>
  );
};

export default EditQuiz;
