import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: {},
  allUsers: [],
  activeUsers: [],
  deletedUsers: [],
  verifiedUsers: [],
  newUsers: [],
  learners: [],
  hosts: [],
  clients: [],
  profileDetails: {}, 
  professionalDetails: {},
  uploadData: {},
  email: "",
  name: "",
  phone: "",
  image: "",
}

export const userSlice = createSlice({
  name: 'user_info',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.email = action.payload.email
      state.name = action.payload.name
      state.phone = action.payload.phone
      state.image = action.payload.image
      state.currentUser = action.payload.currentUser
      state.allUsers = action.payload.allUsers
      state.activeUsers = action.payload.activeUsers
      state.deletedUsers = action.payload.deletedUsers
      state.verifiedUsers = action.payload.verifiedUsers
      state.newUsers = action.payload.newUsers
      state.learners = action.payload.learners
      state.hosts = action.payload.hosts
      state.clients = action.payload.clients
    },
    unsetUserInfo: (state, action) => {
      state.email = action.payload.email 
      state.name = action.payload.name
      state.phone = action.payload.phone
      state.image = action.payload.image
      state.currentUser = action.payload.currentUser
      state.allUsers = action.payload.allUsers
      state.activeUsers = action.payload.activeUsers
      state.deletedUsers = action.payload.deletedUsers
      state.verifiedUsers = action.payload.verifiedUsers
      state.newUsers = action.payload.newUsers
      state.learners = action.payload.learners
      state.hosts = action.payload.hosts
      state.clients = action.payload.clients
    },
  }
})

export const { setUserInfo, unsetUserInfo } = userSlice.actions

export default userSlice.reducer