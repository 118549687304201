/* eslint-disable */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

// @mui
import axios from 'axios'

import { Box, Card, Link, Typography, Stack, Button, Modal, CircularProgress, Alert, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { useGetLoggedUserQuery } from '../../../services/userAuthApi'
import { getToken } from '../../../services/LocalStorageService'
import { useEnrollCourseMutation } from '../../../services/coursesApi'
import currentServer from '../../../_mock/server'

const style = {
  backgroundColor: 'red',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ApplyCourseCard.propTypes = {
  product: PropTypes.object,
};

export default function ApplyCourseCard({ course }) {
  // eslint-disable-next-line
  const { id, name, slug, description,image_course, academy, small_description, tags, rating, host, total_module , total_video } = course;
  const {accessToken} = getToken()
  const [userData, setUserData] = useState({})

  const [enrolled, setEnrolled] = useState(false)
  const { data, isSuccess } = useGetLoggedUserQuery(accessToken)
  const [enrollCourse, {isLoading}] = useEnrollCourseMutation()
  const [serverError, setServerError] = useState({});
  // const { myCourses} = useSelector(state =>state.course)
  // const { myCourses} = useSelector(state =>state.course)
  useEffect(() => {
    if (data && isSuccess) {
        setUserData( data)
    }
  }, [data, isSuccess])



   
  const status = "sale"
  const colors = ["green", "red", "blue"]
  const [openOTP, setOpenOTP] = useState(false)

  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleEnroll = async () => {
    console.log("id", data.email, id)
    const courseData = {"learner": data.id, course: id}
    
    const res = await enrollCourse({id, accessToken})
    // handleOpen();
    if (res.error){
      console.log(res.error)
     console.log(res.error.data.errors)
     setServerError(res.error.data.errors)
    }
    if (res.data){
      // console.log(res.data)
      handleOpen();

    }

  }
  return (
    <>
      <Card>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {status}
            </Label>
          )}
          <StyledProductImg alt={name} src={`${currentServer}/${image_course}`} />
          {/*  <StyledProductImg alt={name} src={`${image_course}`} /> */}
        </Box>

        <Stack sx={{ p: 1 }}>
          <Link color="inherit" underline="hover" component={NavLink} to={`/learnerpage/course/${slug}`}>
            <Typography variant="h6" noWrap>
              {name}
            </Typography>
          </Link>
          <Typography variant="subtitle2">{academy}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors} /> */}
            <em>
              <small>{description}</small>
            </em>

            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                }}
              >
                {/* {priceSale && fCurrency(priceSale)} */}
                {/* {small_description && fCurrency(small_description)} */}
              </Typography>
              &nbsp;
              {/* {fCurrency(total_module)} */}
            </Typography>
          </Stack>
          <Rating name="read-only" value={4} readOnly size="small" />
          {/* {myCourses.map(( item) =>{
          { (item.id === course.id)?
            setEnrolled(true): "";
            // console.log("Enrolling", enrolled);
          }
        })} */}

          {/* {isLoading ? <CircularProgreress /> : <Button type='submit' id={id} variant='contained' sx={{ mt: 1, mb: 1, px: 1 }} color="info" onClick={handleEnroll}>Enroll now</Button>} */}
          {/* {serverError.non_field_errors ? <Button variant='contained' sx={{ mt: 1, mb: 1, px: 1 }} color="info" >Already Requested</Button> : <Button type='submit' id={id} variant='contained' sx={{ mt: 1, mb: 1, px: 1 }} color="info" onClick={handleEnroll}>Enroll now</Button>} */}
          {!userData.account_activated ? (
            <Button variant="contained" sx={{ mt: 1, mb: 1, px: 1 }} color="info">
              Account Not activated
            </Button>
          ) : (
            <Button
              type="submit"
              id={id}
              variant="contained"
              sx={{ mt: 1, mb: 1, px: 1 }}
              color="info"
              onClick={handleEnroll}
            >
              Enroll now
            </Button>
          )}
          {/* {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''} */}
        </Stack>
      </Card>

      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="notification"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'blur' }}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Request has been sent to the Admin for your Enrollment of the course...
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            You will be notified when your Enrollment is Approved...
          </Typography>
          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
