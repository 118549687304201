import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Modal, Box, Alert, Chip, Stack } from '@mui/material';
import { getToken } from '../services/LocalStorageService';
import { useCheckAvailabilityMutation, useMarkAttendanceMutation } from '../services/attendanceApi';
import { useGetLearnersAttendenceListQuery, useGetLoggedUserQuery } from '../services/userAuthApi';
import { CalendarView } from '../sections/calendar/view';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CalendarAttendance() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [checkAvailability] = useCheckAvailabilityMutation();
  const [markAttendance] = useMarkAttendanceMutation();
  const [output, setOutput] = useState(false);
  const [attendanceButton, setAttendanceButton] = useState(false);
  const [label, setLabel] = useState('Unavailable');
  const [color, setColor] = useState('error');
  const [message, setMessage] = useState('');
  const { accessToken } = getToken();
  const [loggedInUser, setLoggedInUser] = useState({});
  const [attendanceList, setAttendanceList] = useState([]);

  // Fetch logged-in user
  const { data: loggedUserData, isSuccess: isLoggedUserSuccess } = useGetLoggedUserQuery(accessToken);

  useEffect(() => {
    if (isLoggedUserSuccess) {
      setLoggedInUser(loggedUserData);
    }
  }, [loggedUserData, isLoggedUserSuccess]);

  // Fetch attendance list
  const { data: attendanceData, isSuccess: isAttendanceSuccess } = useGetLearnersAttendenceListQuery({
    id: loggedInUser?.id,
    accessToken,
  });

  useEffect(() => {
    if (isAttendanceSuccess) {
      console.log('Attendance Data:', attendanceData);
      setAttendanceList(attendanceData);
    }
  }, [attendanceData, isAttendanceSuccess]);

  const parseAttendanceData = (data) => {
    return data.map((entry, index) => ({
      id: index.toString(),
      title: entry.status === true ? 'Present' : 'Absent',
      start: entry.date, 
    }));
  };

  const attendanceEvents = parseAttendanceData(attendanceList);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const checkHostAvailability = async () => {
    const data = { lat: latitude, lng: longitude };
    const res = await checkAvailability({ data, accessToken });
    if (res.error) {
      setOutput(true);
      setAttendanceButton(false);
      setMessage('Failed to fetch location...');
      setColor('error');
    } else if (res.data?.msg) {
      setOutput(true);
      setAttendanceButton(true);
      setMessage(res.data.msg);
      setColor('success');
      setLabel('Available');
    } else if (res.data?.err) {
      setOutput(true);
      setAttendanceButton(false);
      setMessage(res.data.err);
      setColor('error');
    }
  };

  const handleSubmitAttendance = () => {
    handleOpen();
  };

  const submitAttendance = async (e) => {
    e.preventDefault();
    const res = await markAttendance(accessToken);
    if (res.error) {
      console.error('Failed to mark attendance:', res.error);
    } else if (res.data) {
      window.location.reload();
    }
  };

  const [openAttendance, setOpenAttendance] = useState(false);
  const handleOpen = () => setOpenAttendance(true);
  const handleClose = () => setOpenAttendance(false);

  console.log(attendanceEvents);

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Student Attendance Calendar
      </Typography>
      <CalendarView events={attendanceEvents} />
      <Button variant="contained" color="primary" onClick={handleSubmitAttendance}>
        Mark My Attendance
      </Button>
      <Modal
        open={openAttendance}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box component="form" sx={style} onSubmit={submitAttendance}>
          <Typography id="modal-title" variant="h6" component="h2">
            My Current Location: {latitude || 'Not Available'}, {longitude || 'Not Available'}
          </Typography>
          <Stack spacing={2} direction="row">
            <Button onClick={checkHostAvailability} variant="contained" color="primary">
              Check Availability
            </Button>
            <Chip label={label} color={color} />
          </Stack>
          {output && <Alert severity={color}>{message}</Alert>}
          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 2 }}>
              Cancel
            </Button>
            {attendanceButton && (
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>
                Mark Attendance
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}
