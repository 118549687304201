import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {
  Typography,
  Container,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  ListItemAvatar,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetLoggedUserQuery, useNearestLearnerQuery, useMarkAttendanceMutation } from '../services/userAuthApi';
import { getToken } from '../services/LocalStorageService';

// Leaflet marker icon fix (default icon doesn't load correctly)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

export default function AttendanceMonitoring() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [studentAttendance, setStudentAttendance] = useState({});
  const [markAttendance] = useMarkAttendanceMutation();
  const { accessToken } = getToken();
  const { data: loggedUserData, isSuccess: isUserSuccess } = useGetLoggedUserQuery(accessToken);
  const { data: nearestLearners, isSuccess: learnersLoaded } = useNearestLearnerQuery({
    actualData: { user_id: loggedUserData?.id },
    accessToken,
  });
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isUserSuccess) {
      setLoggedInUser(loggedUserData);
    }
  }, [loggedUserData, isUserSuccess]);

  const [currentPosition, setCurrentPosition] = useState([]);

  useEffect(() => {
    // Optionally, get user's actual current location
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition([position.coords.latitude, position.coords.longitude]);
    });
  }, []);

  const hostLocation = loggedInUser
    ? [parseFloat(loggedInUser.latitude), parseFloat(loggedInUser.longitude)]
    : [12.9716, 77.5946];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleToggleAttendance = async (studentId, status) => {
    if (studentId) {
      const data = { status };

      try {
        const response = await markAttendance({ studentId, data, accessToken });
        if (response.error) {
          setError(response.error.data.error);
          console.error('Error marking attendance:', response.error);
        } else {
          console.log('Attendance marked successfully:', response.data);

          setStudentAttendance((prev) => ({
            ...prev,
            [studentId]: status,
          }));
          window.location.reload();
        }
      } catch (error) {
        console.error('Failed to mark attendance:', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Student Attendance
      </Typography>
      <DatePicker
        label="Select Date"
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <MapContainer center={hostLocation} zoom={13} style={{ height: '500px', width: '100%', marginTop: '20px' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Current User Marker */}
        {loggedInUser && (
          <Marker position={hostLocation}>
            <Tooltip permanent direction="top">
              <Typography variant="body1">{loggedInUser.role || 'Host Location'}</Typography>
            </Tooltip>
          </Marker>
        )}

        {/* Nearest Learners Markers */}
        {learnersLoaded && nearestLearners && nearestLearners[0] && nearestLearners.length > 0 ? (
          nearestLearners.map((learner) => {
            const lat = parseFloat(learner.user.latitude);
            const lng = parseFloat(learner.user.longitude);

            if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
              return (
                <Marker key={learner.user.id} position={[lat, lng]}>
                  <Tooltip permanent direction="top">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItemAvatar>
                        <Avatar alt={learner.user.name} src={learner.user.image} />
                      </ListItemAvatar>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6">{learner.user.name}</Typography>
                        <Typography variant="body2">{learner.user.email}</Typography>
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
              );
            }
            return null;
          })
        ) : (
          <Typography>No learners found.</Typography>
        )}
      </MapContainer>

      <List style={{ width: '50%', margin: '0 auto' }}>
        {learnersLoaded && nearestLearners?.length > 0 ? (
          nearestLearners?.map((learner) => (
            <div key={learner.user.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt={learner.user.name} src={learner.user.image} />
                </ListItemAvatar>
                <ListItemText primary={`Name: ${learner.user.name}`} secondary={`Email: ${learner.user.email}`} />

                {/* Conditionally render the check mark or buttons */}
                {studentAttendance[learner.user.id] !== undefined ? (
                  <CheckCircleIcon style={{ color: studentAttendance[learner.id] ? 'green' : 'red' }} />
                ) : (
                  <>
                    {learner.attendance_status === true || learner.attendance_status === false ? (
                      <p style={{ marginBottom: '0', fontSize: '12px' }}>
                        {`Today's Attendence is marked as: ${learner.attendance_status === true ? 'Present' : 'Absent'}`}{' '}
                      </p>
                    ) : (
                      <>
                        {' '}
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleToggleAttendance(learner.id, true)}
                        >
                          Mark as Present
                        </Button>
                        <Button
                          style={{ marginLeft: '1rem' }}
                          variant="contained"
                          color="primary"
                          onClick={() => handleToggleAttendance(learner.id, false)}
                        >
                          Absent
                        </Button>
                      </>
                    )}
                  </>
                )}
              </ListItem>

              <Divider />
            </div>
          ))
        ) : (
          <Typography>No learners found.</Typography>
        )}
      </List>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError} message="Note archived">
        <Alert onClose={handleCloseError} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
