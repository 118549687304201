import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async'
import { Typography, Container, TextField, Button, CircularProgress, Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { useContactAdminMutation } from '../services/userAuthApi'
import { ContactView } from '../sections/contact/view'


const BackgroundContainer = styled('div')({
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // Replace with your awesome background style
  background: 'default', // Replace with your awesome background style
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FormContainer = styled('div')({
  maxWidth: 900,
  width: '100%',
  padding: '20px',
  borderRadius: '2px',
  background: '#fff',
  margin: '4px',
});


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [contactAdmin, {isLoading}] = useContactAdminMutation()
  const [serverError, setServerError] = useState({});
  const [serverMsg, setServerMsg] = useState(false);


  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    })
  }
  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await contactAdmin(formData)
    // Replace this with your logic to handle form submission
    if (res.error) {
      // console.log(res.error)
      // console.log(res.error.data.errors)
      setServerError(res.error.data.errors)
    }
    if (res.data) {
      // console.log(typeof (res.data))
      // console.log("data", res.data)
      resetForm()
      setServerMsg(true)

      // navigate('/dashboard')
      }
      // data-bs-toggle="modal" data-bs-target="#otpModal" 
    
  };

  return (
    <>
    <Helmet>
        <title> Contact us</title>
      </Helmet>
   < ContactView/>
    </>
  );
};
 
export default ContactForm;

// {/* <Container maxWidth="lg" sx={{ */}
    //   display: 'flex',
    //   flexDirection: 'column',
    //   // minHeight: '10vh',
    //   alignItems: 'center',
    // justifyContent: 'center',
    // }} >
    //   <FormContainer >
    //     <Typography variant="h4" align="center" gutterBottom>
    //       Contact Us
    //     </Typography>
    //     <form onSubmit={handleSubmit}>
    //       <TextField
    //         label="Name"
    //         name="name"
    //         value={formData.name}
    //         onChange={handleChange}
    //         fullWidth
    //         margin="normal"
    //         variant="outlined"
    //         required
    //       />
    //       <TextField
    //         label="Email"
    //         name="email"
    //         type="email"
    //         value={formData.email}
    //         onChange={handleChange}
    //         fullWidth
    //         margin="normal"
    //         variant="outlined"
    //         required
    //       />
    //       <TextField
    //         label="Phone Number"
    //         name="phone"
    //         value={formData.phone}
    //         onChange={handleChange}
    //         fullWidth
    //         margin="normal"
    //         variant="outlined"
    //         required
    //       />
    //       <TextField
    //         label="Message"
    //         name="message"
    //         multiline
    //         rows={4}
    //         value={formData.message}
    //         onChange={handleChange}
    //         fullWidth
    //         margin="normal"
    //         variant="outlined"
    //         required
    //       />
    //       <Box textAlign="right">
    //         <Button  component={NavLink} to="/" variant="contained" color="primary" sx={{m:2}} >
    //           Cancel
    //         </Button>
    //         {isLoading ? <CircularProgress /> : <Button type="submit" variant="contained" color="success">
    //           Submit
    //         </Button>}
            
    //         {serverMsg? <Alert severity='success'>Your query is submitted to the Admin Successfully!! </Alert> : ''}
    //       </Box>
    //     </form>
    //   </FormContainer>
    //   </Container