import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, TextField, FormControlLabel, Checkbox, Alert, InputLabel, MenuItem, Select, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Avatar, CircularProgress, Input } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { useGetLoggedUserQuery, useProfessionalDetailsMutation } from '../services/userAuthApi'
import { getToken } from '../services/LocalStorageService'


const EMPLOYMENT = [
    [
      'None of these',
      'None of these',
    ],
    [
      'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
      'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
    ],
    [
      'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
      'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
    ],
    ['I am presently employed', 'I am presently employed'],
  ];
const ProfessionalForm = ({id}) => {
    const [taxNumber, setTax] = useState()
    const [employmentStatus, setEmployment] = useState("None of these")
    const [homeLanguage, setHomeLanguage] = useState()
    const [streetAddress, setStreet] = useState()
    const [residentalArea, setResidentalArea] = useState()
    const [city, setCity] = useState()
    const [province, setProvince] = useState()
    const [postalCode, setPostalCode] = useState()
    const [postalAddress, setPostalAddress] = useState()
    const [emergencyNumber, setEmergencyNumber] = useState()
    const [whatsappNumber, setWhatsappNumber] = useState()
    const [lastSchool, setLastSchool] = useState()
    const [lastSchoolYear, setLastSchoolYear] = useState()
    const [schoolHighestGrade, setSchoolHighestGrade] = useState()
    const [alertUser, setAlert] = useState(false)
    const navigate = useNavigate()
    const [professionalDetails, { isLoading }] = useProfessionalDetailsMutation()
    const {accessToken} = getToken()
 
    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })

    const resetForm = () => {
     
        setTax('')
        setEmployment('')
        setHomeLanguage('')
        setStreet('')
        setResidentalArea('')
        setCity('')
        setProvince('')
        setPostalCode('')
        setPostalAddress('')
        setEmergencyNumber('')
        setWhatsappNumber('')
        setLastSchool('')
        setLastSchoolYear('')
        setSchoolHighestGrade('')
        document.getElementById('profileDetails-form').reset()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('user', id)
        data.append('tax_number', taxNumber)
        data.append('employment_status', employmentStatus)
        data.append('home_language', homeLanguage)
        data.append('street_address', streetAddress)
        data.append('residential_area', residentalArea)
        data.append('city', city)
        data.append('province', province)
        data.append('postal_code', postalCode)
        data.append('postal_address', postalAddress)
        data.append('emergency_number', emergencyNumber)
        data.append('whatsapp_number', whatsappNumber)
        data.append('last_school', lastSchool)
        data.append('last_school_year', lastSchoolYear)
        data.append('school_highest_grade', schoolHighestGrade)
        
      const res = await professionalDetails({ data, accessToken })
      
      if (res.data) {
        // console.log("data")
          setError({ status: true, msg: "Professional Data Uploaded Successfully", type: 'success' })
          // resetForm()
          // navigate("/learnerpage/dashboard")
      }else if (res.error) {
        // console.log("error1", res.error)
        setError({ status: true, msg: "All Fields are Required", type: 'error' })
      }else  {
        // console.log("error2", res.error)
        setError({ status: true, msg: "All Fields are Required", type: 'error' })
      }
    // data[0].map(item =>console.log(item))
    
    // console.log(specialPowerAttorney);
    }

  return (<>
    <Box component="form" sx={{ p: 3 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}> 
             
     {/* <FormControl fullWidth margin='normal'>
       <InputLabel id="state-select-label">State</InputLabel>
       <Select labelId='state-select-label' id='state-select' value={st} label='st' onChange={(e) => { setSt(e.target.value) }}>
         <MenuItem value="Jharkhand">Jharkhand</MenuItem>
         <MenuItem value="Bihar">Bihar</MenuItem>
         <MenuItem value="West Bengal">West Bengal</MenuItem>
       </Select>
     </FormControl> */}
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2} >

     <TextField fullWidth margin='normal' id="employmentStatus" select name="employmentStatus" label="Employment Status"  SelectProps={{native: true, }} onChange={(e) => setEmployment(e.target.value)}>          
       {EMPLOYMENT.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>
     <TextField id="homeLanguage"  required fullWidth margin='normal' label='Home Language' onChange={(e) => setHomeLanguage(e.target.value)}  />

       </Stack>
       <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>

       <TextField id="taxNumber" required fullWidth margin='normal' label='Tax Number' onChange={(e) => setTax(e.target.value)} />
       
       <TextField id="streetAddress" required fullWidth margin='normal' label='Street Address' onChange={(e) => setStreet(e.target.value)} />
       </Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>
       
       <TextField id="residentalArea" required fullWidth margin='normal' label='Residential Area' onChange={(e) => setResidentalArea(e.target.value)} />
       
       <TextField id="city" required fullWidth margin='normal' label='City' onChange={(e) => setCity(e.target.value)} />
       </Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>

       <TextField id="province" required fullWidth margin='normal' label='Province' onChange={(e) => setProvince(e.target.value)} />
       
       <TextField id="postalCode" required fullWidth margin='normal' label='Postal Code' onChange={(e) => setPostalCode(e.target.value)} />
     </Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>
       
       <TextField id="postalAddress" required fullWidth margin='normal' label='Postal Address' onChange={(e) => setPostalAddress(e.target.value)} />
       
       <TextField id="emergencyNumber" required fullWidth margin='normal' label='Emergency Number' onChange={(e) => setEmergencyNumber(e.target.value)} />
       </Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>

       <TextField id="whatsappNumber" required fullWidth margin='normal' label='Whatsapp Number' onChange={(e) => setWhatsappNumber(e.target.value)} />
       
       <TextField id="lastSchool" required fullWidth margin='normal' label='Last School Name' onChange={(e) => setLastSchool(e.target.value)} />
      </Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} spacing={2}>
       
       <TextField id="lastSchoolYear" required fullWidth margin='normal' label='Last School Year(1900-2100)'  onChange={(e) => setLastSchoolYear(e.target.value)} />
       
       <TextField id="schoolHighestGrade" required fullWidth margin='normal' label='School Highest Grade' onChange={(e) => setSchoolHighestGrade(e.target.value)} />
       </Stack>
       <Box textAlign='center'>
        {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 1, mb: 1, px: 8 }}>Save</Button>}
        </Box>
        {/* {alertUser?  <Alert severity="success">Your Data is Saved Successfully!</Alert> : ""} */}
        {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
       </Box>
   </>
  )
}

export default ProfessionalForm