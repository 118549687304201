/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Modal,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  FormControl,
  OutlinedInput,
  DialogActions,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../features/userSlice';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from '../services/LocalStorageService';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import {
  useCreateHostMutation,
  useGetAllUsersQuery,
  useGetHostListQuery,
  useGetLoggedUserQuery,
  useListGroupQuery,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useCreateGroupMutation,
} from '../services/userAuthApi';
import currentNetwork from '../_mock/network';

import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'group', label: 'Group', alignRight: false },
  //{ id: 'description', label: 'Description', alignRight: false },
  //  { id: 'assign', label: 'Assign', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
  //  { id: 'role', label: 'Role', alignRight: false },
  //  { id: 'verified', label: 'Verified', alignRight: false },
  //  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UserGroups = () => {
  // const {hosts} = useSelector(state => state.user)
  const [groups, setGroups] = useState([]);
  // console.log("🚀 ~ file: UserGroups.js:106 ~ groups:", groups)
  //  const [createHost] = useCreateHostMutation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();
  const [open, setOpen] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [qualification, setQualification] = useState('');
  const [dob, setDob] = useState('');

  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();
  const [createGroup] = useCreateGroupMutation();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const getAllGroups = useListGroupQuery(accessToken);

  const [hostList, setHostsList] = useState([]);
  // console.log("🚀 ~ file: MyHosts.js:111 ~ hostList:", hostList)

  const myHosts = useGetHostListQuery(accessToken);
  const getAllHosts = useGetHostListQuery(accessToken);

  useEffect(() => {
    if (myHosts.data && myHosts.isSuccess) {
      setHostsList(myHosts.data);
    }
  }, [myHosts.data, myHosts.isSuccess]);

  useEffect(() => {
    if (getAllGroups.data && getAllGroups.isSuccess) {
      setGroups(getAllGroups.data);
    }
  }, [getAllGroups.data, getAllGroups.isSuccess]);

  useEffect(() => {
    if (getAllHosts.data && getAllHosts.isSuccess) {
      setHostsList(getAllHosts.data);
    }
  }, [getAllHosts.data, getAllHosts.isSuccess]);

  const [assignOpen, setAssignOpen] = useState(false);
  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(Number(event.target.value) || '');
  };

  const handleClickOpen = () => {
    setAssignOpen(true);
  };

  const handleAssignClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAssignOpen(false);
    }
  };

  const handleHostAssignSubmit = (e) => {
    const data = e.target.value;
  };

  const [assignedHost, setAssignedHost] = useState();

  const handleHostAssigned = (e) => {
    console.log('hostAssigned');
    setAssignOpen(e.target.value);
  };

  const [selectedGroup, setSelectedGroup] = useState([]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedGroup(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const [description, setDescription] = useState('');
  const [host, setHost] = useState('');

  const handleHostChange = (event) => {
    setHost(event.target.value);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen();
  };
  const handleAssignHostChange = (e) => {
    setAssignedHost(e.target.value);
    console.log('assisgened host: ', assignedHost);

    const actualData = {
      learner: "",
      host: assignedHost
    };
  };
  const submitHost = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const actualData = {
      name: data.get('name'),
      description: data.get('description'),
      assigned_to: host,
    };
    console.log(actualData);
    const res = await createGroup({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();
      window.location.reload();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (getAllGroups.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (getAllGroups.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  //  if (getAllGroups.isError){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No User Data Found...</h1>)
  //  }else{
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const [editModal, setEditModal] = useState(false);
  const handleEditClose = () => setEditModal(!editModal);
  const handleDeleteModalClose = () => setDeleteModal(!deleteModal);
  const [selectedLearner, setSelectedLearner] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedGroup?.id;
    console.log('data from update host:', actualData);

    const res = await deleteGroup({ actualData, id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      handleDeleteModalClose();

      window.location.reload();
    }
  };

  const [academyList, setAcademyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [subHosts, setSubHosts] = useState([]);

  const editLearnersSubmit = async (values) => {
    //console.log(values)

    // const data = new FormData(e.currentTarget);

    const data = {
      name: values.name,
      description: values.description,
    };
    const id = selectedGroup?.id;
    const res = await updateGroup({ data, id, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res.data) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleEditClose();

      window.location.reload();
      // navigate('/adminpage/hosts');
    }
  };
  return (
    <>
      <Helmet>
        <title> Groups | Y-Plan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Groups
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>
            New Groups
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={groups.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    const { id, user, name, group, description, permissions } = row;

                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        {/* <NavLink to={`http://${currentNetwork}/adminpage/groups/${id}`}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={image} />
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </Stack>
                      </TableCell></NavLink> */}

                        {/* <TableCell align="left">{name}</TableCell> */}

                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                      

                        {/* <TableCell align="left">{role}</TableCell> */}
                        {/* 
                      <TableCell align="left">
                        <Label color={(accountActivated === 'banned' && 'error') || 'success'}>{sentenceCase(accountActivated)}</Label>
                      </TableCell> */}
                       {/*  <TableCell align="left">
                          <Button onClick={handleClickOpen}>Assign Host</Button>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {getAllGroups.isError && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            {/* <strong>&quot;{filterName}&quot;</strong>. */}
                            <strong>&quot;User Groups&quot;</strong>.
                            <br /> Try to Log In again with an Admin Id...
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={groups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setEditModal(!editModal)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* Create host Modal  */}
      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="otp-form"
        onSubmit={submitHost}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a Group
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            label="name"
            onChange={(name) => {
              setPhone(name);
            }}
          />
          {serverError.name ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            name="description"
            label="Description"
            onChange={(description) => {
              setDescription(description);
            }}
          />
          {serverError.description ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography>
          ) : (
            ''
          )}

          <FormControl margin="normal" fullWidth>
            <InputLabel id="demo-simple-select-label">Host</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={host}
              label="Category"
              onChange={handleHostChange}
            >
              {hostList.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category?.user?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
              Create Group
            </Button>
          </Box>
          {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>

      {/* Assign Host Box */}
      <Dialog disableEscapeKeyDown open={assignOpen} onClose={handleAssignClose}>
        <DialogTitle>Select Host</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">Hosts</InputLabel>

              <Select
                native
                // value={age}
                onChange={handleAssignHostChange}
                input={<OutlinedInput label="Hosts" id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                {/* <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option> */}
                {hostList.map((host) => (
                  <option key={host.id} value={host.id}>
                    {host?.user?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignClose}>Cancel</Button>
          <Button onClick={(e) => handleHostAssigned(e)}>Ok</Button>
        </DialogActions>
      </Dialog>

      {/* /////////////////////////////////////////////////////////////////////////////// */}

      {/* Edit host Modal  */}
      <Modal
        open={editModal}
        onClose={handleEditClose}
        id="otp-form"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Formik
          initialValues={{
            name: selectedGroup?.name || '',
            description: selectedGroup?.description || '',
          }}
          onSubmit={(values) => editLearnersSubmit(values)}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form>
              <Box noValidate sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Group
                </Typography>

                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.department && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.department[0]}
                    </Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.description && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.description[0]}
                    </Typography>
                  )}
                </Stack>

                <Box textAlign="right">
                  <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    onSubmit={handleSubmit}
                    onClick={(values) => editLearnersSubmit(values)}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}
                  >
                    Edit Group
                  </Button>
                </Box>
                {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Modal Popup */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Group
          </Typography>

          <form onSubmit={handleDeleteModalSubmit} noValidate>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  background: '#bf3f57',
                  padding: '1rem',
                  marginBottom: '.5rem',
                }}
              >
                <AiOutlineDelete size={23} color="#fff" />
              </div>
              <h5>
                {' '}
                Do you want to Delete{' '}
                <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedGroup?.name}</span> Group ?{' '}
              </h5>
            </div>

            <Box textAlign="center">
              <Button onClick={handleDeleteModalClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                Delete
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
// }

export default UserGroups;
