/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../features/userSlice';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from '../services/LocalStorageService';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import {
  useCreateAcademyMutation,
  useCreateHostMutation,
  useDeleteAcademiesMutation,
  useGetAcademyQuery,
  useGetAllUsersQuery,
  useGetLoggedUserQuery,
  useUpdateAcademiesMutation,
} from '../services/userAuthApi';
import currentNetwork from '../_mock/network';
import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: 'reg_no', label: 'Reg. No.', alignRight: false },
  { id: 'vat', label: 'Vat No.', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllAcademies = () => {
  // const {hosts} = useSelector(state => state.user)
  const [academy, setAcademy] = useState([]);
  // console.log("🚀 ~ file: AllAcademies.js:107 ~ academy:", academy)
  const [createAcademy, { isLoading }] = useCreateAcademyMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();
  const [open, setOpen] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [qualification, setQualification] = useState('');
  const [dob, setDob] = useState('');

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [updateAcademies] = useUpdateAcademiesMutation();
  const [deleteAcademies] = useDeleteAcademiesMutation();

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const getAllAcademies = useGetAcademyQuery(accessToken);

  useEffect(() => {
    if (getAllAcademies.data && getAllAcademies.isSuccess) {
      setAcademy(getAllAcademies.data);
    }
  }, [getAllAcademies.data, getAllAcademies.isSuccess]);

  const [selectedAcademies, setSelectedAcademies] = useState([]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedAcademies(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateAcademy = () => {
    handleOpen();
  };
  const submitAcademy = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const actualData = {
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone'),
      registration_no: data.get('registration_no'),
      vat_no: data.get('vat_no'),
      website: data.get('website'),
    };
    console.log(actualData);
    const res = await createAcademy({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();
      // navigate('/adminpage/academy')
      window.location.reload();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (getAllAcademies.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (getAllAcademies.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  //  if (getAllAcademies.isError){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No User Data Found...</h1>)
  //  }else{

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const getAllUsers = useGetAllUsersQuery(accessToken);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess) {
      var clientData = getAllUsers.data.filter((curElem) => {
        return curElem.is_client === true;
      });
      setAllUsers(getAllUsers.data);
    }
  }, [getAllUsers.data, getAllUsers.isSuccess]);

  const [editModal, setEditModal] = useState(false);
  const handleEditClose = () => setEditModal(!editModal);
  const handleDeleteModalClose = () => setDeleteModal(!deleteModal);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedAcademies?.id;

    const res = await deleteAcademies({ actualData, id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      handleDeleteModalClose();
      window.location.reload();
    }
  };

  // Get all users and filter subhost

  const editAcademiesSubmit = async (values) => {
    const actualData = {
      user: values?.user,
      name: values?.name,
      registration_no: values?.registration_no,
      vat_no: values?.vat_no,
      bee_level: values?.bee_level,
      description: values?.description,
      location: values?.location,
      website: values?.website,
      founded_year: values?.founded_year,
      employees: values?.employees,
      learners_active: values?.learners_active,
      learners_not_placed: values?.learners_not_placed,
    };
    const id = selectedAcademies?.id;
    console.log('data from update academies:', actualData);

    const res = await updateAcademies({ actualData, id, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res.data) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleEditClose();

      window.location.reload();
      // navigate('/adminpage/hosts');
    }
  };
  return (
    <>
      <Helmet>
        <title> Academies | Y-Plan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Academies
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateAcademy}>
            New Academies
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={academy.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {academy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    const {
                      id,
                      user,
                      name,
                      registration_no,
                      vat_no,
                      bee_level,
                      description,
                      location,
                      website,
                      founded_year,
                      employees,
                      learners_active,
                      learners_not_placed,
                    } = row;
                    // const isActive = String(is_active)
                    // const accountActivated = String(account_activated)
                    const selectedUser = selected.indexOf(name) !== -1;
                    // if (is_admin){
                    //   var role = 'Admin';
                    // }else if(is_learner){
                    //   var role =  "Learner";
                    // }else if(is_host){
                    //   var role =  "Host";
                    // }
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        {/* <TableCell component="th" scope="row" padding="none"> */}
                        <TableCell component="th" scope="row">
                          {/* <NavLink to={`http://${currentNetwork}/adminpage/academy/${id}`}> */}
                          <NavLink to={`/adminpage/academy/${user.id}`}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={image} /> */}
                              <Typography variant="subtitle2" sx={{ color: 'black' }} noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </NavLink>
                        </TableCell>

                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">{website}</TableCell>

                        <TableCell align="left">{registration_no}</TableCell>

                        <TableCell align="left">{vat_no}</TableCell>
                        <TableCell align="left">{location}</TableCell>
                        {/* 
                      <TableCell align="left">
                        <Label color={(bee_level === 'banned' && 'error') || 'success'}>{sentenceCase(bee_level)}</Label>
                      </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {getAllAcademies.isError && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            {/* <strong>&quot;{filterName}&quot;</strong>. */}
                            <strong>&quot;Academies&quot;</strong>.
                            <br /> Try to Log In again with an Admin Id...
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={academy.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setEditModal(!editModal)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* Create Academy Modal  */}
      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="otp-form"
        onSubmit={submitAcademy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create an Academy
          </Typography>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="name" name="name" label="name" />
            {serverError.name ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
            ) : (
              ''
            )}
            <TextField margin="normal" required fullWidth id="email" name="email" label="email" />
            {serverError.email ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="phone" name="phone" label="phone" />
            {serverError.phone ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography>
            ) : (
              ''
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="registration_no"
              name="registration_no"
              label="registration_no"
            />
            {serverError.registration_no ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                {serverError.registration_no[0]}
              </Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="vat_no" name="vat_no" label="vat_no" />
            {serverError.vat_no ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.vat_no[0]}</Typography>
            ) : (
              ''
            )}
            <TextField margin="normal" required fullWidth id="website" name="website" label="Website" />
            {serverError.website ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.website[0]}</Typography>
            ) : (
              ''
            )}
          </Stack>

          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Cancel
            </Button>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                Create Academy
              </Button>
            )}
          </Box>
          {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>

      {/* /////////////////////////////////////////////////////////////////////////////// */}

      {/* Edit host Modal  */}
      <Modal
        open={editModal}
        onClose={handleEditClose}
        id="otp-form"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Formik
          initialValues={{
            user: selectedAcademies?.user || '',
            name: selectedAcademies?.name || '',
            registration_no: selectedAcademies?.registration_no || '',
            vat_no: selectedAcademies?.vat_no || '',
            bee_level: selectedAcademies?.bee_level || '',
            description: selectedAcademies?.description || '',
            location: selectedAcademies?.location || '',
            website: selectedAcademies?.website || '',
            founded_year: selectedAcademies?.founded_year || '',
            employees: selectedAcademies?.employees || '',
            learners_active: selectedAcademies?.learners_active || '',
            learners_not_placed: selectedAcademies?.learners_not_placed || '',
          }}
          onSubmit={(values) => editAcademiesSubmit(values)}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Box noValidate sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Academies
                </Typography>
                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="user"
                      value={values.user}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {allUsers.map((academy) => (
                        <MenuItem key={academy.id} value={academy.id}>
                          {academy.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.name && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.name[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="registration_no"
                    name="registration_no"
                    label="Registration No"
                    value={values.registration_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.registration_no && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.registration_no[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="vat_no"
                    name="vat_no"
                    label="Vat No"
                    value={values.vat_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.vat_no && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.vat_no[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bee_level"
                    type="number"
                    name="bee_level"
                    label="Bee Level"
                    value={values.bee_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.bee_level && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.bee_level[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.description && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.description[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="location"
                    name="location"
                    label="Location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.location && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.location[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="website"
                    name="website"
                    label="Website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.website && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.website[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    id="founded_year"
                    name="founded_year"
                    label="Founded Year"
                    value={values.founded_year}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: new Date().toISOString().split('T')[0],
                      },
                    }}
                  />
                  {serverError.founded_year && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.founded_year[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="employees"
                    name="employees"
                    label="Employees"
                    value={values.employees}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.employees && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.employees[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="learners_active"
                    name="learners_active"
                    label="Learners Active"
                    value={values.learners_active}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.learners_active && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.learners_active[0]}
                    </Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="learners_not_placed"
                    name="learners_not_placed"
                    label="Learners not placed"
                    value={values.learners_not_placed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.learners_not_placed && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.learners_not_placed[0]}
                    </Typography>
                  )}
                </Stack>

                <Box textAlign="right">
                  <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Cancel
                  </Button>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                      Edit Academies
                    </Button>
                  )}
                </Box>
                {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Modal Popup */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Academies
          </Typography>

          <form onSubmit={handleDeleteModalSubmit} noValidate>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  background: '#bf3f57',
                  padding: '1rem',
                  marginBottom: '.5rem',
                }}
              >
                <AiOutlineDelete size={23} color="#fff" />
              </div>
              <h5>
                {' '}
                Do you want to Delete{' '}
                <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedAcademies?.name}</span> Academy
                ?{' '}
              </h5>
            </div>

            <Box textAlign="center">
              <Button onClick={handleDeleteModalClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                Delete
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
// }

export default AllAcademies;
