import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, Typography } from '@mui/material'
import { useGetQuizDetailsQuery, useSubmitQuizMutation } from '../services/coursesApi';
import { getToken } from '../services/LocalStorageService';

const AttemptQuiz = () => {
  const { id } = useParams();
  const { accessToken } = getToken();
  const getQuizDetails = useGetQuizDetailsQuery({ id, accessToken });
  const [questionList, setQuestionList] = useState([])
  const [value, setValue] = useState('');
  const [submitQuiz, {isLoading}] = useSubmitQuizMutation()

  // console.log("🚀 ~ AttemptQuiz ~ questionList:", questionList)

  useEffect(() => {
    if (getQuizDetails.data && getQuizDetails.isSuccess) {
        setQuestionList(getQuizDetails.data);
      }
    }, [getQuizDetails.data, getQuizDetails.isSuccess]);


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const answers = {};
    console.log("formData", formData.entries)

  // Convert formData into an array and iterate
  Array.from(formData.entries()).forEach(([key, value]) => {
    const questionId = key.split('_')[1];
    answers[questionId] = value;
  });
    console.log(answers);  // Process answers or send them to your backend
  }
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleCheckboxChange = (questionId, choice) => {
    const currentSelections = selectedAnswers[questionId] || [];
    const isSelected = currentSelections?.includes(choice);

    if (isSelected) {
      setSelectedAnswers({
        ...selectedAnswers,
        [questionId]: currentSelections.filter(item => item !== choice)
      });
    } else {
      setSelectedAnswers({
        ...selectedAnswers,
        [questionId]: [...currentSelections, choice]
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(selectedAnswers);
    const res = submitQuiz({id, selectedAnswers, accessToken})
    
    console.log(res);
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log(res.error)
     //  console.log(res.error.data.errors) 
      // setResourceError(res.error.data.errors)
    }
    if (res.data) {
    console.log("data", res.data)
    //  console.log("key", res.data.session_key)
    // handleClose();
    // window.location.reload();
    // navigate('/dashboard')
    }
  };
   
  return (
  <>
    <Grid container >
      <Grid item sm={9}
        sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}
      >
        <List>
          <FormGroup>
            {questionList.map((quiz, index) => (
              <ListItem key={quiz.questions.id} disablePadding sx={{ 
                display: 'block', 
                // backgroundColor: videoI.d === quiz.id ? '#f0f0f0' : 'inherit',
              }}>
                <ListItemButton
                //   sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  // component={NavLink} to={`/adminpage/attemptQuiz/${quiz.id}`}
                  // onClick={(e) => handleCurrentVideo(e, quiz.id, quiz.video)}
                >
                  <ListItemIcon 
                //   sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                  sx={{ minWidth: 0, mr: 1, justifyContent: 'center' }}>
                    {/* {quiz.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    {/* <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} /> */}
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={quiz.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    {/* <ListItemText primary={`Quiz ${index +1}`} */}
                    {/* <ListItemText primary={`Q.${index+1}  ${quiz.questions.text}`}
                    //  sx={{ opacity: open ? 1 : 0 }}>
                    sx={{ opacity: 1 }}>
                      {' '}
                    </ListItemText> */}
                    {/* {quiz?.choices?.map((item, index) => {
                      return (
                        <>
                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'black' } }}/>
                        <Typography variant="p" sx={{ flexShrink: 0 }}>
                      {item.text}
                    </Typography>
                        </>
                      )
                    })} */}
                    {/* <form onSubmit={handleFormSubmit}>  */}
                    {/* <FormControl component="fieldset"> */}
                    <FormLabel  component="legend">{`Q.${index+1}  ${quiz.questions.text}`}</FormLabel>
                      {/* <RadioGroup
                        name={`question_${quiz.questions.id}`}
                        value={value}
                        onChange={handleChange}
                      >
                        {quiz?.choices?.map((choice, index) => (
                          <FormControlLabel key={index} value={choice.text} control={<Radio />} label={choice.text} />
                        ))}
                      </RadioGroup> */}
                       {quiz?.choices?.map(choice => (
                        <FormControlLabel
                          key={choice.id}
                          control={
                            <Checkbox
                              // checked={selectedAnswers[quiz.questions.id] === choice.id}
                              checked={selectedAnswers[quiz.questions.id]?.includes(choice.id) || false}
                              onChange={() => handleCheckboxChange(quiz.questions.id, choice.id)}
                            />
                          }
                          label={choice.text}
                        />
                      ))}
                  {/* </FormControl> */}
                        
                  {/* </form> */}
                  </div>
                  {/* <ListItemText primary={quiz.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
            <Box textAlign='center'>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>  Submit</Button>
            </Box> 
          </FormGroup>
        </List>
         
      </Grid>
    </Grid>
  </>);
};

export default AttemptQuiz;
