import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { getToken } from '../../services/LocalStorageService'
import { useGetLoggedUserQuery } from '../../services/userAuthApi'
import { setUserInfo } from '../../features/userSlice'
import { setCourseInfo } from '../../features/courseSlice'
import { useGetAllCoursesQuery, useGetMyCoursesQuery } from '../../services/coursesApi'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function LearnerLayout() {
  const [open, setOpen] = useState(false);
  // const {courses, myCourses} = useSelector(state =>state.course)
  const {accessToken} = getToken()
  const dispatch = useDispatch()
  const [course, setCourses] = useState([]);
  const [userData, setUserData] = useState({})
  const {data, isSuccess} = useGetLoggedUserQuery(accessToken)
  const getAllCourses = useGetAllCoursesQuery(accessToken)
  const getMyCourses = useGetMyCoursesQuery(accessToken)


    // Store User Data in Redux Store
    useEffect(() => {
      if (getAllCourses.data && getMyCourses.isSuccess){
          dispatch(
            setCourseInfo({
              courses: getAllCourses.data,
              myCourses: getMyCourses.data
          }))
      }
    }, [getAllCourses.data, getAllCourses.isSuccess]) 
    
  useEffect(() => {
    if (data && isSuccess) {
        setUserData( data)
    }
  }, [data, isSuccess])

  // console.log("🚀 ~ file: LearnerLayout.js:39 ~ LearnerLayout ~ user:", courses)
    // Store User Data in redux State
    useEffect(() => {
      if (data && isSuccess) {
          dispatch(
              setUserInfo( {
                currentUser: userData
              } )
          )
      }
    }, [data, isSuccess, dispatch])
  // console.log("learnerDashboard", userData)

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
