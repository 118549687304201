import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, TextField, FormControlLabel, Checkbox, Alert, InputLabel, MenuItem, Select, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Avatar, CircularProgress, Input } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { useGetLoggedUserQuery, useProfileDataMutation } from '../services/userAuthApi'
import { getToken } from '../services/LocalStorageService'


const ProfileData = ({id}) => {
  // States
  const [title, setTitle] = useState("None")
  const [idNumber, setIdnumber] = useState()
  const [dateofBirth, setDob] = useState(null)
  const [ethnicity, setEthnicity] = useState('')
  const [gender, setGender] = useState()
  const [citizenship, setCitizenship] = useState("None of these")
  const [disability, setDisability] = useState("None of these")
  // const [disability, setDisability] = useState([])
  const [alertUser, setAlert] = useState(false)
  const navigate = useNavigate()
  const [profileData, { isLoading }] = useProfileDataMutation()
  const {accessToken} = getToken()
 
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: ""
  })

  const TITLE = [
    ['None', 'None'],
    ['Mr.', 'Mr.'],
    ['Miss.', 'Miss.'],
    ['Mrs.', 'Mrs.'],
    ['Prof.', 'Prof.'],
    ['Dr.', 'Dr.'],
  ]
 
  const DISABILITY = [
    ['None of these', 'None of these'],
    ['VI', 'Visual Impairment'],
    ['HI', 'Hearing Impairment'],
    ['MI', 'Mobility Impairment'],
    ['SLI', 'Speech and Language Impairment'],
  ];

  const COUNTRY_CHOICES = [
    ['None of these', 'None of these'],
    ['Nigeria', 'Nigeria'],
    ['Ethiopia', 'Ethiopia'],
    ['Egypt', 'Egypt'],
    ['DR Congo', 'DR Congo'],
    ['Tanzania', 'Tanzania'],
    ['South Africa', 'South Africa'],
    ['Kenya', 'Kenya'],
    ['Uganda', 'Uganda'],
    ['Algeria', 'Algeria'],
    ['Sudan', 'Sudan'],
    ['Morocco', 'Morocco'],
    ['Angola', 'Angola'],
    ['Mozambique', 'Mozambique'],
    ['Madagascar', 'Madagascar'],
    ['Cameron', 'Cameron'],
    ["Cote d'Ivoire", "Cote d'Ivoire"],
    ['Niger', 'Niger'],
    ['Burkina Faso', 'Burkina Faso'],
    ['Mali', 'Mali'],
    ['Malawi', 'Malawi'],
    ['Zambia', 'Zambia'],
    ['Senegal', 'Senegal'],
    ['Chad', 'Chad'],
    ['Somalia', 'Somalia'],
    ['Zimbabwe', 'Zimbabwe'],
    ['Guinea', 'Guinea'],
    ['Rwanda', 'Rwanda'],
    ['Benin', 'Benin'],
    ['Burundi', 'Burundi'],
    ['Tunisia', 'Tunisia'],
    ['South Sudan', 'South Sudan'],
    ['Togo', 'Togo'],
    ['Sierra Leone', 'Sierra Leone'],
    ['Libya', 'Libya'],
    ['Congo', 'Congo'],
    ['Liberia', 'Liberia'],
    ['Central African Republic', 'Central African Republic'],
    ['Mauritania', 'Mauritania'],
    ['Eritrea', 'Eritrea'],
    ['Namibia', 'Namibia'],
    ['Gambia', 'Gambia'],
    ['Botswana', 'Botswana'],
    ['Gabon', 'Gabon'],
    ['Lesotho', 'Lesotho'],
    ['Guinea-Bissau', 'Guinea-Bissau'],
    ['Equatorial Guinea', 'Equatorial Guinea'],
    ['Mauritius', 'Mauritius'],
    ['Eswatini', 'Eswatini'],
    ['Djibouti', 'Djibouti'],
    ['Comoros', 'Comoros'],
    ['Cabo Verde', 'Cabo Verde'],
    ['Sao Tome & Principe', 'Sao Tome & Principe'],
    ['Sychelles', 'Sychelles'],
    // ... other country choices
  ];
  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('user', id)
    data.append('title', title)
    data.append('id_number', idNumber)
    data.append('date_of_birth', dateofBirth == null ? null : format(dateofBirth, 'yyyy-MM-dd'))
    data.append('ethnicity', ethnicity)
    data.append('gender', gender)
    data.append('citizenship', citizenship)
    data.append('disability', disability)
    
     // data.append('pimage', pimage)
    //  console.log(disability)
    // console.log(citizenship)
    // console.log("file", specialPowerAttorney)
    // if (title && ethnicity && gender && citizenship) {  
      const res = await profileData({ data, accessToken })
      // console.log(res)
      // console.log(res.data)
      // console.log(res.error)
      if (res.data) {
        // console.log("data")
          setError({ status: true, msg: "ProfileData Uploaded Successfully", type: 'success' })
          setAlert(true)
          // resetForm()
          // navigate("/learnerpage/dashboard")
       } else if (res.error) {
        // console.log("error1", res.error)
        setError({ status: true, msg: "All Fields are Required", type: 'error' })
      }else  {
        // console.log("error2", res.error)
        setError({ status: true, msg: "All Fields are Required", type: 'error' })
      }
    
    // data[0].map(item =>console.log(item))
    
    // console.log(specialPowerAttorney);
  }


  const resetForm = () => {
  setTitle('')
  setIdnumber('')
  setDob(null)
  setEthnicity('')
  setGender('')
  // setPjl([])
  setCitizenship('')
  setDisability('')
  document.getElementById('profileDetails-form').reset()
  }
 
       
  return (<>
    <Box component="form" sx={{ p: 1 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}> 
     <TextField fullWidth margin='normal' id="title" select name="title" label="Title" SelectProps={{ native: true, }} onChange={(e) => setTitle(e.target.value)}>
       {TITLE.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>
     
      <TextField margin='normal' required fullWidth id='idNumber' name='idNumber' label='ID number' onChange={(e) => setIdnumber(e.target.value)}/>

      <TextField margin='normal' fullWidth label="Select a Date" id="dateofBirth" name="dateofBirth" type="date" InputLabelProps={{ shrink: true, }} />
      
      <TextField fullWidth margin='normal' id="citizenship" select name="citizenship" label="Citizenship" SelectProps={{native: true, }} onChange={(e) => setCitizenship(e.target.value)}>          
        {COUNTRY_CHOICES.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>
      
      <TextField fullWidth margin='normal' id="disability" select name="disability" label="Disability" SelectProps={{native: true, }} onChange={(e) => setDisability(e.target.value)}>          
        {DISABILITY.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>

      <TextField id="gender" required fullWidth margin='normal' label='Gender' onChange={(e) => setGender(e.target.value)} />
      
      {/* <TextField id="email" email="email" required fullWidth margin='normal' label='Email' onChange={(e) => setEmail(e.target.value)} /> */}
      
      {/* <Box mt={2}> */}
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker label="Date of Birth" value={dob} onChange={(newValue) => { setDob(newValue) }} renderInput={(params) => <TextField {...params} />} />
        </LocalizationProvider> */}
      {/* </Box> */}
      <TextField margin='normal' required fullWidth id='ethnicity' name='ethnicity' label='Ethnicity' onChange={(e) => setEthnicity(e.target.value)}/>
      <Box textAlign='center'>
        {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>}
      </Box>
      {/* {alertUser?  <Alert severity="success">Your Data is Saved Successfully!</Alert> : ""} */}
      {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
      </Box>  
    </>)
}

export default ProfileData