export const TITLE = [
    ['None', 'None'],
    ['Mr.', 'Mr.'],
    ['Miss.', 'Miss.'],
    ['Mrs.', 'Mrs.'],
    ['Prof.', 'Prof.'],
    ['Dr.', 'Dr.'],
  ]
 
  export const DISABILITY = [
    ['None of these', 'None of these'],
    ['VI', 'Visual Impairment'],
    ['HI', 'Hearing Impairment'],
    ['MI', 'Mobility Impairment'],
    ['SLI', 'Speech and Language Impairment'],
  ];

  export const COUNTRY_CHOICES = [
    ['None of these', 'None of these'],
    ['Nigeria', 'Nigeria'],
    ['Ethiopia', 'Ethiopia'],
    ['Egypt', 'Egypt'],
    ['DR Congo', 'DR Congo'],
    ['Tanzania', 'Tanzania'],
    ['South Africa', 'South Africa'],
    ['Kenya', 'Kenya'],
    ['Uganda', 'Uganda'],
    ['Algeria', 'Algeria'],
    ['Sudan', 'Sudan'],
    ['Morocco', 'Morocco'],
    ['Angola', 'Angola'],
    ['Mozambique', 'Mozambique'],
    ['Madagascar', 'Madagascar'],
    ['Cameron', 'Cameron'],
    ["Cote d'Ivoire", "Cote d'Ivoire"],
    ['Niger', 'Niger'],
    ['Burkina Faso', 'Burkina Faso'],
    ['Mali', 'Mali'],
    ['Malawi', 'Malawi'],
    ['Zambia', 'Zambia'],
    ['Senegal', 'Senegal'],
    ['Chad', 'Chad'],
    ['Somalia', 'Somalia'],
    ['Zimbabwe', 'Zimbabwe'],
    ['Guinea', 'Guinea'],
    ['Rwanda', 'Rwanda'],
    ['Benin', 'Benin'],
    ['Burundi', 'Burundi'],
    ['Tunisia', 'Tunisia'],
    ['South Sudan', 'South Sudan'],
    ['Togo', 'Togo'],
    ['Sierra Leone', 'Sierra Leone'],
    ['Libya', 'Libya'],
    ['Congo', 'Congo'],
    ['Liberia', 'Liberia'],
    ['Central African Republic', 'Central African Republic'],
    ['Mauritania', 'Mauritania'],
    ['Eritrea', 'Eritrea'],
    ['Namibia', 'Namibia'],
    ['Gambia', 'Gambia'],
    ['Botswana', 'Botswana'],
    ['Gabon', 'Gabon'],
    ['Lesotho', 'Lesotho'],
    ['Guinea-Bissau', 'Guinea-Bissau'],
    ['Equatorial Guinea', 'Equatorial Guinea'],
    ['Mauritius', 'Mauritius'],
    ['Eswatini', 'Eswatini'],
    ['Djibouti', 'Djibouti'],
    ['Comoros', 'Comoros'],
    ['Cabo Verde', 'Cabo Verde'],
    ['Sao Tome & Principe', 'Sao Tome & Principe'],
    ['Sychelles', 'Sychelles'],
    // ... other country choices
  ];
  export const EMPLOYMENT = [
    [
      'None of these',
      'None of these',
    ],
    [
      'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
      'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
    ],
    [
      'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
      'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
    ],
    ['I am presently employed', 'I am presently employed'],
  ];
