import { Box, Container, Typography } from '@mui/material'
import React from 'react';
import { Link } from 'react-router-dom'



function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" to="http://natiive.in/" target='_blank'>
          Y-Plan
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
export default function Footer() {
    return (
        <>
            <Box
                component="footer"
                sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                justifyContent: 'center',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="sm">
                    <Typography variant="body1" align="center">
                    National Youth Database and LMS Solution
                    </Typography>
                    <Copyright />
                </Container>
                </Box>
        </>
    )
}