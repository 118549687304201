import React from 'react'
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GiteIcon from '@mui/icons-material/Gite';
import SchoolIcon from '@mui/icons-material/School';
import DraftsIcon from '@mui/icons-material/Drafts';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import GroupIcon from '@mui/icons-material/Group';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

// NavSection.propTypes = {
//   data: PropTypes.array,
// };

export default function AdminNavSection({ data = [], ...other }) {
    const [hostOpen, setHostOpen] = React.useState(false);
    const [clientOpen, setClientOpen] = React.useState(false);
    const [userOpen, setUserOpen] = React.useState(false);

    const handleHostClick = () => {
      setHostOpen(!hostOpen);
    };
    const handleClientClick = () => {
      setClientOpen(!clientOpen);
    };
    const handleUserClick = () => {
      setUserOpen(!userOpen);
    };

  return (
    <Box {...other}>
      {/* <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List> */}
      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
       // <ListSubheader component="div" id="nested-list-subheader">
       //   Nested List Items
        // </ListSubheader>
      // }
    >
      <ListItemButton  component={RouterLink} to="/adminpage/app" >
        <ListItemIcon>
          <SpaceDashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton  component={RouterLink} to="/adminpage/learners" >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Learners" />
      </ListItemButton>

      {/* Host  */}
      <ListItemButton onClick={handleHostClick}>
        <ListItemIcon>
          <GiteIcon />
        </ListItemIcon>
        <ListItemText primary="Host" />
        {hostOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={hostOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={RouterLink} to="/adminpage/hosts" sx={{ pl: 4 }}>
            <ListItemIcon>
              <GiteIcon />
            </ListItemIcon>
            <ListItemText primary="Host" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/adminpage/subhosts" sx={{ pl: 4 }}>
            <ListItemIcon>
              <GiteIcon />
            </ListItemIcon>
            <ListItemText primary="Subhosts" />
          </ListItemButton>
        </List>
      </Collapse>     
      
      {/* Client */}
      <ListItemButton onClick={handleClientClick}>
        <ListItemIcon>
          <PersonPinIcon />
        </ListItemIcon>
        <ListItemText primary="Clients" />
        {clientOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={clientOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={RouterLink} to="/adminpage/clients" sx={{ pl: 4 }}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/adminpage/projects" sx={{ pl: 4 }}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItemButton>
        </List>
      </Collapse>      
      
      {/* Users  */}
      <ListItemButton onClick={handleUserClick}>
        <ListItemIcon>
          <PeopleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {userOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={userOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={RouterLink} to="/adminpage/users" sx={{ pl: 4 }}>
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="All Users" />
          </ListItemButton> 
          <ListItemButton component={RouterLink} to="/adminpage/userRequests" sx={{ pl: 4 }}>
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary="User Requests" />
          </ListItemButton> 
          <ListItemButton component={RouterLink} to="/adminpage/userGroups" sx={{ pl: 4 }}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="User Groups" />
          </ListItemButton>
        </List>
      </Collapse>


      <ListItemButton  component={RouterLink} to="/adminpage/academy" >
        <ListItemIcon>
          <CorporateFareIcon />
        </ListItemIcon>
        <ListItemText primary="Academies" />
      </ListItemButton>

      <ListItemButton  component={RouterLink} to="/adminpage/courses" >
        <ListItemIcon>
          <OnDeviceTrainingIcon />
        </ListItemIcon>
        <ListItemText primary="Courses" />
      </ListItemButton>
    </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
// };

// function NavItem({ item }) {
//   const { title, path, icon, info } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         '&.active': {
//           color: 'text.primary',
//           bgcolor: 'action.selected',
//           fontWeight: 'fontWeightBold',
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

//       <ListItemText disableTypography primary={title} />

//       {info && info}
//     </StyledNavItem>
//   );
// }
