/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Modal,
  Box,
  TextField,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../features/userSlice';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from '../services/LocalStorageService';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';


import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';

import {
  useCreateHostMutation,
  useGetAcademyQuery,
  useGetAllUsersQuery,
  useGetClientListQuery,
  useGetLoggedUserQuery,
  useUpdateHostMutation,
  useGetHostListQuery,
} from '../services/userAuthApi';
import currentNetwork from '../_mock/network';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'company_name', label: 'Company Name', alignRight: false },
  /*  { id: 'role', label: 'Role', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false }, */
  { id: 'Actions', label: 'Actions', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListHosts = () => {
  // const {hosts} = useSelector(state => state.user)
  const [hosts, setHosts] = useState([]);
  const [createHost, { isLoading }] = useCreateHostMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();
  const [open, setOpen] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
const [updateHost] = useUpdateHostMutation();
  const [academyList, setAcademyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  console.log(clientList);
  const [academy, setAcademy] = useState('');
  const [client, setClient] = useState('');

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const getAllUsers = useGetAllUsersQuery(accessToken);
  const getAcademy = useGetAcademyQuery(accessToken);
  const getAllClients = useGetClientListQuery(accessToken);
  const getAllHosts = useGetHostListQuery(accessToken);
  const [editModal, setEditModal] = useState(false);

  const [selectedHost, setSelectedHost] = useState([]);
  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess) {
      var hostData = getAllUsers.data.filter((curElem) => {
        return curElem.is_host === true;
      });
      var clientData = getAllUsers.data.filter((curElem) => {
        return curElem.is_client === true;
      });
     // setHosts(hostData);
      // setClientList(clientData);
    }
  }, [getAllUsers.data, getAllUsers.isSuccess]);

  useEffect(() => {
    if (getAllHosts.data && getAllHosts.isSuccess) {
      setHosts(getAllHosts.data);
    }
  }, [getAllHosts.data, getAllHosts.isSuccess]);
  useEffect(() => {
    if (getAllClients.data && getAllClients.isSuccess) {
      setClientList(getAllClients.data);
    }
  }, [getAllClients.data, getAllClients.isSuccess]);

  useEffect(() => {
    if (getAcademy.data && getAcademy.isSuccess) {
      setAcademyList(getAcademy.data);
    }
  }, [getAcademy.data, getAcademy.isSuccess]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedHost(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleEditClose = () => setEditModal(!editModal);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen();
  };
  const submitHost = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const actualData = {
      name: data.get('name'),
      company_name: data.get('company_name'),
      email: data.get('email'),
      phone: data.get('phone'),
      department: data.get('department'),
      address: data.get('address'),
      region: data.get('region'),
      client: client,
      // academy: academy,
    };
    console.log('data:', actualData);
    const res = await createHost({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();
      window.location.reload();
      // navigate('/clientpage/hosts');
    }
  };

  const handleAcademyChange = (event) => {
    setAcademy(event.target.value);
  };
  const handleClientChange = (event) => {
    setClient(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (getAllUsers.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (getAllUsers.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  ///////////////////////Delete modal funtions
    const handleDeleteModalClose = () => setDeleteModal(!deleteModal);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedHost?.id;
    console.log('data from update host:', actualData);

    const res = await deleteHost({ actualData, id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      handleDeleteModalClose();

      window.location.reload();
    }
  };

   const editHostSubmit = async (values) => {
     //console.log(values)

     // const data = new FormData(e.currentTarget);

     const actualData = {
       company_name: values.company_name,
       department: values.department,
       address: values.address,
       region: values.region,
       client: values.client,
       qualification: values.qualification,
       bio: values.bio,
       academy: values.academy,
       research_interests: values.research_interests,
       report_status: values.report_status,
     };
     const id = selectedHost?.id;
     console.log('data from update host:', actualData);

     const res = await updateHost({ actualData, id, accessToken });
     if (res.error) {
       // console.log(typeof (res.error.data.errors))
       console.log('error', res.error);
       setServerError(res.error.data.errors);
     }
     if (res.data) {
       // console.log(typeof res.data);
       console.log('data', res.data);
       // console.log("token", res.data.token)

       handleEditClose();

       window.location.reload();
       // navigate('/adminpage/hosts');
     }
   };

  if (getAllUsers.isError) {
    return (
      <h1 className="text-center" mt={10} mx={10}>
        <br />
        <br />
        <br />
        No User Data Found...
      </h1>
    );
  } else {
    return (
      <>
        <Helmet>
          <title> Hosts | Y-Plan </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Hosts
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>
              New Host
            </Button>
          </Stack>
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={hosts.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                    {hosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const {
                        id,
                        name,
                        email,
                        image,
                        phone,
                        company_name,
                        is_active,
                        is_admin,
                        is_host,
                        is_learner,
                        account_activated,
                        user
                      } = row;
                      const isActive = String(is_active);
                      const accountActivated = String(account_activated);
                      const selectedUser = selected.indexOf(name) !== -1;
                      if (is_admin) {
                        var role = 'Admin';
                      } else if (is_learner) {
                        var role = 'Learner';
                      } else if (is_host) {
                        var role = 'Host';
                      }
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          <NavLink to={`http://${currentNetwork}/academy/hosts/${id}`}>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={user.name} src={user.image} />
                                <Typography variant="subtitle2" noWrap>
                                  {user.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                          </NavLink>

                          <TableCell align="left">{user.phone}</TableCell>

                          <TableCell align="left">{user.email}</TableCell>

                           <TableCell align="left">{company_name}</TableCell>  

                         {/*  <TableCell align="left">
                            <Label color={(accountActivated === 'banned' && 'error') || 'success'}>
                              {sentenceCase(accountActivated)}
                            </Label>
                          </TableCell> */}

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={hosts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => setEditModal(!editModal)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>

        {/* Create host Modal  */}
        <Modal
          component="form"
          open={openOTP}
          onClose={handleClose}
          id="otp-form"
          onSubmit={submitHost}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{backgrou}}
        >
          <Box noValidate sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create a Host
            </Typography>

            <Stack direction="row">
              <TextField margin="normal" required fullWidth id="name" name="name" label="Name" />
              {serverError.name ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
              ) : (
                ''
              )}
              <TextField margin="normal" required fullWidth id="department" name="department" label="department" />
              {serverError.department ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.department[0]}
                </Typography>
              ) : (
                ''
              )}
            </Stack>

            <Stack direction="row">
              <TextField margin="normal" required fullWidth id="email" name="email" label="email" />
              {serverError.email ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.phoneOtp[0]}
                </Typography>
              ) : (
                ''
              )}
              <TextField margin="normal" required fullWidth id="phone" name="phone" label="phone" />
              {serverError.phone ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography>
              ) : (
                ''
              )}
            </Stack>

            <Stack direction="row">
              <TextField
                margin="normal"
                required
                fullWidth
                id="company_name"
                name="company_name"
                label="Name of Company"
              />
              {serverError.company_name ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.company_name[0]}
                </Typography>
              ) : (
                ''
              )}
              <TextField margin="normal" required fullWidth id="address" name="address" label="Address" />
              {serverError.address ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.address[0]}
                </Typography>
              ) : (
                ''
              )}
            </Stack>

            <Stack direction="row">
              {/* <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-label">Academy</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={academy}
                  label="Category"
                  onChange={handleAcademyChange}
                >
                  {academyList.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <TextField margin="normal" required fullWidth id="region" name="region" label="Region" />
              {serverError.region ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.region[0]}</Typography>
              ) : (
                ''
              )}
              <FormControl margin="normal" fullWidth mx={2}>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={client}
                  label="Client"
                  onChange={handleClientChange}
                >
                  {clientList.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.user.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Box textAlign="center">
              <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                  Create Host
                </Button>
              )}
            </Box>
            {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
          </Box>
        </Modal>

        {/* /////////////////////////////////////////////////////////////////////////////// */}

        {/* Edit host Modal  */}
        <Modal
          open={editModal}
          onClose={handleEditClose}
          id="otp-form"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ border: 'none' }}
        >
          <Formik
            initialValues={{
              department: selectedHost?.department || '',

              qualification: selectedHost?.qualification || '',
              bio: selectedHost?.bio || '',
              company_name: selectedHost?.company_name || '',
              address: selectedHost?.address || '',
              region: selectedHost?.region || '',
              research_interests: selectedHost?.research_interests || '',
              report_status: selectedHost?.report_status || '',
              client: '',
              academy: '',
            }}
            onSubmit={(values) => editHostSubmit(values)}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <Box noValidate sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Edit Host
                  </Typography>
                  {console.log(selectedHost)}

                  <Stack direction="row">
                    <FormControl margin="normal" fullWidth mx={2}>
                      <InputLabel id="demo-simple-select-label">Academy</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="academy"
                        value={values.academy}
                        onChange={(e) => {
                          handleChange(e);
                          handleClientChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {academyList.map((academy) => (
                          <MenuItem key={academy.id} value={academy.id}>
                            {academy.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth mx={2}>
                      <InputLabel id="demo-simple-select-label">Client</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="client"
                        value={values.client}
                        onChange={(e) => {
                          handleChange(e);
                          handleClientChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {clientList.map((client) => (
                          <MenuItem key={client.id} value={client.id}>
                            {client.user.email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack direction="row">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="qualification"
                      name="qualification"
                      label="Qualification"
                      value={values.qualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.qualification && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.qualification[0]}
                      </Typography>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="bio"
                      name="bio"
                      label="Bio"
                      value={values.bio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.bio && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.bio[0]}
                      </Typography>
                    )}
                  </Stack>

                  <Stack direction="row">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="company_name"
                      name="company_name"
                      label="Name of Company"
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.company_name && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.company_name[0]}
                      </Typography>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="address"
                      name="address"
                      label="Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.address && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.address[0]}
                      </Typography>
                    )}
                  </Stack>

                  <Stack direction="row">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="region"
                      name="region"
                      label="Region"
                      value={values.region}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.region && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.region[0]}
                      </Typography>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="department"
                      name="department"
                      label="Department"
                      value={values.department}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.department && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.department[0]}
                      </Typography>
                    )}
                  </Stack>

                  <Stack direction="row">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="research_interests"
                      name="research_interests"
                      label="Research Interests"
                      value={values.research_interests}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.research_interests && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.research_interests[0]}
                      </Typography>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="report_status"
                      name="report_status"
                      label="Report Status"
                      value={values.report_status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {serverError.report_status && (
                      <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                        {serverError.report_status[0]}
                      </Typography>
                    )}
                  </Stack>

                  <Box textAlign="right">
                    <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                      Cancel
                    </Button>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                        Edit Host
                      </Button>
                    )}
                  </Box>
                  {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
                </Box>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* Delete Modal Popup */}
        <Modal
          open={deleteModal}
          onClose={handleDeleteModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ border: 'none' }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete SubHost
            </Typography>

            <form onSubmit={handleDeleteModalSubmit} noValidate>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    background: '#bf3f57',
                    padding: '1rem',
                    marginBottom: '.5rem',
                  }}
                >
                  <AiOutlineDelete size={23} color="#fff" />
                </div>
                <h5>
                  {' '}
                  Do you want to Delete{' '}
                  <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedHost?.name}</span> Sub-Host ?{' '}
                </h5>
              </div>

              <Box textAlign="center">
                <Button
                  onClick={handleDeleteModalClose}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2, px: 5 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                  Delete
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </>
    );
  }
};

export default ListHosts;
