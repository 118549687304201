import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Alert,
  CircularProgress,
  Avatar,
} from '@mui/material';
// components
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useAdminLoginMutation, useClientLoginMutation } from '../services/userAuthApi';
// hooks
import Logo from '../components/logo';
import Iconify from '../components/iconify';

import {useResponsive} from '../hooks/use-responsive';
import { getToken, storeToken } from '../services/LocalStorageService';
import { setUserToken } from '../features/authSlice';
import Footer from './Footer';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ClientLogin() {
  const mdUp = useResponsive('up', 'md');
  const [showPassword, setShowPassword] = useState(false);
  const [clientLogin, { isLoading }] = useClientLoginMutation();
  const [serverError, setServerError] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'), // name of the field
      password: data.get('password'),
    };
    const res = await clientLogin(actualData);
    // res = await adminLogin(actualData)
    if (res.error) {
      setServerError(res.error.data.errors);
      // console.log(res.error);
    }
    if (res.data) {
      // console.log(typeof res.data);
      // console.log(res.data);
      // navigate('/learner/dashboard')
      storeToken(res.data.token);
      const { accessToken } = getToken();
      dispatch(setUserToken({ accessToken }));
      navigate('/clientpage/app');
    }
  };

  return (
    <>
      <Helmet>
        <title> ClientLogin | Y-Plan </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 2, mb: 5 }}>
              Hi, Welcome Back Client!
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Box
              sx={{
                my: 4,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main', alignContent: 'center' }}>
                <LockOpenIcon />
              </Avatar>
              <Typography variant="h4" gutterBottom>
                Sign in to Y-Plan
              </Typography>
            </Box>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2"  component={NavLink} to="/register">Get started</Link>
            </Typography> */}

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <Box component="form" noValidate sx={{ mt: 1 }} id="login-form" onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField name="email" label="Email address" />
                {serverError.email ? (
                  <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                    {serverError.email[0]}
                  </Typography>
                ) : (
                  ''
                )}
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {serverError.password ? (
                  <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                    {serverError.password[0]}
                  </Typography>
                ) : (
                  ''
                )}
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <Checkbox name="remember" style={{color:"primary"}} label="Remember me" /> */}
                <Link variant="subtitle2" component={NavLink} to="/sendPasswordResetMail" underline="hover">
                  Forgot Password
                </Link>
              </Stack>

              <Box textAlign="center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Login
                  </Button>
                )}
              </Box>
              {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
            </Box>

            {/* <LoginForm /> */}
          </StyledContent>
        </Container>
      </StyledRoot>
      <Footer />
    </>
  );
}
