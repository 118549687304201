/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
// import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as Yup from 'yup';

import { getToken } from '../services/LocalStorageService';
import {
  useCourseDetailsQuery,
  useCreateModuleMutation,
  useCreateQuizMutation,
  useGetAllQuizQuery,
  useGetCourseModulesQuery,
  useGetCourseResoursesQuery,
  useGetCourseVideosQuery,
  useUpdateCourseMutation,
} from '../services/coursesApi';
import { useGetHostListQuery, useGetAcademyQuery } from '../services/userAuthApi';
import Iconify from '../components/iconify/Iconify';
import currentServer from '../_mock/server';
import { Controller, useForm } from 'react-hook-form';
import { preventDefault } from '@fullcalendar/core/internal';
// import { TabPanel } from '@mui/lab'
import { useCreateCourseMutation, useGetCategoriesQuery, useGetSubCategoriesQuery } from '../services/coursesApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CourseSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  small_description: Yup.string().required('Small Description is required'),
  learned: Yup.string().required('Learned field is required'),
  rating: Yup.number().min(0).max(5).required('Rating is required'),
  total_video: Yup.number().required('Total video is required'),
  vidoes_time: Yup.number().required('Total video time is required'),
  total_module: Yup.number().required('Total module is required'),
  organization: Yup.string().required('Organization is required'),
  image_course: Yup.mixed().required('Course image is required'),
  host: Yup.string().required('Host is required'),
});

const ClientCourseMaintain = () => {
  const { slug } = useParams();
  const { accessToken } = getToken();
  const theme = useTheme();
  const [courseData, setCourseData] = useState({});
  const [moduleList, setModuleList] = useState([]);
  const [resourseList, setResourseList] = useState([]);
  const [videosList, setVideosList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [value, setValue] = useState(0);
  const [error, setError] = useState({});
  const [serverError, setServerError] = useState({});
  const [openModule, setOpenModule] = useState(false);
  const [moduleError, setModuleError] = useState({});
  const myHosts = useGetHostListQuery(accessToken);
  const courseDetails = useCourseDetailsQuery({ slug, accessToken });
  const moduleDetails = useGetCourseModulesQuery({ slug, accessToken });
  const resourseDetails = useGetCourseResoursesQuery({ slug, accessToken });
  const videoDetails = useGetCourseVideosQuery({ slug, accessToken });
  const getAllQuiz = useGetAllQuizQuery({ slug, accessToken });
  const [createQuiz] = useCreateQuizMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [createModule, { isLoading }] = useCreateModuleMutation();
  const [hostList, setHostsList] = useState([]);
  const getCategory = useGetCategoriesQuery(accessToken);
  const getSubCategory = useGetSubCategoriesQuery(accessToken);
  const getAcademy = useGetAcademyQuery(accessToken);
  // const academyList = useGetAcademyQuery(accessToken);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState('');
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState('');
  const [academy, setAcademy] = useState('');
  const [academyList, setAcademyList] = useState([]);

  // console.log("🚀 ~ MaintainCourse ~ getAllQuiz:", getAllQuiz)
  // Storing data to the local state
  useEffect(() => {
    if (courseDetails.data && courseDetails.isSuccess) {
      setCourseData(courseDetails.data);
    }
  }, [courseDetails.data, courseDetails.isSuccess]);
  useEffect(() => {
    if (moduleDetails.data && moduleDetails.isSuccess) {
      setModuleList(moduleDetails.data);
    }
  }, [moduleDetails.data, moduleDetails.isSuccess]);
  useEffect(() => {
    if (resourseDetails.data && resourseDetails.isSuccess) {
      setResourseList(resourseDetails.data);
    }
  }, [resourseDetails.data, resourseDetails.isSuccess]);
  useEffect(() => {
    if (videoDetails.data && videoDetails.isSuccess) {
      setVideosList(videoDetails.data);
    }
  }, [videoDetails.data, videoDetails.isSuccess]);
  useEffect(() => {
    if (getAllQuiz.data && getAllQuiz.isSuccess) {
      setQuizList(getAllQuiz.data);
    }
  }, [getAllQuiz.data, getAllQuiz.isSuccess]);
  //   console.log("course", courseData)
  useEffect(() => {
    if (myHosts.data && myHosts.isSuccess) {
      setHostsList(myHosts.data);
    }
  }, [myHosts.data, myHosts.isSuccess]);

  // settings CategoryList
  useEffect(() => {
    if (getCategory.data && getCategory.isSuccess) {
      setCategoryList(getCategory.data);
    }
  }, [getCategory.data, getCategory.isSuccess]);

  // settings SubCategoryList
  useEffect(() => {
    if (getSubCategory.data && getSubCategory.isSuccess) {
      setSubCategoryList(getSubCategory.data);
    }
  }, [getSubCategory.data, getSubCategory.isSuccess]);

  // settings academyList
  useEffect(() => {
    if (getAcademy.data && getAcademy.isSuccess) {
      setAcademyList(getAcademy.data);
    }
  }, [getAcademy.data, getAcademy.isSuccess]);

  const {
    id: alias,
    description,
    // academy,
   //  category,
    // subCategory,
    enroller_user,
    image_course,
    created_at,
    //host,
    learned,
    name,
    organization,
    rating,
    small_description,
    tags,
    total_module,
    total_video,
    updated_at,
    vidoes_time,
  } = courseData;
  // console.log("🚀 ~ file: MaintainCourse.js:51 ~ MaintainCourse ~ image_course:", image_course)

  const [expanded, setExpanded] = useState(false);
  const [host, setHost] = useState('');
  const handleModulesOpen = () => setOpenModule(true);
  const handleModulesClose = () => setOpenModule(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubmit = () => {};
  const handleHostChange = (event) => {
    // setAge(Number(event.target.value) || '');
    setHost(event.target.value);
    console.log(host);
  };

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleAddQuiz = async (e) => {
    e.preventDefault();
    const actualData = {
      slug: slug,
    };
    const res = await createQuiz({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      //  console.log(res.error)
      //  console.log(res.error.data.errors)
      setError(res.error.data.errors);
    }
    if (res.data) {
      console.log('data', res.data);
      //  console.log("key", res.data.session_key)
      // handleClose();
      window.location.reload();
      // navigate('/dashboard')
    }
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleAddModules = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      name: data.get('name'),
      description: data.get('description'),
    };
    // actualData.append("name", name)
    // actualData.append("description", description)
    // actualData.append("file", resourceFile)
    // actualData.append("url", url)
    // console.log(actualData)
    const res = await createModule({ slug, data, accessToken });
    console.log('res', res);
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      //  console.log(res.error)
      //  console.log(res.error.data.errors)
      setModuleError(res.error.data.errors);
    }
    if (res.data) {
      console.log('data', res.data);
      //  console.log("key", res.data.session_key)
      // handleClose();
      window.location.reload();
      // navigate('/dashboard')
    }
  };
   const CourseDetails = () => {
     const initialValues = {
       name: name || '',
       description: description || '',
       small_description: small_description || '',
       category: category || '',
       academy: academy || '',
       subCategory: subCategory || '',
       learned: learned || '',
       rating: rating || 0,
       total_video: total_video || 0,
       vidoes_time: vidoes_time || 0,
       total_module: total_module || 0,
       image_course: image_course || null,
       host: '',
     };

     const handleEditSubmit = async (values, e) => {
       e.preventDefault();
       const data = new FormData();
       data.append('name', values.name);
       data.append('description', values.description);
       data.append('small_description', values.small_description);
       data.append('learned', values.learned);
       data.append('rating', values.rating);
       data.append('total_video', values.total_video);
       data.append('vidoes_time', values.vidoes_time);
       data.append('total_module', values.total_module);
       data.append('image_course', values.image_course);
       data.append('host', values.host);
       data.append('category', values.category);
       data.append('academy', values.academy);
       data.append('subCategory', values.subCategory);

       try {
         const res = await updateCourse({ slug, data, accessToken });

         console.log('Response:', res.data);
         window.location.reload();
       } catch (error) {
         console.error('Error updating course:', error);
       }
     };

     if (courseDetails.isError) {
       return (
         <Typography variant="h6" sx={{ flexShrink: 0 }}>
           No Course Details was found. Please update...
         </Typography>
       );
     } else if (courseDetails.isLoading) {
       return <p>Loading...</p>;
     } else if (courseDetails.isSuccess) {
       return (
         <>
           <Formik initialValues={initialValues} validationSchema={CourseSchema}>
             {({ values, setFieldValue, handleChange, isSubmitting }) => (
               <Form>
                 <Grid container spacing={3}>
                   <Grid item xs={12} sm={6}>
                     <Typography>Name</Typography>
                     <Field name="name" as={TextField} fullWidth />
                     <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Description</Typography>
                     <Field name="description" as={TextField} fullWidth />
                     <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12}>
                     <Typography>Small Description</Typography>
                     <Field name="small_description" as={TextField} fullWidth />
                     <ErrorMessage name="small_description" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12}>
                     <Typography>Learned</Typography>
                     <Field name="learned" as={TextField} fullWidth />
                     <ErrorMessage name="learned" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Rating</Typography>
                     <Field name="rating" type="number" as={TextField} fullWidth />
                     <ErrorMessage name="rating" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Total Videos</Typography>
                     <Field name="total_video" type="number" as={TextField} fullWidth />
                     <ErrorMessage name="total_video" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Video Time (in minutes)</Typography>
                     <Field name="vidoes_time" type="number" as={TextField} fullWidth />
                     <ErrorMessage name="vidoes_time" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Total Modules</Typography>
                     <Field name="total_module" type="number" as={TextField} fullWidth />
                     <ErrorMessage name="total_module" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12} sm={6}>
                     <Typography>Host</Typography>
                     <FormControl sx={{ m: 1, minWidth: 120 }}>
                       <Select
                         native
                         value={values.host}
                         name="host"
                         onChange={(event) => setFieldValue('host', event.target.value)}
                         input={<OutlinedInput label="Hosts" id="demo-dialog-native" />}
                       >
                         <option aria-label="None" value="" />
                         {hostList.map((host) => (
                           <option key={host.id} value={host.id}>
                             {host.user.name}
                           </option>
                         ))}
                       </Select>
                     </FormControl>
                     <Stack direction="row" spacing={2} mt={2} style={{ width: '100%' }}>
                       <FormControl fullWidth>
                         <InputLabel id="demo-simple-select-label">Category</InputLabel>
                         <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={values.category}
                           label="Category"
                           name="category"
                           onChange={(e) => setFieldValue('category', e.target.value)}
                         >
                           {categoryList.map((category) => (
                             <MenuItem key={category.id} value={category.id}>
                               {category.name}
                             </MenuItem>
                           ))}
                         </Select>
                         {serverError.category ? (
                           <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                             {serverError.category[0]}{' '}
                           </Typography>
                         ) : (
                           ''
                         )}
                       </FormControl>
                       <FormControl fullWidth mx={2}>
                         <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                         <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={values.subCategory}
                           label="SubCategory"
                           name="subCategory"
                           onChange={(e) => setFieldValue('subCategory', e.target.value)}
                         >
                           {subCategoryList.map((category) => (
                             <MenuItem key={category.id} value={category.id}>
                               {category.name}
                             </MenuItem>
                           ))}
                         </Select>
                         {serverError.subCategory ? (
                           <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                             {serverError.subCategory[0]}{' '}
                           </Typography>
                         ) : (
                           ''
                         )}
                       </FormControl>
                       <FormControl fullWidth>
                         <InputLabel id="demo-simple-select-label">Academy</InputLabel>
                         <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={values.academy}
                           label="Academy"
                           name="academy"
                           onChange={(e) => setFieldValue('academy', e.target.value)}
                         >
                           {academyList.map((client) => (
                             <MenuItem key={client.id} value={client.id}>
                               {client.name}
                             </MenuItem>
                           ))}
                         </Select>
                         {serverError.academy ? (
                           <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                             {serverError.academy[0]}{' '}
                           </Typography>
                         ) : (
                           ''
                         )}
                       </FormControl>
                     </Stack>
                   </Grid>

                   <Grid item xs={12}>
                     <Typography>Course Image</Typography>
                     <input
                       type="file"
                       name="image_course"
                       onChange={(event) => {
                         setFieldValue('image_course', event.currentTarget.files[0]);
                       }}
                     />
                     <ErrorMessage name="image_course" component="div" style={{ color: 'red' }} />
                   </Grid>

                   <Grid item xs={12}>
                     <Box textAlign="center">
                       <Button onClick={(e) => handleEditSubmit(values, e)} variant="contained" disabled={isSubmitting}>
                         {isSubmitting ? 'Updating...' : 'Update Course'}
                       </Button>
                     </Box>
                   </Grid>
                 </Grid>
               </Form>
             )}
           </Formik>
         </>
       );
     }
   };

  const ModuleDetails = () => {
    if (moduleDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Course Details was found. Please update...
        </Typography>
      );
    } else if (moduleDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (moduleDetails.isSuccess) {
      return (
        <>
          <List>
            {moduleList.map((modules) => (
              <ListItem key={modules.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  component={NavLink}
                  to={`/clientpage/updatemodule/${slug}/${modules.id}`}
                  sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={modules.name}
                    secondary={`${modules.description} - ${modules.duration}`}
                    sx={{ opacity: open ? 1 : 0 }}
                  ></ListItemText>
                  {/* <ListItemText primary={modules.description} sx={{ opacity: open ? 1 : 0 }} >  {modules.name} - {modules.description}       </ListItemText> */}
                  {/* <ListItemText primary={`Duration: ${modules.duration}`} sx={{ opacity: open ? 1 : 0 }} >  {modules.name} - {modules.description}       </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box textAlign="center">
            <Button variant="contained" color="primary" onClick={handleModulesOpen}>
              {' '}
              Add Modules
            </Button>
          </Box>
        </>
      );
    }
  };

  const ResourseDetails = () => {
    if (resourseDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Resource was found. Please update...
        </Typography>
      );
    } else if (resourseDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (resourseDetails.isSuccess) {
      return (
        <>
          <List>
            {resourseList.map((modules) => (
              <ListItem key={modules.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={modules.name} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <Link target="_blank" to={`${modules.file}`}>
                    <ListItemText primary="File" sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                  </Link>
                  <Link ms={10} target="_blank" to={`${modules.url}`}>
                    <ListItemText primary={modules.url} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  const VideoDetails = () => {
    if (videoDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Videos was found. Please update...
        </Typography>
      );
    } else if (videoDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (videoDetails.isSuccess) {
      return (
        <>
          <List>
            {videosList.map((videos) => (
              <ListItem key={videos.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText primary={videos.name} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                    {videos.name} - {videos.description}{' '}
                  </ListItemText>
                  <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                  </ListItemText>
                  <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };

  const QuizSection = () => {
    if (getAllQuiz.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Videos was found. Please update...
        </Typography>
      );
    } else if (getAllQuiz.isLoading) {
      return <p>Loading...</p>;
    } else if (getAllQuiz.isSuccess) {
      // var allData = getAllQuiz.data.filter((curElem) => {
      //   return curElem.module === id;
      // });
      // console.log(allData)
      return (
        <>
          <List>
            {quizList.map((quiz, index) => (
              <ListItem
                key={quiz.id}
                disablePadding
                sx={{
                  display: 'block',
                  // backgroundColor: videoI.d === quiz.id ? '#f0f0f0' : 'inherit',
                }}
              >
                <ListItemButton
                  sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  component={NavLink}
                  to={`/adminpage/editquiz/${quiz.id}`}
                  // onClick={(e) => handleCurrentVideo(e, quiz.id, quiz.video)}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    {/* {quiz.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} />
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={quiz.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    <ListItemText primary={`Quiz ${index + 1}`} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                    <Typography variant="p" sx={{ flexShrink: 0 }}>
                      {/* <OndemandVideoIcon sx={{ flexShrink: 0 }} /> */}
                      {quiz.duration}
                    </Typography>
                  </div>
                  {/* <ListItemText primary={quiz.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box textAlign="center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={handleAddQuiz}>
                {' '}
                Add Quiz
              </Button>
            )}
          </Box>
          {error.non_field_errors ? <Alert severity="error">{error.non_field_errors[0]}</Alert> : ''}
        </>
      );
    }
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid item sm={6} mb={2} sx={{ height: '400px', p: 4, color: 'black', border: 'rounded' }}>
          {/* <Avatar
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
                sx={{ width: 156, height: 156 , mb:5, mt:5}}
            /> */}
          <img
            alt="Remy Sharp"
            src={`${currentServer}/${image_course}`}
            //src={`${image_course}`}
            style={{ width: 750, height: 350, padding: 2 }}
          />
          {/* <Typography sx={{ flexShrink: 0 }}>
                        Name:  {name}
            </Typography> */}
        </Grid>
        <Grid item sm={6} mb={2} sx={{ height: '400px', py: 4, px: 2, color: 'black', border: 'rounded' }}>
          {/* <Avatar
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
                sx={{ width: 156, height: 156 , mb:5, mt:5}}
            /> */}
          <Typography variant="h3" sx={{ flexShrink: 0 }}>
            {name}
          </Typography>
          <Typography variant="h5" sx={{ flexShrink: 0 }} gutterBottom>
            {description}
          </Typography>
          <Typography variant="h6" sx={{ flexShrink: 0, color: 'grey' }} gutterBottom>
            {small_description}
          </Typography>
          <Typography variant="p" sx={{ flexShrink: 0 }} gutterBottom>
            {/* <Chip label="Ratings: "  color="secondary" />  {rating} <Rating mt={1} name="read-only" value={rating} readOnly/>  */}
            <Rating name="read-only" precision={0.5} defaultValue={rating} disabled />
          </Typography>
          {/* <Typography sx={{ flexShrink: 0 }}>
                        Status:  {account_activated? "Active" : "Not Active"}
            </Typography> */}
        </Grid>

        <Grid item sm={11} mx="auto" mb={10}>
          {/* <Box sx={{  flexShrink: 0 }}  >
                <Typography variant='h4' sx={{  flexShrink: 0 }}>
                    Videos, resourse and Notes:
                </Typography>
            </Box> */}
          <Box component="form" noValidate id="profileDetails-form" onSubmit={handleSubmit}>
            <Box sx={{ width: '100%' }}>
              {/* <AppBar position="static"> */}
              <Tabs
                value={value}
                onChange={handleTabsChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="Course Details"
              >
                <Tab label="Course Details" {...a11yProps(0)} />
                <Tab label="Modules" {...a11yProps(1)} />
                {/* <Tab label="Resources" {...a11yProps(2)}/>
                    <Tab label="Videos" {...a11yProps(3)}/> 
                    <Tab label="Quizes" {...a11yProps(4)}/>  */}
              </Tabs>
              {/* </AppBar> */}
            </Box>
            {/* <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                > */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <CourseDetails />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <ModuleDetails />
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
                        <ResourseDetails/>
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <VideoDetails/>
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                        <QuizSection/>
                    </TabPanel> */}

            {/* </SwipeableViews> */}
          </Box>
        </Grid>
      </Grid>

      {/* Add Module modal */}
      <Modal
        component="form"
        open={openModule}
        onClose={handleModulesClose}
        id="add-resource-form"
        onSubmit={handleAddModules}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Module
          </Typography>
          {/* <label htmlFor="phoneOtp" className="col-form-label">OTP on Phone:</label>
          <input type="text" className="form-control" id="phoneOtp" name="phoneOtp" /> */}
          {/* <TextField margin='normal' required fullWidth id='phoneOtp' name='phoneOtp' label='Phone OTP'  onChange={(phoneOtp) => {setPhone(phoneOtp)}} /> */}
          <TextField margin="normal" required fullWidth id="name" name="name" label="Name" />
          {moduleError.name ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{moduleError.name[0]}</Typography>
          ) : (
            ''
          )}
          <TextField margin="normal" required fullWidth id="description" name="description" label="Description" />
          {moduleError.description ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
              {moduleError.description[0]}
            </Typography>
          ) : (
            ''
          )}
          {/* <label htmlFor="emailOtp" className="col-form-label">OTP on Email:</label>
          <input type="text" className="form-control" id="emailOtp" name="emailOtp"/>  */}
          {/* <Stack direction="row" alignItems="center" spacing={4} py={2}>
            <Typography margin='normal' style={{width: "50%"}}>Upload File(optional)</Typography>
            <Input accept="*" id="file" type="file" onChange={(e) => { setResourceFile(e.target.files[0]) }} />
        </Stack>
          {moduleError.file ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{moduleError.file[0]}</Typography> : ""} */}
          <Box textAlign="center">
            <Button onClick={handleModulesClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 2 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>
              Add
            </Button>
          </Box>
          {moduleError.non_field_errors ? <Alert severity="error">{moduleError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>
    </>
  );
};

export default ClientCourseMaintain;
