import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import currentServer from "../_mock/server"


export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  // baseQuery: fetchBaseQuery({ baseUrl: "http://api.therealdineshan.com/api/courses/" }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://127.0.0.1:8000/api/courses/" }),
  baseQuery: fetchBaseQuery({ baseUrl: `${currentServer}/api/courses/` }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (accessToken) => {
        return {
          url: 'createCategory/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSubCategories: builder.query({
      query: (accessToken) => {
        return {
          url: 'createSubCategory/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getModules: builder.query({
      query: (accessToken) => {
        return {
          url: 'createModule/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getCourseModules: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `getModules/${slug}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createCourse: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'createCourse/',
          method: 'POST',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createModule: builder.mutation({
      query: ({ slug, data, accessToken }) => {
        return {
          url: `createModule/${slug}/`,
          method: 'POST',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAllCourses: builder.query({
      query: (accessToken) => {
        return {
          url: 'createCourse/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getMyCourses: builder.query({
      query: (accessToken) => {
        return {
          url: 'myCourses/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    courseDetails: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `courseDetails/${slug}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateCourse: builder.mutation({
      query: ({ slug, data, accessToken }) => {
        return {
          url: `courseDetails/${slug}`,
          method: 'PUT',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    enrollCourse: builder.mutation({
      query: ({ id, accessToken }) => {
        return {
          url: `enrollCourse/${id}`,
          method: 'POST',
          // body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    enrollRequests: builder.query({
      query: (accessToken) => {
        return {
          url: 'enrollCourse/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    approveRequest: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'approveRequest/',
          method: 'POST',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createResourse: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: `createResourse/`,
          method: 'POST',
          body: actualData,
          headers: {
            // "Content-type": "application/json",.
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getCourseResourses: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `getResourses/${slug}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getResourcePerModule: builder.query({
      query: ({ id, slug, accessToken }) => {
        return {
          url: `getResourcePerModule/${slug}/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    uploadVideos: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: `uploadVideos/`,
          method: 'POST',
          body: actualData,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getCourseVideos: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `getVideos/${slug}/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getVideosPerModule: builder.query({
      query: ({ id, slug, accessToken }) => {
        return {
          url: `getVideosPerModule/${slug}/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createProject: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'createProject',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getProjectList: builder.query({
      query: () => {
        return {
          url: 'listProject',
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    assignCourse: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'assignCourse',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getMyProgress: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `myProgress/${slug}/`,
          method: 'GET',
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    submitProgress: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'myProgress/',
          method: 'POST',
          body: actualData,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAllQuiz: builder.query({
      query: ({ slug, accessToken }) => {
        return {
          url: `getAllQuiz/${slug}/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getQuizDetails: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `quizDetails/${id}/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getQuizPerModule: builder.query({
      query: ({ id, slug, accessToken }) => {
        return {
          url: `getQuizPerModule/${slug}/${id}/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createQuiz: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createQuiz/',
          method: 'POST',
          body: actualData,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createQuestion: builder.mutation({
      query: ({ id, actualData, accessToken }) => {
        return {
          url: `createQuestion/${id}/`,
          method: 'POST',
          body: actualData,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    submitQuiz: builder.mutation({
      query: ({ id, selectedAnswers, accessToken }) => {
        return {
          url: `submitQuiz/${id}/`,
          method: 'POST',
          body: selectedAnswers,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateProject: builder.mutation({
      query: ({ data, id, accessToken }) => {
        return {
          url: `projectRetrive/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteProject: builder.mutation({
        query: ({ id, accessToken }) => {
            return {
              url: `projectRetrive/${id}`,
              method: 'DELETE', 
              headers: {
                // "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`,
              },
            };
        }
    })

  }),
});

export const {
    useGetCategoriesQuery,
    useGetSubCategoriesQuery,
    useGetModulesQuery,
    useGetCourseModulesQuery,
    useEnrollCourseMutation,
    useEnrollRequestsQuery,
    useApproveRequestMutation,
    useCreateCourseMutation,
    useCreateModuleMutation,
    useGetAllCoursesQuery,
    useGetMyCoursesQuery,
    useCourseDetailsQuery,
    useUpdateCourseMutation,
    useCreateResourseMutation,
    useGetCourseResoursesQuery,
    useGetResourcePerModuleQuery,
    useUploadVideosMutation,
    useGetCourseVideosQuery,
    useGetVideosPerModuleQuery,
    useCreateProjectMutation,
    useGetProjectListQuery,
    useAssignCourseMutation,
    useGetMyProgressQuery,
    useSubmitProgressMutation,
    useGetAllQuizQuery,
    useGetQuizPerModuleQuery,
    useGetQuizDetailsQuery,
    useCreateQuestionMutation,
    useCreateQuizMutation,
    useSubmitQuizMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = coursesApi;