 /* eslint-disable */
 import React, { useState, useEffect } from "react"
 import { Link, NavLink, useNavigate } from "react-router-dom"
 import { Helmet } from 'react-helmet-async';
 import { filter } from 'lodash';
 import { sentenceCase } from 'change-case';
 import {
     Card,
     Table,
     Stack,
     Paper,
     Avatar,
     Button,
     Popover,
     Checkbox,
     TableRow,
     MenuItem,
     TableBody,
     TableCell,
     Container,
     Typography,
     IconButton,
     TableContainer,
     TablePagination,
     Skeleton,
     Grid,
     List,
     ListItem,
     ListItemText,
     ListItemAvatar,
     Modal,
     Box,
     TextField,
     CircularProgress,
     FormControl,
     InputLabel,
     Select,
     Input,
     OutlinedInput,
   } from '@mui/material';
 import { useDispatch, useSelector } from "react-redux"
 import { setUserInfo } from "../features/userSlice"
 import Label from '../components/label';
 import Iconify from '../components/iconify';
 import Scrollbar from '../components/scrollbar';
 import { getToken } from "../services/LocalStorageService"
 // sections
 import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
 // mock
 import USERLIST from '../_mock/user';

import { useCreateProjectMutation, useDeleteProjectMutation, useGetProjectListQuery, useUpdateProjectMutation } from 'src/services/coursesApi';
import currentNetwork from '../_mock/network';
import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';
 
 
 const TABLE_HEAD = [
     { id: 'name', label: 'Name', alignRight: false },
     { id: 'description', label: 'Description', alignRight: false },
     { id: 'duration', label: 'Duration', alignRight: false },
     { id: 'file', label: 'File', alignRight: false },
     { id: 'pain', label: 'Paid', alignRight: false },
     { id: '' },
   ];
   
   function descendingComparator(a, b, orderBy) {
     if (b[orderBy] < a[orderBy]) {
       return -1;
     }
     if (b[orderBy] > a[orderBy]) {
       return 1;
     }
     return 0;
   }
   
   function getComparator(order, orderBy) {
     return order === 'desc'
       ? (a, b) => descendingComparator(a, b, orderBy)
       : (a, b) => -descendingComparator(a, b, orderBy);
   }
   
   function applySortFilter(array, comparator, query) {
     const stabilizedThis = array.map((el, index) => [el, index]);
     stabilizedThis.sort((a, b) => {
       const order = comparator(a[0], b[0]);
       if (order !== 0) return order;
       return a[1] - b[1];
     });
     if (query) {
       return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
     }
     return stabilizedThis.map((el) => el[0]);
   }
   const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width:  "600px",
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
 
const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();
  // console.log( activeUsers, verifiedUsers)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();
  const allProjects = useGetProjectListQuery();
  useEffect(() => {
    if (allProjects.data && allProjects.isSuccess) {
      setProjects(allProjects.data);
    }
  }, [allProjects.data, allProjects.isSuccess]);

  const [open, setOpen] = useState(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paid, setPaid] = useState(false);
  const [projectFile, setProjectFile] = useState('');
  const [selectedProject, setSelectedProject] = useState([])

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedProject(row)
    console.log(selectedProject)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen();
  };
  const submitProject = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', name);
    data.append('description', description);
    data.append('file', projectFile);
    data.append('duration', duration);
    data.append('is_paid', paid);

    // vat_no: data.get('vat_no'),
    console.log(data);
    const res = await createProject({ data, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();
      window.location.reload();
      // navigate('/clientpage/projects')
    }
  };

  const handleProjectPaid = (event) => {
    setPaid(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (allProjects.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (allProjects.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const [editModal, setEditModal] = useState(false);
  const handleEditClose = () => setEditModal(!editModal);
  const handleDeleteModalClose = () => setDeleteModal(!deleteModal);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedProject?.id;
    console.log('data from update host:', actualData);

    const res = await deleteProject({ actualData, id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      window.location.reload();
      handleDeleteModalClose();
    }
  };

  // Get all users and filter subhost

  const editProjectSubmit = async (values) => {
   
     const data = new FormData();
    data.append('name', values?.project_name);
    data.append('description', values?.description);
    data.append('file', values?.file);
    data.append('duration', values?.duration);
    data.append('is_paid', values?.paid);

    const id = selectedProject?.id;

    // vat_no: data.get('vat_no'),
    console.log(data);
    const res = await updateProject({ data, id,  accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleEditClose();
       // window.location.reload();
      // navigate('/clientpage/projects')

      window.location.reload();
  
    };
    

     
  };

  if (allProjects.isError) {
    return (
      <h1 className="text-center" mt={10} mx={10}>
        <br />
        <br />
        <br />
        No User Data Found...
      </h1>
    );
  } else {
    return (
      <>
        <Helmet>
          <title> Projects | Y-Plan </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Projects
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>
              New Project
            </Button>
          </Stack>
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={projects.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                    {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const { id, name, description, duration, file, is_paid } = row;
                      // console.log("🚀 ~ file: Projects.js:281 ~ {projects.slice ~ paid:", paid)
                      const isActive = String(is_paid);
                      const accountActivated = String(is_paid);
                      const selectedUser = selected.indexOf(name) !== -1;
                      let isPaid;
                      if (is_paid) {
                        isPaid = 'Yes';
                      } else {
                        isPaid = 'No';
                      }

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          {/* <NavLink to={`http://${currentNetwork}/clientpage/proj/${id}`}> */}
                          {/* <TableCell component="th" scope="row" padding="none"> */}
                          <TableCell component="th" scope="row">
                            <NavLink to="">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={image} /> */}
                                <Typography variant="subtitle2" sx={{ color: 'black' }} noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </NavLink>
                          </TableCell>

                          <TableCell align="left">{description}</TableCell>

                          <TableCell align="left">{duration} days</TableCell>

                          <TableCell align="left">
                            <Link target="_blank" to={`${file}`}>
                              View
                            </Link>
                          </TableCell>

                          <TableCell align="left">
                            {/* <Label color={(is_paid === false && 'error') || 'success'}>{sentenceCase(is_paid)}</Label> */}
                            {isPaid}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => setEditModal(!editModal)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>

        {/* Create Project Modal  */}
        <Modal
          component="form"
          open={openOTP}
          onClose={handleClose}
          id="Project-form"
          onSubmit={submitProject}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{backgrou}}
        >
          <Box noValidate sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create a Project
            </Typography>

            <Stack direction="row">
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                onChange={(e) => setName(e.target.value)}
              />
              {serverError.name ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
              ) : (
                ''
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                name="description"
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              {serverError.ngo ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.ngo[0]}</Typography>
              ) : (
                ''
              )}
            </Stack>

            <Stack direction="row">
              <TextField
                margin="normal"
                required
                fullWidth
                id="duration"
                name="duration"
                label="Duration(No of Days)"
                onChange={(e) => setDuration(e.target.value)}
              />
              {serverError.email ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.phoneOtp[0]}
                </Typography>
              ) : (
                ''
              )}
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native">Project Provider</InputLabel>
                <Select
                  native
                  value={paid}
                  onChange={handleProjectPaid}
                  input={<OutlinedInput label="Project Provider" id="demo-dialog-native" />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  {/* <option value="unknown">Unknown</option> */}
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={4} py={2}>
              <Typography style={{ width: '50%' }}>Upload Project Doc</Typography>
              <Input
                accept="*"
                id="file"
                type="file"
                onChange={(e) => {
                  setProjectFile(e.target.files[0]);
                }}
              />
            </Stack>

            <Box textAlign="center">
              <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                  Create Project
                </Button>
              )}
            </Box>
            {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
          </Box>
        </Modal>

        {/* Edit host Modal  */}
        <Modal
          open={editModal}
          onClose={handleEditClose}
          id="edit-form"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Formik
            initialValues={{
              project_name: selectedProject?.name || '',
              description: selectedProject?.description || '',
              duration: selectedProject?.duration || '',
              paid: selectedProject?.is_paid || '',
            }}
            onSubmit={(values) => {
              editProjectSubmit(values);
            }}
          >
            {({ isSubmitting, setFieldValue, handleChange }) => (
              <Form>
                {console.log('seelctedProject', selectedProject)}
                <Box noValidate sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Edit a Project
                  </Typography>

                  <Stack direction="row">
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      id="project_name"
                      name="project_name"
                      label="Name"
                      onChange={handleChange}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      id="description"
                      name="description"
                      label="Description"
                      onChange={handleChange}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      id="duration"
                      name="duration"
                      label="Duration(No of Days)"
                      onChange={handleChange}
                    />

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel htmlFor="project-provider">Project Provider</InputLabel>
                      <Field
                        as={Select}
                        native
                        name="paid"
                        onChange={(e) => setFieldValue('paid', e.target.value)}
                        input={<OutlinedInput label="Project Provider" id="project-provider" />}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </FormControl>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={4} py={2}>
                    <Typography style={{ width: '50%' }}>Upload Project Doc</Typography>
                    <Input
                      accept="*"
                      id="file"
                      type="file"
                      required
                      onChange={(e) => setFieldValue('file', e.currentTarget.files[0])}
                    />
                  </Stack>

                  <Box textAlign="center">
                    <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                      Cancel
                    </Button>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                        {isSubmitting ? 'Submitting...' : 'Edit Project'}
                      </Button>
                    )}
                  </Box>
                  {serverError && serverError.non_field_errors ? (
                    <Alert severity="error">{serverError.non_field_errors[0]}</Alert>
                  ) : null}
                </Box>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* Delete Modal Popup */}
        <Modal
          open={deleteModal}
          onClose={handleDeleteModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ border: 'none' }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Client
            </Typography>

            <form onSubmit={handleDeleteModalSubmit} noValidate>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    background: '#bf3f57',
                    padding: '1rem',
                    marginBottom: '.5rem',
                  }}
                >
                  <AiOutlineDelete size={23} color="#fff" />
                </div>
                <h5>
                  {' '}
                  Do you want to Delete{' '}
                  <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedProject?.name}</span>{' '}
                  Sub-Host ?{' '}
                </h5>
              </div>

              <Box textAlign="center">
                <Button
                  onClick={handleDeleteModalClose}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2, px: 5 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                  Delete
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </>
    );
  }
}

export default Projects