// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/academy/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Hosts',
    path: '/academy/hosts',
    icon: icon('ic_user'),
  },
  {
    title: 'courses',
    path: '/academy/courses',
    icon: icon('ic_cart'),
  },
  {
    title: 'applicants',
    path: '/academy/applicants',
    icon: icon('ic_user'),
  },
  {
    title: 'My Learners',
    path: '/academy/learners',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
