import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
// components
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../components/logo';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Logo />
      </Typography>
      <List>
        <ListItem button component={NavLink} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={NavLink} to="/about">
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={NavLink} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component={NavLink} to="/login">
          <ListItemText primary="Login/Register" />
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <DrawerAppBar position="static" color='inherit'>
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>
              <Logo />
              
            </Typography>
          </Toolbar>
        </Container>
      </DrawerAppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>

      <AppBar position="static" color="default" sx={{ display: { xs: 'none', sm: 'flex' }, ms: "auto", textAlign: 'center'  }}>
        <Container maxWidth="lg">
          <Toolbar>
            <Logo sx={{  display: { xs: 'none', sm: 'grid' } }} /> 
            <Button component={NavLink} to="/" size="large" color="inherit">
              Home
            </Button>
            <Button component={NavLink} to="/about" size="large" color="inherit">
              About
            </Button>
            <Button component={NavLink} to="/contact" size="large" color="inherit">
              Contact
            </Button>
            <Button component={NavLink} to="/login" size="large" color="inherit">
              Login/Register
            </Button>
          </Toolbar>
        </Container>
      </AppBar>

      <Outlet />
    </>
  );
}
