import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import currentServer from "../_mock/server";


export const attendanceApi = createApi({
    reducedPath: "attendanceApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${currentServer}/api/attendance`}),
    endpoints: (builder) => ({
        saveLocation: builder.mutation({
            query: ({data, accessToken}) => {
                return {
                    url: "geocoding/", 
                    method: "POST",
                    body: data,
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
        checkAvailability: builder.mutation({
            query: ({data, accessToken}) => {
                return {
                    url: "checkAvailability/",
                    method: "POST",
                    body: data,
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
        getAttendance: builder.query({
            query: (  accessToken ) => {
                return {
                    url: "markAttendance/",
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
        markAttendance: builder.mutation({
            query: ( accessToken) => {
                return {
                    url: "markAttendance/",
                    method: "POST",
                    // body: data,
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
        trackAttendance: builder.query({
            query: (  accessToken ) => {
                return {
                    url: "trackAttendance/",
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
        hostLearnerAttendance: builder.query({
            query: (  {id, accessToken} ) => {
                return {
                    url: `hostLearnerAttendance/${id}`,
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                }
            }
        }),
    }),
})
export const {
    useSaveLocationMutation,
    useCheckAvailabilityMutation,
    useGetAttendanceQuery,
    useMarkAttendanceMutation,
    useTrackAttendanceQuery,
    useHostLearnerAttendanceQuery,
} = attendanceApi;