import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import currentServer from '../_mock/server';

// Define a service using a base URL and expected endpoints
export const userAuthApi = createApi({
  reducerPath: 'userAuthApi',
  // baseQuery: fetchBaseQuery({ baseUrl: "http://api.therealdineshan.com/api/user/" }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://127.0.0.1:8000/api/user/" }),
  baseQuery: fetchBaseQuery({ baseUrl: `${currentServer}/api/user/` }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (user) => {
        return {
          url: 'register/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    // http://127.0.0.1:8000/api/user/verifyOTP/
    verifyOTP: builder.mutation({
      query: (otp) => {
        return {
          url: 'verifyOTP/',
          method: 'POST',
          body: otp,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: 'login/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    hostLogin: builder.mutation({
      query: (user) => {
        return {
          url: 'hostLogin/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    clientLogin: builder.mutation({
      query: (user) => {
        return {
          url: 'clientLogin/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    academicLogin: builder.mutation({
      query: (user) => {
        return {
          url: 'academicLogin/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    adminLogin: builder.mutation({
      query: (user) => {
        return {
          url: 'adminLogin/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    contactAdmin: builder.mutation({
      query: (contactData) => {
        return {
          url: 'contact/',
          method: 'POST',
          body: contactData,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    profileData: builder.mutation({
      query: ({ data, accessToken }) => {
        // query: ({ actualData }) => {
        return {
          url: 'profileData/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchProfileData: builder.query({
      query: (accessToken) => {
        return {
          url: 'profileData/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getUserProfileData: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `profileUpdate/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserProfileData: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `profileUpdate/${id}`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    professionalDetails: builder.mutation({
      query: ({ data, accessToken }) => {
        // query: ({ actualData }) => {
        return {
          url: 'professionalDetails/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchProfessionalDetails: builder.query({
      query: (accessToken) => {
        // query: ({ actualData }) => {
        return {
          url: 'professionalDetails/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getUserProfessionalData: builder.query({
      query: ({ id, accessToken }) => {
        // query: ({ actualData }) => {
        return {
          url: `professionalUpdate/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserProfessionalData: builder.query({
      query: ({ id, accessToken }) => {
        // query: ({ actualData }) => {
        return {
          url: `professionalUpdate/${id}`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    uploadDetails: builder.mutation({
      query: ({ data, accessToken }) => {
        // query: ({ actualData }) => {
        return {
          url: 'uploadedData/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchUploadDetails: builder.query({
      query: (accessToken) => {
        return {
          url: 'uploadedData/',
          method: 'GET',
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getUserUploadData: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `uploadedDataUpdate/${id}`,
          method: 'GET',
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserUploadData: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `uploadedDataUpdate/${id}`,
          method: 'GET',
          headers: {
            // "Content-type": "application/json",
            // 'Content-type': 'multipart/form-data',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    uploadImage: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: `uploadImage//`,
          method: 'POST',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getLoggedUser: builder.query({
      query: (accessToken) => {
        return {
          url: 'profile/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateMyDetails: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'profile/',
          method: 'PUT',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserDetails: builder.mutation({
      query: ({ id, data, accessToken }) => {
        return {
          url: `profile/${id}/`,
          method: 'PUT',
          body: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getHostList: builder.query({
      query: (accessToken) => {
        return {
          url: 'hostView/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    hostsLearners: builder.query({
      query: (accessToken) => {
        return {
          url: `getHostsLearners`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getHostsLearners: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `getHostsLearners/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getClientsHost: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `getClientsHost/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getClientsLearners: builder.query({
      query: (accessToken) => {
        return {
          url: `getClientsLearners`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    clientsLearners: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `getClientsLearners/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getHostProfile: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `retrieveHost/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAdminProfile: builder.query({
      // pending
      query: (accessToken) => {
        return {
          url: 'adminProfile/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    changeUserPassword: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'changepassword/',
          method: 'POST',
          body: actualData,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    sendPasswordResetEmail: builder.mutation({
      query: (user) => {
        return {
          url: 'send-reset-password-email/',
          method: 'POST',
          body: user,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ actualData, id, token }) => {
        return {
          url: `/reset-password/${id}/${token}/`,
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),

    // These two should be shifted to ................
    getUserDetails: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `userDetail/${id}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAllUsers: builder.query({
      query: (accessToken) => {
        return {
          url: 'userList/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    // getAllProfile: builder.query({
    //     query:  (accessToken) => {
    //         return {
    //             url: "apiadmin/account/profiledetails/",
    //             method: "GET",
    //             headers: {
    //                 authorization: `Bearer ${accessToken}`,
    //             },
    //         }
    //     },
    // }),
    notifyUser: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'notifyUser/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createLearner: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createLearner/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getLearnerList: builder.query({
      query: (accessToken) => {
        return {
          url: 'listLearners/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateLearner: builder.mutation({
      query: ({ actualData, accessToken, id }) => {
        return {
          url: `retrieveLearner/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createHost: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createHost/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateHost: builder.mutation({
      query: ({ actualData, accessToken, id }) => {
        return {
          url: `retrieveHost/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteHost: builder.mutation({
      query: ({ accessToken, id }) => {
        return {
          url: `retrieveHost/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),

    deleteLearner: builder.mutation({
      query: ({ accessToken, id }) => {
        return {
          url: `retrieveLearner/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createSubHost: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createSubHost/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSubhostProfile: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `retrieveSubhost/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    subhostsLearners: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `getSubhostsLearners/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSubhostsLearners: builder.query({
      query: (accessToken) => {
        return {
          url: `getSubhostsLearners`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createClient: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createClient/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getClientList: builder.query({
      query: (accessToken) => {
        return {
          url: 'clientView/',
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getClientProfile: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `retrieveClient/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAcademy: builder.query({
      query: (accessToken) => {
        return {
          url: 'academyView/',
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    academyDetails: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `academyDetails/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getAcademysLearners: builder.query({
      query: (accessToken) => {
        return {
          url: `getAcademysLearners`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    academysLearners: builder.query({
      query: ({ id, accessToken }) => {
        return {
          url: `getAcademysLearners/${id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createAcademy: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: 'createAcademy/',
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    listGroup: builder.query({
      query: (accessToken) => {
        return {
          url: 'listGroup/',
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    nearestLearner: builder.query({
      query: ({ actualData, accessToken }) => {
        console.log('actal data from user aut api: ', actualData);
        return {
          url: `nearestLearners/`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    importUsers: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'bulkUserUpload/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    assignProject: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'assignProject/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    assignHost: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'assignHost/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    assignClientToLearner: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'assignClientToLearner/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    assignClientToHost: builder.mutation({
      query: ({ data, accessToken }) => {
        return {
          url: 'assignClientToHost/',
          method: 'POST',
          body: data,
          headers: {
            // "Content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateSubHost: builder.mutation({
      query: ({ actualData, id, accessToken }) => {
        return {
          url: `retrieveSubHosts/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteSubHost: builder.mutation({
      query: ({ accessToken, id }) => {
        return {
          url: `retrieveSubHosts/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),

    updateClient: builder.mutation({
      query: ({ actualData, id, accessToken }) => {
        return {
          url: `retrieveClient/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteClient: builder.mutation({
      query: ({ accessToken, id }) => {
        return {
          url: `retrieveClient/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),

    updateAcademies: builder.mutation({
      query: ({ actualData, id, accessToken }) => {
        return {
          url: `retrieveAcademys/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteAcademies: builder.mutation({
      query: ({ accessToken, id }) => {
        return {
          url: `retrieveAcademys/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSubHost: builder.query({
      query: (accessToken) => {
        return {
          url: 'createSubHost/',
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateCreateSubHost: builder.mutation({
      query: ({ actualData, id, accessToken }) => {
        return {
          url: `retrieveSubHosts/${id}`,
          method: 'PUT',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateProject: builder.mutation({
      query: ({ data, id, accessToken }) => {
        return {
          url: `retrieveSubHosts/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createGroup: builder.mutation({
      query: ({ actualData, accessToken }) => {
        return {
          url: `createGroup/`,
          method: 'POST',
          body: actualData,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateGroup: builder.mutation({
      query: ({ data, id, accessToken }) => {
        return {
          url: `retrieveLearnerGroup/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteGroup: builder.mutation({
      query: ({ id, accessToken }) => {
        return {
          url: `retrieveLearnerGroup/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUser: builder.mutation({
      query: ({ data, id, accessToken }) => {
        return {
          url: `userDetail/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteUser: builder.mutation({
      query: ({ id, accessToken }) => {
        return {
          url: `userDetail/${id}`,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    markAttendance: builder.mutation({
      query: ({ studentId, data, accessToken }) => {
        return {
          url: `markAttendance/${studentId}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateCourseDetails: builder.mutation({
      query: ({ courseId, data, accessToken }) => {
        return {
          url: `courseDetails${courseId}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getLearnersAttendenceList: builder.query({
      query: ({id, accessToken}) => {
        return {
          url: `learner/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useProfileDataMutation,
  useFetchProfileDataQuery,
  useGetUserProfileDataQuery,
  useUpdateUserProfileDataQuery,
  useProfessionalDetailsMutation,
  useFetchProfessionalDetailsQuery,
  useGetUserProfessionalDataQuery,
  useUpdateUserProfessionalDataQuery,
  useUploadDetailsMutation,
  useFetchUploadDetailsQuery,
  useGetUserUploadDataQuery,
  useGetLoggedUserQuery,
  useUpdateMyDetailsMutation,
  useUpdateUserDetailsMutation,
  useChangeUserPasswordMutation,
  useSendPasswordResetEmailMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useHostLoginMutation,
  useClientLoginMutation,
  useAcademicLoginMutation,
  useAdminLoginMutation,
  useNotifyUserMutation,
  useCreateLearnerMutation,
  useGetLearnerListQuery,
  useCreateHostMutation,
  useUpdateHostMutation,
  useDeleteHostMutation,
  useCreateSubHostMutation,
  useGetClientListQuery,
  useCreateClientMutation,
  useGetClientProfileQuery,
  useContactAdminMutation,
  useGetHostListQuery,
  useGetClientsHostQuery,
  useGetHostProfileQuery,
  useGetSubhostProfileQuery,
  useGetAcademyQuery,
  useAcademyDetailsQuery,
  useAcademysLearnersQuery,
  useGetAcademysLearnersQuery,
  useCreateAcademyMutation,
  useUploadImageMutation,
  useGetAllUsersQuery,
  useGetUserDetailsQuery,
  useListGroupQuery,
  useNearestLearnerQuery,
  useImportUsersMutation,
  useAssignProjectMutation,
  useAssignHostMutation,
  useAssignClientToHostMutation,
  useAssignClientToLearnerMutation,
  useGetClientsLearnersQuery,
  useClientsLearnersQuery,
  useHostsLearnersQuery,
  useGetHostsLearnersQuery,
  useSubhostsLearnersQuery,
  useGetSubhostsLearnersQuery,
  useUpdateLearnerMutation,
  useDeleteLearnerMutation,
  useUpdateSubHostMutation,
  useDeleteSubHostMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useUpdateAcademiesMutation,
  useDeleteAcademiesMutation,
  useGetSubHostQuery,
  useUpdateCreateSubHostMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useCreateGroupMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useMarkAttendanceMutation,
  useGetLearnersAttendenceListQuery,
  useUpdateCourseDetailsMutation,
} = userAuthApi;
