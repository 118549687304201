import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, TextField, FormControlLabel, Checkbox, Alert, InputLabel, MenuItem, Select, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Avatar, CircularProgress, Input } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { useGetLoggedUserQuery } from '../services/userAuthApi'
import { getToken } from '../services/LocalStorageService'
import ProfileData from '../pages/ProfileData';
import ProfessionalForm from '../pages/ProfessionalForm'
import UploadForm from '../pages/UploadForm'





const steps = ['Personal Details', 'Professional details', 'Upload Documents'];

export default function EditForm({open, close}) {
 
  const {accessToken} = getToken()
  const [userData, setUserData] = useState({
    id: "",
    email: "",
    name: "", 
    phone: "",
})

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: ""
  })

  // TODO remove, this demo shouldn't need to reset the theme.
  const defaultTheme = createTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { data, isSuccess } = useGetLoggedUserQuery(accessToken)
  useEffect(() => {
    if (data && isSuccess) {
        setUserData({
            id: data.id,
            email: data.email,
            name: data.name,
            phone: data.phone,
        })
    }
  }, [data, isSuccess])
  console.log(userData.id)
  // setUserId(data.id)
function getStepContent(step) {
  switch (step) {
    case 0:
      return (<ProfileData id={userData.id} />);
    case 1:
      return (<ProfessionalForm id={userData.id}/>);
    case 2:
      return (<UploadForm id={userData.id}/>);
    default:
      throw new Error('Unknown step');
  }
}

  // RTK Query
  const navigate = useNavigate()
  const handleNext = () => {
    console.log("next")
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    console.log("back")
    setActiveStep(activeStep - 1);
  };   
  
  const handleSubmit = () => {
    // console.log("done")
    navigate("/learnerpage/myProfile")
  }; 

  return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            
            <Container component="main" maxWidth="md" sx={{ mb: 1 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                Add Your Details
                </Typography>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                    <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
                </Stepper>
                {activeStep === steps.length ? (
                <>
                    <Typography variant="h5" gutterBottom>
                    Thank you for your patience. 
                    </Typography>
                    <Typography variant="subtitle1">
                    Your request is submitted. The Admin has been  notified of the request... You will be notified when he accepts the request...
                    </Typography> 
                </>
                ) : (
                <>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                        </Button>
                    )}   
                        {activeStep === steps.length - 1 ? 
                    <Button
                        id="submitForm"
                        // variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Cancel
                    </Button> : <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, ml: 1, px: 5 }}
                    >
                        Continue
                    </Button>}
                        {/* {isLoading ? document.getElementById("submitForm").innerHTML("<CircularProgress/>"): ""} */}
                    {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
                    
                    </Box>
                </>
                )}
            </Paper>
            </Container>
        </ThemeProvider>
  );
}