/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns'

import { getToken } from '../services/LocalStorageService'
import { Alert, Box, Button, CircularProgress, Container,FormControl,Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, Input } from '@mui/material';
import { styled } from '@mui/material/styles'
import Iconify from '../components/iconify';
import axios from 'axios'
import {  useNavigate } from 'react-router-dom'
import { useCreateCourseMutation, useGetCategoriesQuery, useGetSubCategoriesQuery } from '../services/coursesApi'
import { useGetHostListQuery, useGetAcademyQuery } from '../services/userAuthApi'

const AddCourse = () => {
  const {accessToken} = getToken()
  const navigate = useNavigate()
  const [serverError, setServerError] = useState({});
  const [createCourse, {isLoading}] = useCreateCourseMutation()
  const [name, setName] = useState('')
  const [academy, setAcademy] = useState('')
  const [academyList, setAcademyList] = useState([])
  const [host, setHost] = useState('')
  const [hostList, setHostsList] = useState([])
  const [enroller_user, setEnrollerUser] = useState('')
  const [category, setCategory] = useState('')
  const [categoryList, setCategoryList] = useState([]) 
  const [subCategory, setSubCategory] = useState('')
  const [subCategoryList, setSubCategoryList] = useState([])
  const [description, setDescription] = useState('')
 const [image_course, setImageCourse] = useState('');
  const [small_description, setSmallDescription] = useState()
 

  const getCategory = useGetCategoriesQuery(accessToken)
  const getSubCategory = useGetSubCategoriesQuery(accessToken)
  const getAcademy = useGetAcademyQuery(accessToken)
  
  const hosts = useGetHostListQuery(accessToken)
  // setHostsList(hosts.data)
  // console.log("hostlist", hosts.data)

  // settings hostList
  useEffect(() => {
    if(hosts.data && hosts.isSuccess){
      setHostsList(hosts.data)
    }
  }, [hosts.data, hosts.isSuccess])

  // settings CategoryList
  useEffect(() => {
    if(getCategory.data && getCategory.isSuccess){
      setCategoryList(getCategory.data)
    }
  }, [getCategory.data, getCategory.isSuccess])

   // settings SubCategoryList
  useEffect(() => {
    if(getSubCategory.data && getSubCategory.isSuccess){
      setSubCategoryList(getSubCategory.data)
    }
  }, [getSubCategory.data, getSubCategory.isSuccess]) 

   // settings academyList
  useEffect(() => {
    if(getAcademy.data && getAcademy.isSuccess){
      setAcademyList(getAcademy.data)
    }
  }, [getAcademy.data, getAcademy.isSuccess])
 
 
 

  const resetForm = () => {
    setName('')
    setAcademy('')
    setHost('')
    setEnrollerUser('')
    setCategory('')
    // setPjl([])
    setDescription('')
    setImageCourse('')
    setSmallDescription('')
  
    
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('name', name)
    data.append('academy', academy)
    data.append('host', host);
    data.append('category', category);
    data.append('subCategory', subCategory);
    data.append('description', description)
    data.append('small_description', small_description)
    data.append('image_course', image_course)
    data.append('total_video', 0)
    data.append('vidoes_time', 0)
    data.append('total_module', 0)
    // console.log("data", data)
    // console.log("image_course", image_course)
    // console.log(name, image_course, academy,  )

    const res  = await createCourse({data, accessToken})
    if (res.error) {
      setServerError(res.error.data.errors);
      console.log(res.error);
    }
    if (res.data) {
      // console.log(typeof res.data);
      console.log(res.data);
      // navigate('/learner/dashboard')
    }
  } 
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };  
  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };
  const handleClientChange = (event) => {
    setAcademy(event.target.value);
  };
   const handleHostChange = (event) => {
    setHost(event.target.value);
  };
  const handleImageChange = (e) => { 
    setImageCourse(e.target.files[0]);
    console.log("image: ", e.target.files[0])
  };
   
  return (
    <>
      <Helmet>
        <title> All Courses | Y-Plan </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Course
          </Typography>
        </Stack>
        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>asdf
        </Stack>
      </Stack> */}
        <Box
          component="form"
          sx={{ p: 3 }}
          noValidate
          id="course-form"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              SelectProps={{ native: true }}
              onChange={(e) => setName(e.target.value)}
            />
            {serverError.name ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]} </Typography>
            ) : (
              ''
            )}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Academy</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={academy}
                label="Academy"
                onChange={handleClientChange}
              >
                {academyList.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.website}
                  </MenuItem>
                ))}
              </Select>
              {serverError.academy ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.academy[0]}{' '}
                </Typography>
              ) : (
                ''
              )}
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleCategoryChange}
              >
                {categoryList.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {serverError.category ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.category[0]}{' '}
                </Typography>
              ) : (
                ''
              )}
            </FormControl>
            <FormControl fullWidth mx={2}>
              <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subCategory}
                label="Category"
                onChange={handleSubCategoryChange}
              >
                {subCategoryList.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {serverError.subCategory ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                  {serverError.subCategory[0]}{' '}
                </Typography>
              ) : (
                ''
              )}
            </FormControl>
          </Stack>

          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              margin="normal"
              id="description"
              name="description"
              label="Description"
              SelectProps={{ native: true }}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            {serverError.description ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                {serverError.description[0]}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Stack>
          <Stack direction="column">
            <TextField
              fullWidth
              margin="normal"
              id="small_description"
              name="small_description"
              label="Small Description"
              SelectProps={{ native: true }}
              onChange={(e) => setSmallDescription(e.target.value)}
              required
            />
            {serverError.category ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                {serverError.category[0]}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row" spacing={2}>
            {/* <FormControl margin='normal' fullWidth><InputLabel id="demo-simple-select-label">academy</InputLabel>
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={academy} label="academy" onChange={handleClientChange}>
        {academyList.map((client) => (<MenuItem key={client.id} value={client.website}>{client.website}</MenuItem>))}
      </Select>
    </FormControl> */}
            <FormControl margin="normal" style={{ width: ' 50%' }}>
              <InputLabel id="demo-simple-select-label">Hosts</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={host}
                label="Hosts"
                onChange={handleHostChange}
              >
                {hostList.map((host) => (
                  <MenuItem key={host.id} value={host.id}>
                    {host.department}
                  </MenuItem>
                ))}
              </Select>
              {serverError.host ? (
                <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.host[0]} </Typography>
              ) : (
                ''
              )}
            </FormControl>
            <Typography pt={2}>Upload Course Thumbnail:</Typography>
            <Input accept="*" id="image_course" type="file" onChange={handleImageChange} />
            {serverError.image_course ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                {serverError.image_course[0]}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Stack>
          {/* <TextField fullWidth margin='normal' id="image_course"  name="image_course" label="image_course" helperText="Please select your image_course" SelectProps={{ native: true, }} onChange={(e) => setName(e.target.value)} /> */}
          <Box textAlign="right">
            {/* {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="success">Submit</Button>} */}
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, px: 5 }} color="success">
                Next
              </Button>
            )}
            {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ' '}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddCourse