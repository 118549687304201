 /* eslint-disable */
 import React, { useState, useEffect } from "react"
 import { Link, NavLink, useNavigate } from "react-router-dom"
 import { Helmet } from 'react-helmet-async';
 import { filter } from 'lodash';
 import { sentenceCase } from 'change-case';
 import {
     Card,
     Table,
     Stack,
     Paper,
     Avatar,
     Button,
     Popover,
     Checkbox,
     TableRow,
     MenuItem,
     TableBody,
     TableCell,
     Container,
     Typography,
     IconButton,
     TableContainer,
     TablePagination,
     Skeleton,
     Grid,
     List,
     ListItem,
     ListItemText,
     ListItemAvatar,
     Modal,
     Box,
     TextField,
   } from '@mui/material';
 import { useDispatch } from "react-redux"
 import { setUserInfo } from "../features/userSlice"
 import Label from '../components/label';
 import Iconify from '../components/iconify';
 import Scrollbar from '../components/scrollbar';
 import { getToken } from "../services/LocalStorageService"
 // sections
 import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
 // mock
 import USERLIST from '../_mock/user';
import currentNetwork from '../_mock/network';

 
 import {
   useCreateHostMutation,
     useGetAllUsersQuery,
     useGetLoggedUserQuery,
 } from "../services/userAuthApi"
 
 
 const TABLE_HEAD = [
     { id: 'name', label: 'Name', alignRight: false },
     { id: 'phone', label: 'Phone', alignRight: false },
     { id: 'email', label: 'Email', alignRight: false },
     { id: 'role', label: 'Role', alignRight: false },
     { id: 'verified', label: 'Verified', alignRight: false },
     { id: '' },
   ];
   
   function descendingComparator(a, b, orderBy) {
     if (b[orderBy] < a[orderBy]) {
       return -1;
     }
     if (b[orderBy] > a[orderBy]) {
       return 1;
     }
     return 0;
   }
   
   function getComparator(order, orderBy) {
     return order === 'desc'
       ? (a, b) => descendingComparator(a, b, orderBy)
       : (a, b) => -descendingComparator(a, b, orderBy);
   }
   
   function applySortFilter(array, comparator, query) {
     const stabilizedThis = array.map((el, index) => [el, index]);
     stabilizedThis.sort((a, b) => {
       const order = comparator(a[0], b[0]);
       if (order !== 0) return order;
       return a[1] - b[1];
     });
     if (query) {
       return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
     }
     return stabilizedThis.map((el) => el[0]);
   }
   const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
 
 const ManageLearners = () => {
     const [createHost] = useCreateHostMutation()
     const [users, setUsers] = useState([])
     const navigate = useNavigate()
     const dispatch = useDispatch()
     const { accessToken } = getToken()
     const [open, setOpen] = useState(null);
     const getAllUsers = useGetAllUsersQuery(accessToken)
 
     const [name, setName] = useState("");
     const [email, setEmail] = useState("");
     const [phone, setPhone] = useState("");
     const [password, setPassword] = useState("");
     const [department, setDepartment] = useState("");
     const [qualification, setQualification] = useState("");
     const [dob, setDob] = useState("");
 
     const [page, setPage] = useState(0);
     const [order, setOrder] = useState('asc');
     const [serverError, setServerError] = useState({});
     const [selected, setSelected] = useState([]);
   
     const [orderBy, setOrderBy] = useState('name');
   
     const [filterName, setFilterName] = useState('');
     const [openOTP, setOpenOTP] = useState(false)
     const [rowsPerPage, setRowsPerPage] = useState(5);
 
      // Store User Data in Local State
   useEffect(() => {
     if (getAllUsers.data && getAllUsers.isSuccess) {
       setUsers(getAllUsers.data)
     }
   }, [getAllUsers.data, getAllUsers.isSuccess]) 
   
     const handleOpenMenu = (event) => {
       setOpen(event.currentTarget);
     };
   
     const handleCloseMenu = () => {
       setOpen(null);
     };
     const handleOpen = () => setOpenOTP(true);
     const handleClose = () => setOpenOTP(false);
   
     const handleRequestSort = (event, property) => {
       const isAsc = orderBy === property && order === 'asc';
       setOrder(isAsc ? 'desc' : 'asc');
       setOrderBy(property);
     };
   
     const handleSelectAllClick = (event) => {
       if (event.target.checked) {
         const newSelecteds = users.map((n) => n.name);
         setSelected(newSelecteds);
         return;
       }
       setSelected([]);
     };
   
     const handleClick = (event, name) => {
       const selectedIndex = selected.indexOf(name);
       let newSelected = [];
       if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
       } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
       } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
       } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
       }
       setSelected(newSelected);
     };
   
     const handleChangePage = (event, newPage) => {
       setPage(newPage);
     };
   
     const handleChangeRowsPerPage = (event) => {
       setPage(0);
       setRowsPerPage(parseInt(event.target.value, 10));
     };
   
     const handleFilterByName = (event) => {
       setPage(0);
       setFilterName(event.target.value);
     };
     const handleCreateHost = () => {
       handleOpen()
     }
     const submitHost = async (e) => {
       e.preventDefault();
       const data = new FormData(e.currentTarget);
     
       const actualData = {
         name: data.get('name'),
         email: data.get('email'),
         phone: data.get('phone'),
         password: data.get('password'),
         department: data.get('department'),
         qualification: data.get('qualification'),
         date_of_birth: data.get('dob'),
       }
       console.log(actualData)
       const res = await createHost({actualData, accessToken})
       if (res.error) {
         // console.log(typeof (res.error.data.errors))
         console.log("error",res.error)
         setServerError(res.error.data.errors)
       }
       if (res.data) {
         console.log(typeof (res.data))
         console.log("data", res.data)
         // console.log("token", res.data.token)
          
         handleClose()
         navigate('/adminpage/user')
       }
     }
   
     const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
   
     const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
   
     const isNotFound = !filteredUsers.length && !!filterName;
 
     if (getAllUsers.isLoading) {
         ;<Stack spacing={1}>
             <h1 className="mt-4">Loading</h1>
             {/* For variant="text", adjust the height via font-size */}
             <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
             {/* For other variants, adjust the size with `width` and `height` */}
             <Skeleton variant="circular" width={40} height={40} />
             <Skeleton variant="rectangular" width={210} height={60} />
             <Skeleton variant="rounded" width={210} height={60} />
         </Stack>
     }
     
    //  if (getAllUsers.isLoading){
    //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
    //  }  
     
     if (getAllUsers.isError){
       return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No User Data Found...</h1>)
     }else{
     return (
         <>
       <Helmet>
         <title> User | Y-Plan </title>
       </Helmet>
 
       <Container>
         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
           <Typography variant="h4" gutterBottom>User</Typography>
           {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>New Host</Button>   */}
         </Stack>
         <Card>
           <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
           <Scrollbar>
             <TableContainer sx={{ minWidth: 800 }}>
               <Table>
                 <UserListHead
                   order={order}
                   orderBy={orderBy}
                   headLabel={TABLE_HEAD}
                   rowCount={users.length}
                   numSelected={selected.length}
                   onRequestSort={handleRequestSort}
                   onSelectAllClick={handleSelectAllClick}
                 />
                 <TableBody>
                   {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                   {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                     // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                     const { id, name, email, image, phone,is_active, is_admin, is_client, is_host, is_learner, account_activated } = row;
                     const isActive = String(is_active)
                     const accountActivated = String(account_activated)
                     const selectedUser = selected.indexOf(name) !== -1;
                       if (is_admin){
                         var role = 'Admin';
                       }else if(is_learner){
                         var role =  "Learner";
                       }else if(is_host){
                         var role =  "Host";
                       }else if(is_client){
                         var role =  "Client";
                       }
                     return (
                       <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                         
                         <TableCell padding="checkbox">
                           <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                         </TableCell>
 
                         <NavLink to={`http://${currentNetwork}/adminpage/user/${id}`}>
                         <TableCell component="th" scope="row" padding="none">
                           <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar alt={name} src={image} />
                             <Typography variant="subtitle2" noWrap>
                               {name}
                             </Typography>
                           </Stack>
                         </TableCell></NavLink>
 
                         <TableCell align="left">{phone}</TableCell>
 
                         <TableCell align="left">{email}</TableCell>
            
                         <TableCell align="left">{role}</TableCell>
 
                         <TableCell align="left">
                           <Label color={(accountActivated === 'banned' && 'error') || 'success'}>{sentenceCase(accountActivated)}</Label>
                         </TableCell>
 
                         <TableCell align="right">
                           <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                             <Iconify icon={'eva:more-vertical-fill'} />
                           </IconButton>
                         </TableCell>
                       </TableRow>
                     );
                   })}
                   {emptyRows > 0 && (
                     <TableRow style={{ height: 53 * emptyRows }}>
                       <TableCell colSpan={6} />
                     </TableRow>
                   )}
                 </TableBody>
 
                 {isNotFound && (
                   <TableBody>
                     <TableRow>
                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                         <Paper
                           sx={{
                             textAlign: 'center',
                           }}
                         >
                           <Typography variant="h6" paragraph>
                             Not found
                           </Typography>
 
                           <Typography variant="body2">
                             No results found for &nbsp;
                             <strong>&quot;{filterName}&quot;</strong>.
                             <br /> Try checking for typos or using complete words.
                           </Typography>
                         </Paper>
                       </TableCell>
                     </TableRow>
                   </TableBody>
                 )}
               </Table>
             </TableContainer>
           </Scrollbar>
 
           <TablePagination
             rowsPerPageOptions={[5, 10, 25]}
             component="div"
             count={users.length}
             rowsPerPage={rowsPerPage}
             page={page}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
           />
         </Card>
       </Container>
 
       <Popover
         open={Boolean(open)}
         anchorEl={open}
         onClose={handleCloseMenu}
         anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
         PaperProps={{
           sx: {
             p: 1,
             width: 140,
             '& .MuiMenuItem-root': {
               px: 1,
               typography: 'body2',
               borderRadius: 0.75,
             },
           },
         }}
       >
         
         <MenuItem>
           <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
           Edit
         </MenuItem>
 
         <MenuItem sx={{ color: 'error.main' }}>
           <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
           Delete
         </MenuItem>
       </Popover>
 
 
       {/* Create host Modal  */}
       <Modal
         component="form"
         open={openOTP}
         onClose={handleClose}
         id='otp-form' 
         onSubmit={submitHost}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         // style={{backgrou}}
       >
         <Box
          
          noValidate
          sx={style}>
           <Typography id="modal-modal-title" variant="h6" component="h2">
             Create a Host
           </Typography>
            
           <TextField margin='normal' required fullWidth id='name' name='name' label='name'  onChange={(name) => {setPhone(name)}} />
           {serverError.name ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography> : ""} 
 
           <TextField margin='normal' required fullWidth id='email' name='email' label='email'  onChange={(email) => {setPhone(email)}} />
           {serverError.email ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography> : ""}
            
           <TextField margin='normal' required fullWidth id='phone' name='phone' label='phone' onChange={(phone) => {setEmail(phone)}}/>
           {serverError.phone ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography> : ""}
 
           <TextField margin='normal' required fullWidth id='password' type='password' name='password' label='password'  onChange={(password) => {setPhone(password)}} />
           {serverError.password ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.password[0]}</Typography> : ""} 
 
           <TextField margin='normal' required fullWidth id='department' name='department' label='department'  onChange={(department) => {setPhone(department)}} />
           {serverError.department ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.department[0]}</Typography> : ""}
            
           <TextField margin='normal' required fullWidth id='qualification' name='qualification' label='qualification' onChange={(qualification) => {setEmail(qualification)}}/>
           {serverError.qualification ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.qualification[0]}</Typography> : ""} 
           
           <TextField margin='normal' required fullWidth id='date_of_birth' type='date' name='dob' label='date_of_birth' onChange={(date_of_birth) => {setEmail(emdate_of_birthadate_of_birthilOtp)}}/>
           {serverError.dob ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.date_of_birth[0]}</Typography> : ""}
 
 
           <Box textAlign='center'>
             <Button onClick={handleClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 5,  }}>Cancel</Button>
             <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>Create Host</Button>
           </Box>
           {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
         </Box>
       </Modal>
     </>
     )
   }
 }
 
 export default ManageLearners