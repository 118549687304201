/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { AppBar, Avatar, Box, CircularProgress, Button, Checkbox, Chip, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Rating, Tab, Tabs, TextField, Typography, useTheme, Popper, Fade, Paper,
} from '@mui/material';
// import CircularProgress from '@mui/joy/CircularProgress';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuizIcon from '@mui/icons-material/Quiz';
import ReactPlayer from 'react-player';

// import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import { getToken } from '../services/LocalStorageService';
import {
  useCourseDetailsQuery,
  useGetAllQuizQuery,
  useGetCourseModulesQuery,
  useGetCourseResoursesQuery,
  useGetCourseVideosQuery,
  useGetMyProgressQuery,
  useSubmitProgressMutation,
} from '../services/coursesApi';
import Iconify from '../components/iconify/Iconify';
import currentServer from '../_mock/server';
import VideoPlayer from './VideoPlayer';
import CourseNavbar from './CourseNavbar';
import CustomControls from './CustomControls';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
// import { TabPanel } from '@mui/lab'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    // expandIcon={<IconButton edge="end" aria-label="delete"> <ArrowForwardIosSharpIcon /> </IconButton>}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ContinueLearning = () => {
  const { slug, videoId } = useParams();
  const { accessToken } = getToken();
  const theme = useTheme();
  const navigate = useNavigate();
  // const history = useHistory()
  const [courseData, setCourseData] = useState({});
  const [moduleList, setModuleList] = useState([]);
  const [resourseList, setResourseList] = useState([]);
  const [videosList, setVideosList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [value, setValue] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);

  const [lastWatched, setLastWatched] = useState({
    video_id: '',
    video_url: '',
    minutes: '',
    videos_watched: 0,
    total_videos: 0,
    progress_percent: 0,
  });
  // const [lastPlayedVideoURL, setLastPlayedVideoURL] = useState("")
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const courseDetails = useCourseDetailsQuery({ slug, accessToken });
  const moduleDetails = useGetCourseModulesQuery({ slug, accessToken });
  const resourseDetails = useGetCourseResoursesQuery({ slug, accessToken });
  const videoDetails = useGetCourseVideosQuery({ slug, accessToken });
  const getMyProgress = useGetMyProgressQuery({ slug, accessToken });
  const getAllQuiz = useGetAllQuizQuery({ slug, accessToken });
  const [submitProgress, { isError }] = useSubmitProgressMutation();
  console.log("🚀 ~ file: CourseDetails.js:18 ~ CourseDetails ~ getAllQuiz:", videoDetails)
  // Storing data to the local state
  // const handleVideoClick = (videoId) => {
  //   history.push(`/my-course/${course.slug}/learn/${lastPlayedVideoId}`);
  // };
  // if (lastPlayedVideoId) {
  //    <Navigate to={`/my-course/${slug}/learn/${lastPlayedVideoId}`} />;
  // }
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    if (getMyProgress.data && getMyProgress.isSuccess) {
      setLastWatched({
        video_id: getMyProgress.data.last_video_watched.id,
        video_url: getMyProgress.data.last_video_watched.video,
        minutes: getMyProgress.data.minutes_watched,
        videos_watched: getMyProgress.data.number_of_videos_watched,
        total_videos: getMyProgress.data.total_number_of_videos,
        progress_percent: getMyProgress.data.progress_percent,
      });
      // setCurrentTime(getMyProgress.data.minutes_watched * 60)
      playerRef.current.seekTo(parseFloat(lastWatched.minutes));
      // setLastPlayedVideoId(getMyProgress.data.last_video_watched.id)
    }
    //   if (lastPlayedVideoId) {
    //     // <Navigate to={`/my-course/${slug}/learn/${lastPlayedVideoId}`} />;
    //     navigate(`/my-course/${slug}/learn/${lastPlayedVideoId}`) ;
    //  }
  }, [getMyProgress.data, getMyProgress.isSuccess]);

  const [currentVideoURL, setCurrentVideoURL] = useState(lastWatched.video_url);
  useEffect(() => {
    if (courseDetails.data && courseDetails.isSuccess) {
      setCourseData(courseDetails.data);
    }
  }, [courseDetails.data, courseDetails.isSuccess]);
  useEffect(() => {
    if (moduleDetails.data && moduleDetails.isSuccess) {
      setModuleList(moduleDetails.data);
    }
  }, [moduleDetails.data, moduleDetails.isSuccess]);
  useEffect(() => {
    if (resourseDetails.data && resourseDetails.isSuccess) {
      setResourseList(resourseDetails.data);
    }
  }, [resourseDetails.data, resourseDetails.isSuccess]);
  useEffect(() => {
    if (videoDetails.data && videoDetails.isSuccess) {
      setVideosList(videoDetails.data);
      var setVideo = videoDetails.data.filter((curElem) => {
        return curElem.id === videoId;
      });
      // console.log(setVideo[0].video);
      setCurrentVideoURL(setVideo[0].video);
    }
  }, [videoDetails.data, videoDetails.isSuccess]);
  useEffect(() => {
    if (getAllQuiz.data && getAllQuiz.isSuccess) {
      setQuizList(getAllQuiz.data);
    }
  }, [getAllQuiz.data, getAllQuiz.isSuccess]);
  //   console.log("course", courseData)

  const {
    id, slug: alias, description, academy, category, subCategory, enroller_user, image_course, created_at, host, learned, name, organization, rating, small_description, tags, total_module, total_video, updated_at, vidoes_time,
  } = courseData;
  // console.log("🚀 ~ file: CourseDetails.js:51 ~ CourseDetails ~ videoDetails:", videoDetails)

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubmit = () => {};

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  // useEffect(() => {
  const handleCurrentVideo = (e, id, url) => {
    navigate(`/learnerpage/my-course/${slug}/learn/${id}`);
    // location.reload()
    setCurrentVideoURL(url);
    // console.log('current video url', currentVideoURL)
  };
  // }, [setCurrentVideoURL, videoDetails.isSuccess])
  const [minutesWatched, setMinutesWatched] = useState(0);
  const [completed, setCompleted] = useState(false);

  // const videoRef = useRef<ReactPlayer>(null)
  const playerRef = useRef(null);
  // const [played, setPlayed] = useState(0)
  const handleProgress = (progress) => {
    // Update user's watch time whenever the video progresses
    if (progress.played === 1) {
      // If the video has been fully watched
      setCompleted(true); // Set completion status to true
    }
    setCurrentTime(progress.playedSeconds);
    setMinutesWatched(progress.playedSeconds / 60);
    // console.log("Watching video...", minutesWatched);
  };

  // const handleSeek = () => {
  //   if (completed) {
  //     // If the current video has been completed
  //     const currentTime = playerRef.current.getCurrentTime();
  //     console.log('🚀 ~ handleSeek ~ currentTime:', currentTime);
  //     const played = playerRef.current.getCurrentTime();
  //     if (currentTime > played) {
  //       // If the user tries to seek ahead
  //       playerRef.current.seekTo(played); // Seek back to the last watched position
  //     }
  //   }
  // };
  // const playerRef = useRef(null);
  // const [currentTime, setCurrentTime] = useState(0);

  const handleSeek = (e) => {
    // Check if the user is trying to seek forward
    if (e.target.currentTime > currentTime) {
      // If so, prevent seeking by resetting the current time
      playerRef.current.seekTo(currentTime, 'seconds');
    } else {
      // Otherwise, update the current time
      setCurrentTime(e.target.currentTime);
    }
  };

  const handleSaveProgress = async (e) => {
    // console.log(e)
    // console.log(completed);
    // if (completed){
    const actualData = {
      course: slug,
      last_video_watched: videoId,
      completed: completed,
      minutes_watched: parseInt(minutesWatched),
    };
    console.log(actualData);
    const res = await submitProgress({ actualData, accessToken });
    if (res.error) {
      console.log(res.error);
      console.log(res.error.data.errors);
    }
    if (res.data) {
      console.log(res.data);
    }
    // }
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const PopperPopupState = () => {
    return (
      <PopupState variant="popper" popupId="demo-popup-popper">
        {(popupState) => (
          <div>
            <Button variant="contained" {...bindToggle(popupState)}>
              My Progress
            </Button>
            <Popper {...bindPopper(popupState)} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={300}>
                  <Paper>
                    <Typography sx={{ p: 2 }}>
                      {' '}
                      <b>
                        {lastWatched.videos_watched} of {lastWatched.total_videos} completed.{' '}
                      </b>
                    </Typography>
                    <Typography sx={{ p: 2 }}> Finish your course to get certificate.</Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    );
  };

  const CourseDetails = () => {
    if (courseDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Course Details was found. Please update...
        </Typography>
      );
    } else if (courseDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (courseDetails.isSuccess) {
      return (
        <>
          <Typography variant='h4'>About The course:</Typography>
          <Typography sx={{ color: 'text.primary', mt: 2 }}>{name}</Typography>
          <br/>
          <hr/>

          <Typography sx={{ color: 'text.secondary', mt: 2 }} >Total Modules: {total_module}</Typography>
          <br/>
          <hr/>
          <Typography sx={{ color: 'text.secondary', mt: 2 }} >Category : {category}</Typography>
          <Typography sx={{ color: 'text.secondary', mt: 2 }} >SubCategory : {subCategory}</Typography>
          <br/>
          <hr/>
          <Typography sx={{ color: 'text.secondary', mt: 2 }} >Host : {host}</Typography>
          <Typography sx={{ color: 'text.secondary', mt: 2 }} >Academy : {academy}</Typography>
          <br/>
          <hr/>
          {/* <TextField value={name} required fullWidth id="name" name="name" /> */}

          {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Date of Birth</Typography>
                <TextField value={description} fullWidth id="date_of_birth" name="date_of_birth" type="date" InputLabelProps={{ shrink: true, }} /> */}

          {/* <Typography sx={{ color: 'text.secondary', mt: 2 }} >Host</Typography>
          <TextField value={host} required fullWidth id="host" name="host" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Academy</Typography>
          <TextField value={academy} required fullWidth id="academy" name="academy" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Category</Typography>
          <TextField value={category} required fullWidth id="category" name="category" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>SubCategory</Typography>
          <TextField value={subCategory} required fullWidth id="subCategory" name="subCategory" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Description</Typography>
          <TextField value={description} required fullWidth id="description" name="description" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Sub Description</Typography>
          <TextField value={small_description} required fullWidth id="small_description" name="small_description" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Created At</Typography>
          <TextField value={created_at} required fullWidth id="created_at" name="created_at" />

          <Typography sx={{ color: 'text.secondary', mt: 2 }}>Updated At</Typography>
          <TextField value={updated_at} required fullWidth id="updated_at" name="updated_at" /> */}
        </>
      );
    }
  };

  const ModuleDetails = () => {
    if (moduleDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Course Details was found. Please update...
        </Typography>
      );
    } else if (moduleDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (moduleDetails.isSuccess) {
      return (
        <>
          <List>
            {moduleList.map((modules) => (
              <ListItem key={modules.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText primary={modules.name} secondary={`${modules.description} - ${modules.duration}`} sx={{ opacity: open ? 1 : 0 }}>
                  </ListItemText>
                  
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };

  const ResourseDetails = () => {
    if (resourseDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Resource was found. Please update...
        </Typography>
      );
    } else if (resourseDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (resourseDetails.isSuccess) {
      return (
        <>
          <List>
            {resourseList.map((modules) => (
              <ListItem key={modules.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={modules.name} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <Link target="_blank" to={`${modules.file}`}>
                    <ListItemText primary="File" sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                  </Link>
                  <Link ms={10} target="_blank" to={`${modules.url}`}>
                    <ListItemText primary={modules.url} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  const VideoDetails = () => {
    if (videoDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Videos was found. Please update...
        </Typography>
      );
    } else if (videoDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (videoDetails.isSuccess) {
      return (
        <>
          <List>
            {videosList.map((videos) => (
              <ListItem key={videos.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText primary={videos.name} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                    {videos.name} - {videos.description}{' '}
                  </ListItemText>
                  <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                  </ListItemText>
                  <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }}>
                    {' '}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  const ModuleVideos = ({ id }) => {
    if (videoDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Videos was found. Please update...
        </Typography>
      );
    } else if (videoDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (videoDetails.isSuccess) {
      var allVideos = videoDetails.data.filter((curElem) => {
        return curElem.module === id;
      });
      // console.log(allVideos)
      return (
        <>
          <List>
            {allVideos.map((videos) => (
              <ListItem key={videos.id} disablePadding sx={{ 
                display: 'block', 
                backgroundColor: videoId === videos.id ? '#f0f0f0' : 'inherit',
              }}>
                {/* <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} component={NavLink} to="/adminpage/subhosts"> */}
                <ListItemButton
                  sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  onClick={(e) => handleCurrentVideo(e, videos.id, videos.video)}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} />
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    <ListItemText primary={videos.name} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                    <Typography variant="p" sx={{ flexShrink: 0 }}>
                      <OndemandVideoIcon sx={{ flexShrink: 0 }} />
                      {videos.duration}
                    </Typography>
                  </div>
                  {/* <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  const ModuleQuizes = ({ id }) => {
    if (getAllQuiz.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Quiz was found. Please update...
        </Typography>
      );
    } else if (getAllQuiz.isLoading) {
      return <p>Loading...</p>;
    } else if (getAllQuiz.isSuccess) {
      var allQuizes = getAllQuiz.data.filter((curElem) => {
        return curElem.module === id;
      });
      // console.log(allVideos)
      return (
        <>
          <List>
            {allQuizes.map((quiz, index) => (
              <ListItem key={quiz.id} disablePadding sx={{ 
                display: 'block', 
                // backgroundColor: videoId === videos.id ? '#f0f0f0' : 'inherit',
              }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} component={NavLink} to={`/learnerpage/attemptQuiz/${quiz.id}`}>
                {/* <ListItemButton
                  sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  onClick={(e) => handleCurrentVideo(e, videos.id, videos.video)}
                > */}
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} />
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    <ListItemText primary={` Quiz ${index + 1}`} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                    {/* <Typography variant="p" sx={{ flexShrink: 0 }}>
                      <OndemandVideoIcon sx={{ flexShrink: 0 }} />
                      {videos.duration}
                    </Typography> */}
                  </div>
                  {/* <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  const ModuleResources = ({ id }) => {
    if (resourseDetails.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Resource was found. Please update...
        </Typography>
      );
    } else if (resourseDetails.isLoading) {
      return <p>Loading...</p>;
    } else if (resourseDetails.isSuccess) {
      var allResources = resourseDetails.data.filter((curElem) => {
        return curElem.module === id;
      });
      // console.log(allVideos)
      return (
        <>
          <List>
            {allResources.map((resource, index) => (
              <ListItem key={resource.id} disablePadding sx={{ 
                display: 'block', 
                // backgroundColor: videoId === videos.id ? '#f0f0f0' : 'inherit',
              }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} component={NavLink} to={`${resource.url}`} target="_blank">
                {/* <ListItemButton
                  sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  onClick={(e) => handleCurrentVideo(e, videos.id, videos.video)}
                > */}
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    {/* <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} /> */}
                    <MenuBookIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    <ListItemText primary={` Resource ${index + 1}`} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                    {/* <Typography variant="p" sx={{ flexShrink: 0 }}>
                      <OndemandVideoIcon sx={{ flexShrink: 0 }} />
                      {videos.duration}
                    </Typography> */}
                  </div>
                  {/* <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  
  const QuizSection = () => {
    if (getAllQuiz.isError) {
      return (
        <Typography variant="h6" sx={{ flexShrink: 0 }}>
          No Videos was found. Please update...
        </Typography>
      );
    } else if (getAllQuiz.isLoading) {
      return <p>Loading...</p>;
    } else if (getAllQuiz.isSuccess) {
      // var allVideos = getAllQuiz.data.filter((curElem) => {
      //   return curElem.module === id;
      // });
      // console.log(allVideos)
      return (
        <>
          <List>
            {quizList.map((quiz, index) => (
              <ListItem key={quiz.id} disablePadding sx={{ 
                display: 'block', 
                // backgroundColor: videoI.d === quiz.id ? '#f0f0f0' : 'inherit',
              }}>
                <ListItemButton
                  sx={{ minHeight: 20, justifyContent: open ? 'initial' : 'center', px: 1 }}
                  component={NavLink} to={`/learnerpage/attemptQuiz/${quiz.id}`}
                  // onClick={(e) => handleCurrentVideo(e, quiz.id, quiz.video)}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    {/* {quiz.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {/* <CropSquareIcon /> */}
                    <Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'black' } }} />
                  </ListItemIcon>
                  {/* <ListItemText primary={<input class="largerCheckbox" type="checkbox"/>} secondary={quiz.description} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                  <div className="text">
                    <ListItemText primary={`Quiz ${index +1}`} sx={{ opacity: open ? 1 : 0 }}>
                      {' '}
                    </ListItemText>
                    <Typography variant="p" sx={{ flexShrink: 0 }}>
                      {/* <OndemandVideoIcon sx={{ flexShrink: 0 }} /> */}
                      {quiz.duration}
                    </Typography>
                  </div>
                  {/* <ListItemText primary={quiz.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };
  // ModuleVideos(1)
  // const renderMainContent = () => {
  //   // if (chapterItem?.class === 'Lecture')
  //     return <VideoPlayer url={chapterItem?.embedUrl} />;

  return (
    <>
      <Grid container>
        <Grid item sm={showSidebar ? 12 : 8} mb={2} sx={{ height: '100%', py: 2, color: 'black', border: 'rounded' }}>
          {/* {lastPlayedVideoURL ? <VideoPlayer url={lastPlayedVideoURL} />:<VideoPlayer url={currentVideoURL} />} */}
          {/* <VideoPlayer url={currentVideoURL} />  */}
          <div style={{ position: 'relative', paddingTop: '56.25%' }}>
            <ReactPlayer
              url={currentVideoURL}
              width="100%"
              height="100%"
              controls
              // controls={false}
                
              playing={true}
              muted={true}
              style={{ position: 'absolute', top: 0, left: 0 }}
              ref={playerRef}
              onStart={() => {
                // this.player.seekTo(lastWatched.minutes);
              }}
              // onProgress={handleProgress}
              // onProgress={handleSeek}
              onPause={handleSaveProgress}
              onEnd={handleSaveProgress}
              // onSeek={handleSeek}
              // config={{
              //   file: {
              //     attributes: {
              //       currentTime: currentTime,
              //     },
              //   },
              // }}
              //     onSeek={() => {
              //       videoRef.current.getCurrentTime() > played &&
              //       videoRef.current.seekTo(played)
              // }}
              // onProgress={(progress) => {
              //   setPlayed(progress.playedSeconds);
              // }}
            />
            {/* <CustomControls /> */}
          </div>
          <div style={{ position: 'relative', padding: '0.5em' }}>
            <Box sx={{ flexShrink: 0 }}>
              <Typography variant="h4" sx={{ flexShrink: 0 }}>
                Videos, Resources and Notes:
              </Typography>
            </Box>
            <Box component="form" noValidate id="profileDetails-form" onSubmit={handleSubmit}>
              <Box sx={{ width: '100%' }}>
                {/* <AppBar position="static"> */}
                <Tabs
                  value={value}
                  onChange={handleTabsChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Course Details" {...a11yProps(0)} />
                  <Tab label="Modules" {...a11yProps(1)} />
                  {/* <Tab label="Resources" {...a11yProps(2)} />
                  <Tab label="Videos" {...a11yProps(3)} /> */}
                </Tabs>
                {/* </AppBar> */}
              </Box>
              {/* <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                > */}
              <TabPanel value={value} index={0} dir={theme.direction}>
                <CourseDetails />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ModuleDetails />
              </TabPanel>
              {/* <TabPanel value={value} index={2} dir={theme.direction}>
                <ResourseDetails />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <VideoDetails />
              </TabPanel> */}

              {/* </SwipeableViews> */}
            </Box>
          </div>
        </Grid>
        {showSidebar ? (
          <IconButton aria-label="close" onClick={toggleSidebar} style={{ position: 'absolute', top: '100px', right: '10px', padding: "8px", px: "30px" }}>
           <Button  variant="contained"> <KeyboardBackspaceIcon /></Button>
          </IconButton>
        ) : (
          <Grid item sm={4} mb={2} sx={{ height: '400px', py: 2, color: 'black', border: 'rounded' }}>
            {/* <Avatar
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
                sx={{ width: 156, height: 156 , mb:5, mt:5}}
            /> */}
            <Typography variant="h4" sx={{ flexShrink: 0, border: '1px solid rgba(0, 0, 0, .125)', py: 1, px: 1 }}>
              Course Content
              <IconButton aria-label="close" onClick={toggleSidebar} mx="auto">
                <CloseIcon />
                {/* <CircularProgress determinate value={100} >12</CircularProgress> */}
              </IconButton>
              <PopperPopupState />
            </Typography>

            {moduleList.map((modules) => (
              // <Accordion key={modules.id} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <Accordion
                key={modules.id}
                expanded={expanded === `${modules.id}`}
                onChange={handleChange(`${modules.id}`)}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <div className="text">
                    <Typography variant="h6" sx={{ flexShrink: 0 }}>
                      {modules.name}
                    </Typography>

                    <Typography variant="p" sx={{ flexShrink: 0 }}>
                      {modules.total_video} | {modules.duration}
                    </Typography>
                  </div>
                </AccordionSummary>
                {/* <AccordionDetails disablePadding> */}
                {/* <Typography>
                 
              </Typography> */}
                <div className="text">
                  <ModuleVideos id={modules.id} />
                  <ModuleResources id={modules.id} />
                  <ModuleQuizes id={modules.id} />
                </div>
                {/* </AccordionDetails> */}
              </Accordion>
            ))}
            
            {/* Quiz section........... */}
            {/* <QuizSection /> */}


            {/* <Typography variant='h5' sx={{  flexShrink: 0 }} gutterBottom>
                        Description:  {description}
            </Typography>
            <Typography variant='h6' sx={{ flexShrink: 0, color:"grey" }} gutterBottom>
                        {small_description}
            </Typography>
            <Typography variant='p' sx={{  flexShrink: 0 }} gutterBottom>
            <Chip label="Ratings: "  color="secondary" />  {rating} <Rating mt={1} name="read-only" value={rating} readOnly/> 
            </Typography>   */}
            {/* <Typography sx={{ flexShrink: 0 }}>
                        Status:  {account_activated? "Active" : "Not Active"}
            </Typography> */}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContinueLearning;

// {/* <video
// controls // Add this attribute to show video controls (play, pause, etc.)
// // autoPlay // Add this attribute to auto-play the video
// loop // Add this attribute to make the video loop
// muted // Add this attribute to mute the video (optional)
// preload="auto" // Add this attribute to preload the video
// style={{ width: '100%', height: '100%' }}
// >
// <source src={`${currentServer}/${curElem.video}`} type="video/mp4" />
// {/* Add other source elements for different video formats if needed */}
// {/* <source src="/assets/illustrations/gif.webm" type="video/webm" /> */}
// {/* <source src="/assets/illustrations/gif.ogv" type="video/ogg" /> */}
// Your browser does not support the video tag.
// </video> */}
