/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../features/userSlice'
import { getToken } from '../services/LocalStorageService'
import { useGetUserDetailsQuery, useGetUserProfessionalDataQuery, useGetUserProfileDataQuery, useGetUserUploadDataQuery, useNotifyUserMutation } from "../services/userAuthApi"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Paper, Button, CircularProgress, Grid, Input, TextField, Typography, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { Link, useParams } from 'react-router-dom'
import { TITLE, DISABILITY, COUNTRY_CHOICES, EMPLOYMENT } from "../_mock/dropdowns";
import Iconify from '../components/iconify'

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'personal',
    label: 'Personal Details',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  {
    value: 'professional',
    label: 'Professional Details',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  {
    value: 'uploads',
    label: 'Uploads',
    icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
  },
  // {
  //   value: 'academy',
  //   label: 'Academy',
  //   icon: <Iconify icon="solar:gallery-wide-bold" width={24} />,
  // },
];
const SelectedUser = () => {
  const { id } = useParams()

  const [newTitle, setTitle] = useState()
  const [idNumber, setIdnumber] = useState()
  const [dateofBirth, setDob] = useState(null)
  const [newEthnicity, setEthnicity] = useState('')
  const [newGender, setGender] = useState()
  const [newCitizenship, setCitizenship] = useState()
  const [newDisability, setDisability] = useState()
  const [taxNumber, setTax] = useState()
  const [employmentStatus, setEmployment] = useState()
  const [homeLanguage, setHomeLanguage] = useState()
  const [streetAddress, setStreet] = useState()
  const [residentalArea, setResidentalArea] = useState()
  const [newCity, setCity] = useState()
  const [newProvince, setProvince] = useState()
  const [postalCode, setPostalCode] = useState()
  const [postalAddress, setPostalAddress] = useState()
  const [emergencyNumber, setEmergencyNumber] = useState()
  const [whatsappNumber, setWhatsappNumber] = useState()
  const [lastSchool, setLastSchool] = useState()
  const [lastSchoolYear, setLastSchoolYear] = useState()
  const [schoolHighestGrade, setSchoolHighestGrade] = useState()
  const [idUpload, setID] = useState('')
  const [cdUpload, setCV] = useState('')
  const [bankConfirmationUpload, setBank] = useState('')
  const [highestEducationCertificate, setEduCertificate] = useState('')
  const [sarsDocumentUpload, setSars] = useState('')
  const [disabilityDocument, setDisabilityDocument] = useState('')
  const [specialPowerAttorney, setSPA] = useState('')

  const dispatch = useDispatch()
  const {accessToken} = getToken()
  // const { data, isSuccess } = useGetLoggedUserQuery(accessToken)
//   console.log("data:", data)
  const [userData, setUserData] = useState({})
  const [profileData, setProfileData] = useState([])
  const [professionalData, setProfessionalData] = useState([])
  const [uploadData, setUploadData] = useState([])
  
  const learnerUser = useGetUserDetailsQuery({id, accessToken})

  const fetchProfileData = useGetUserProfileDataQuery({id, accessToken })
  // console.log("typeof", profileData)
  const fetchProfessionalData  = useGetUserProfessionalDataQuery({ id, accessToken })
  // console.log("typeof1", professionalData)
  const fetchUploadData = useGetUserUploadDataQuery({ id, accessToken })
  // console.log("typeof2", uploadData)

  useEffect(() => {
    if (learnerUser.data && learnerUser.isSuccess) {
        setUserData(learnerUser.data)
    }
  }, [learnerUser.data, learnerUser.isSuccess])

  useEffect(() => {
    if(fetchProfileData.data && fetchProfileData.isSuccess){
      setProfileData(fetchProfileData.data)
    }
  }, [fetchProfileData.data, fetchProfileData.isSuccess]) 
  
  useEffect(() => {
    if(fetchProfessionalData.data && fetchProfessionalData.isSuccess){
      setProfessionalData(fetchProfessionalData.data)
    }
  }, [fetchProfessionalData.data, fetchProfessionalData.isSuccess])
  
  useEffect(() => {
    if(fetchUploadData.data && fetchUploadData.isSuccess){
      setUploadData(fetchUploadData.data)
    }
  }, [fetchUploadData.data, fetchUploadData.isSuccess])

  const { account_activated, created_at, email, email_verified, image,is_active, is_admin, is_client, is_host, is_learner, last_login, name, password, phone, phone_verified, tc, updated_at} = userData
    const {user, id_number,title, gender, date_of_birth, ethnicity, citizenship, disability} = profileData
    const {tax_number, employment_status, home_language, street_address,residential_area, city, province,postal_code, postal_address, emergency_number, whatsapp_number, last_school, last_school_year, school_highest_grade} = professionalData
    const {id_upload, cv_upload, bank_confirmation_upload, highest_education_certificate, sars_document_upload, disability_document, special_power_attorney} = uploadData

  // const updateUserProfileData = useUpdateUserProfileDataQuery({ id, accessToken } )
  // const updateUserProfessionalData = useUpdateUserProfessionalDataQuery({ id, accessToken } )
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubmit = () => {}
  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    // const fileInput = document.getElementById('idFile');
        // console.log("fileInput", id_upload)
        // fileInput.innerHTML =id_upload; // This line will trigger the error.
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const PersonalDetails = () =>  {
    if (fetchProfileData.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Profile Data was found. Please Inform Learner to update...
        </Typography>
      )
    }else if (fetchProfessionalData.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (fetchProfessionalData.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Your Personal details:
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Your Personal details: 
                      </Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Title</Typography>
                        <TextField fullWidth value={title} id="title" select name="title" SelectProps={{ native: true, }} onChange={(e) => setTitle(e.target.value)}>
                        {TITLE.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Id Number</Typography>
                        <TextField value={id_number} required fullWidth id='id_number' name='id_number' onChange={(e) => setIdnumber(e.target.value)}/>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Date of Birth</Typography>
                        <TextField value={date_of_birth} fullWidth id="date_of_birth" name="date_of_birth" type="date" InputLabelProps={{ shrink: true, }} onChange={(e) => setDob(e.target.value)} />

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Ethnicity</Typography>
                        <TextField value={ethnicity} required fullWidth id='ethnicity' name='ethnicity' onChange={(e) => setEthnicity(e.target.value)}/>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Citizenship</Typography>
                        <TextField fullWidth value={citizenship} id="citizenship" select name="citizenship" SelectProps={{native: true, }} onChange={(e) => setCitizenship(e.target.value)}>          
                        {COUNTRY_CHOICES.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Disability</Typography>
                        <TextField fullWidth value={disability}  id="disability" select name="disability" SelectProps={{native: true, }} onChange={(e) => setDisability(e.target.value)}>          
                        {DISABILITY.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Gender</Typography>
                        <TextField id="gender" value={gender} required fullWidth  onChange={(e) => setGender(e.target.value)} />
                </AccordionDetails>
            </Accordion>
      )
    }
  }

  const ProfessionalDetails = () =>  {
    if (fetchProfessionalData.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Professional Data was found. Please Inform Learner to update...
        </Typography>
      )
    }else if (fetchProfessionalData.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (fetchProfessionalData.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Your Professional Details:
                      </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Your Professional Details:
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Tax Number</Typography>
                    <TextField value={tax_number} id="tax_number" required fullWidth  onChange={(e) => setTax(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Employment Status</Typography>
                    <TextField value={employment_status} id="employment_status" fullWidth select name="employment_status" SelectProps={{native: true, }} onChange={(e) => setEmployment(e.target.value)}>          
                    {EMPLOYMENT.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Home Language</Typography>
                    <TextField value={home_language} id="home_language"  required fullWidth onChange={(e) => setHomeLanguage(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Street Address</Typography>
                    <TextField value={street_address} id="street_address" required fullWidth onChange={(e) => setStreet(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Residential Area</Typography>
                    <TextField value={residential_area} id="residential_area" required fullWidth onChange={(e) => setResidentalArea(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>City</Typography>
                    <TextField value={city} id="city" required fullWidth  onChange={(e) => setCity(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Province</Typography>
                    <TextField value={province} id="province" required fullWidth  onChange={(e) => setProvince(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Postal Code</Typography>
                    <TextField value={postal_code} id="postal_code" required fullWidth  onChange={(e) => setPostalCode(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Postal Address</Typography>
                    <TextField value={postal_address} id="postal_address" required fullWidth  onChange={(e) => setPostalAddress(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Emergency Number</Typography>
                    <TextField value={emergency_number} id="emergency_number" required fullWidth  onChange={(e) => setEmergencyNumber(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Whatsapp Number</Typography>
                    <TextField value={whatsapp_number} id="whatsapp_number" required fullWidth  onChange={(e) => setWhatsappNumber(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Last School</Typography>
                    <TextField value={last_school} id="last_school" required fullWidth onChange={(e) => setLastSchool(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Last School Year</Typography>
                    <TextField value={last_school_year} id="last_school_year" required fullWidth  onChange={(e) => setLastSchoolYear(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>School Highest Grade</Typography>
                    <TextField value={school_highest_grade} id="school_highest_grade" required fullWidth  onChange={(e) => setSchoolHighestGrade(e.target.value)} />
                    
                </AccordionDetails>
            </Accordion>
      )
    }
  }

  const UploadDetails = () =>  {
    if (fetchUploadData.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Upload Data was found. Please Inform Learner to update...
        </Typography>
      )
    }else if (fetchUploadData.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (fetchUploadData.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Your Files:
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Your Files:
                    </Typography>
                </AccordionSummary>
                <AccordionDetails> 
                {/* <Stack direction="row" spacing={10}>
                <Typography sx={{ color: 'text.secondary' }}>ID</Typography>
                <Link target="_blank" to={`${id_upload}`} >View</Link>
                </Stack>
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>CV:</Typography>
                <Link sx={{justifyContent:"right"}} target="_blank" to={`${cv_upload}`} >View</Link>
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Bank Confirmation Upload</Typography>
                <Link target="_blank" to={`${id_upload}`} >bank_confirmation_upload</Link> */}
                <table className="table table-primary table-borderless">
                        {/* <table className="table"> */}
                    
                        <thead>
                            <tr>
                                <th scope="col">Document Type</th>
                                <th scope="col">File</th>
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ID:</td>
                                <td ><Link target="_blank" to={`${id_upload}`} >View</Link>
                                {/* <td><p style={{alignContent: "right"}} margin='normal'   id="idFile" ></p> */}
                                </td>
                                {/* <th><Button variant="contained" color="warning" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                            </tr>
                            <tr>
                                <td>CV:</td>
                                <td ><Link target="_blank" to={`${cv_upload}`} >View</Link></td>
                                {/* <th><Button variant="contained" color="grey" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                            </tr>
                            <tr>
                                <td>Bank Confirmation</td>
                                <td ><Link target="_blank" to={`${bank_confirmation_upload}`} >View</Link></td>
                                {/* <th><Button variant="contained" color="info" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                            </tr>
                            <tr>
                                <td>Highest Education Certificate</td>
                                <td ><Link target="_blank" to={`${highest_education_certificate}`} >View</Link></td>
                                {/* <th><Button variant="contained" color="warning" size="large" onClick={handleDiscard} sx={{ mt: 8 }} >Discard</Button></th> */}
                                {/* <th><Button variant="contained" color="warning" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                            </tr> 
                            <tr>
                                <td>Sars Document</td>
                                <td ><Link target="_blank" to={`${sars_document_upload}`} >View</Link></td>
                                {/* <th><Button variant="contained" color="primary" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                            </tr> 
                            <tr>
                                <td>Disability Document</td>
                                <td ><Link target="_blank" to={`${disability_document}`} >View</Link></td>
                                {/* <td><Button variant="contained" color="success" size="medium" osx={{ mt: 8 }} >Discard</Button></td> */}
                            </tr> 
                            <tr>
                                <td>Special Power Attorney</td>
                                <td ><Link target="_blank" to={`${special_power_attorney}`} >View</Link></td>
                                {/* <th><Button variant="contained" color="grey" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                {/* <th><div className="form-check form-switch"><input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"></input></div></th> */}
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
      )
    }
  }





  return (
    <>
    <Grid container>
        <Grid item sm={9}  mb={4} mx="auto"
        sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}
        >
        {/* <Item>  */}
        <Stack direction="row" spacing={2}>
        <Avatar
            alt="Remy Sharp"
            src={`${image}`}
            sx={{ width: 80, height: 80 , mb:2, mt:2, ms:10}}
        />
        <Typography variant='h2' sx={{ flexShrink: 0 }}>
                    Name:  {name}
        </Typography>
        </Stack>
        {/* <Typography sx={{  flexShrink: 0 }}>
                    Email:  {email}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
                    Gender:  {gender}
        </Typography>
        <Typography sx={{  flexShrink: 0 }}>
                    Date of Birth:  {date_of_birth}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
                    Status:  {account_activated? "Active" : "Not Active"}
        </Typography> */}
        {/* </Item> */}
        </Grid>
        
        <Grid item sm={10} mx="auto" mb={10}>
        {/* <Box sx={{  flexShrink: 0 }} >
            <Typography variant='h4' sx={{  flexShrink: 0 }}>
                   Any Correction in the below data requires admin approval:
            </Typography>
        </Box> */}
        <Box component="form" sx={{ p: 3, mt: 4}} noValidate id="profileDetails-form" onSubmit={handleSubmit}>

        {/* {profileData ? <PersonalDetails} */}
        
        <PersonalDetails/>
        <ProfessionalDetails/>
        <UploadDetails/>

            

            
            {/* {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="success">Submit</Button>} */}

        </Box>
        </Grid>
        </Grid>
        {/* <Image style={{height:"231"}} src={special_power_attorney} alt="img"/>  */}
    </>
  )
}
export default SelectedUser