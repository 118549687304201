import React, { useState } from 'react';
import { Typography, Container, List, ListItem, ListItemText, Checkbox, Button } from '@mui/material';

// Sample list of students (replace with your actual student data)
const students = [
  { id: 1, name: 'Akash', present: false },
  { id: 2, name: 'Aryan', present: false },
  { id: 3, name: 'Code12', present: false },
  { id: 4, name: 'Mr.Bin', present: false },

  // Add more students here
];

export default function AttendanceMarking() {
  const [studentList, setStudentList] = useState(students);

  const handleToggleAttendance = (studentId) => {
    setStudentList((prevList) =>
      prevList.map((student) =>
        student.id === studentId ? { ...student, present: !student.present } : student
      )
    );
  };

  const handleSubmitAttendance = () => {
    // Implement logic to submit attendance data to the backend
    // console.log(studentList);
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Learner's Application
      </Typography>
      <List>
        {studentList.map((student) => (
          <ListItem key={student.id}>
            <Checkbox
              checked={student.present}
              onChange={() => handleToggleAttendance(student.id)}
            />
            <ListItemText primary={student.name} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={handleSubmitAttendance}>
        Submit Approval
      </Button>
    </Container>
  );
}
