/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../services/LocalStorageService'
import { useGetHostProfileQuery, useGetSubhostProfileQuery, useGetUserDetailsQuery, useSubhostsLearnersQuery, useUpdateUserDetailsMutation } from '../services/userAuthApi'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Iconify from '../components/iconify'
import CustomBreadcrumbs from '../components/custom-breadcrumbs/custom-breadcrumbs'
import ProfileCover from '../sections/user/profile-cover'
import { Controller, useForm } from 'react-hook-form'

const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'personal',
      label: 'Personal Details',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    {
      value: 'learners',
      label: 'Learners ',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    // {
    //   value: 'uploads',
    //   label: 'Uploads',
    //   icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
    // },
]

const SubHostDetails = () => {
  const { id } = useParams()
  const [newBio, setBio] = useState()
  const [newDob, setDob] = useState()
  const [newDepartment, setDepartment] = useState()
  const [newOrganization, setOrganization] = useState()
  const [newQualification, setQualification] = useState()
  const [newResarchInterests, setSearchInterests] = useState()

  const navigate = useNavigate()
  const {accessToken} = getToken()
  const [userData, setUserData] = useState({})
  const [subhostData, setSubHostData] = useState({})
  const [subhostLearners, setSubhostsLearners] = useState([])
  const [currentTab, setCurrentTab] = useState('profile');
  const [updateError, setUpdateError] = useState({})
  const [updateUserDetails] = useUpdateUserDetailsMutation()
//   const selectedHost = useGetHostProfileQuery({id, accessToken})
  const getSubhost = useGetSubhostProfileQuery({id, accessToken})
  const getSubhostLearners = useSubhostsLearnersQuery({id, accessToken})
//   const hostUser = useGetUserDetailsQuery({id, accessToken})
//   console.log("🚀 ~ file: SubHostDetails.js:11 ~ HostDetails ~ subhostData:", selectedHost)

  // Store User Data in Local State
//   useEffect(() => {
//     if (hostUser.data && hostUser.isSuccess) {
//         setUserData(hostUser.data)
//     }
//   }, [hostUser.data, hostUser.isSuccess])
  useEffect(() => {
    if (getSubhost.data && getSubhost.isSuccess) {
      setSubHostData(getSubhost.data)
    }
  }, [getSubhost.data, getSubhost.isSuccess])
  useEffect(() => {
    if (getSubhostLearners.data && getSubhostLearners.isSuccess) {
        setSubhostsLearners(getSubhostLearners.data)
    }
  }, [getSubhostLearners.data, getSubhostLearners.isSuccess])

//   const {account_activated, created_at, email, email_verified, image,is_active, is_admin, is_client, is_host, is_learner, last_login, name, password, phone, phone_verified, tc, updated_at} = userData

//   const { bio , date_of_birth, department, client, academy, qualification, research_interests, address, region, report_status} = subhostData

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const handleSubmit = () => {}

  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const fileInput = document.getElementById('idFile');
        // console.log("fileInput", id_upload)
        fileInput.innerHTML =id_upload; // This line will trigger the error.
  };

  const UserProfileDetails = () => {
    const { control, handleSubmit, setValue } = useForm()

    const onSubmit = async (data) => {
      console.log("data", data);
      console.log("data", data);
      const res = await updateUserDetails({id, data, accessToken});
      if (res.error) {
        // setServerError(res.error.data.errors);
        // console.log(res.error);
        setUpdateError(res.error.data.errors);
      }
      if (res.data) {
        // console.log(typeof res.data);
        // console.log(res.data);
        window.location.reload();
      }
    };

    if (getSubhost.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Upload Data was found. Please Inform Learner to update...
        </Typography>
      )
    }else if (getSubhost.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (getSubhost.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Personal Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                <Controller 
                  control={control}
                  name="name"
                  defaultValue={subhostData?.user?.name}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField  {...field} fullWidth  margin='normal' id="name" name="name" /> 
              )}
              />
                 <Typography sx={{ color: 'text.secondary', mt:2  }}>Email</Typography>
                <Controller 
                  control={control}
                  name="email"
                  defaultValue={subhostData?.user?.email}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                // <TextField value={field.email} fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }} onChange={(e) => setField({...field, email: e.target.value})}/>
                <TextField  {...field}  fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }}/>
              )}
              />
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Phone</Typography>
                <Controller 
                  control={control}
                  name="phone"
                  defaultValue={subhostData?.user?.phone}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField {...field}   fullWidth id="phone" name="phone" InputLabelProps={{ shrink: true, }} />
              )}
              />
                <Box textAlign='center'>
                    <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                </Box>
                {updateError.non_field_errors ? <Alert severity='error'>{updateError.non_field_errors[0]}</Alert> : ''}
                </form>
            </AccordionDetails>
        </Accordion>
      )
    }
  }
  const PersonalDetails = () => {
    if (getSubhost.isError) {
        return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No  Data was found. Please Inform  to update...
          </Typography>
        )
      }else if (getSubhost.isLoading){
        return (
          <p>Loading...</p>
        )
      }
      else if (getSubhost.isSuccess){
        return (
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Personal Details
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                
                    {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Title</Typography>
                    <TextField fullWidth value={subhostData.bio} margin='normal' id="title" name="title"  onChange={(e) => setBio(e.target.value)}>
                    </TextField> */}

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Data of Birth:</Typography>
                    <TextField value={subhostData.date_of_birth} type="date" required fullWidth id='date_of_birth' name='date_of_birth' onChange={(e) => setDob(e.target.value)}/>

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Department</Typography>
                    <TextField value={subhostData.department} fullWidth id="department" name="department" InputLabelProps={{ shrink: true, }} onChange={(e) => setDepartment(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Client</Typography>
                    <TextField value={subhostData.client} fullWidth id="client" name="client" InputLabelProps={{ shrink: true, }} onChange={(e) => setClient(e.target.value)} />

                    {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Academy</Typography>
                    <TextField value={subhostData.academy} required fullWidth id='academy' name='academy' onChange={(e) => setAcademy(e.target.value)}/> */}

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Qualification</Typography>
                    <TextField fullWidth value={subhostData.qualification} id="qualification" name="qualification" onChange={(e) => setQualification(e.target.value)}/>

                    {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Research Interests</Typography>
                    <TextField fullWidth value={subhostData.research_interests} id="research_interests" name="research_interests"onChange={(e) => setSearchInterests(e.target.value)}/> */}

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Address</Typography>
                    <TextField id="address" value={subhostData.address} required fullWidth onChange={(e) => setGender(e.target.value)} />

                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Region</Typography>
                    <TextField id="region" value={subhostData.region} required fullWidth onChange={(e) => setGender(e.target.value)} />

                    {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Report Status</Typography>
                    <TextField id="report_status" value={subhostData.report_status} required fullWidth onChange={(e) => setGender(e.target.value)} /> */}
                       
                            {/* <Box textAlign='center'>
                                <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                            </Box> */}
                </AccordionDetails>
            </Accordion>
        )
      }
  }
  const SubhostsLearners = () => {
    if (getSubhostLearners.isError) {
        return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No Upload Data was found. Please Inform Learner to update...
          </Typography>
        )
      }else if (getSubhostLearners.isLoading){
        return (
          <p>Loading...</p>
        )
      }
      else if (getSubhostLearners.isSuccess){
        return (
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel2bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Learners Details
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}> Learners details: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* LearnersList: {hostsLearners.map((Learners) => (<p key={Learners.id} value={Learners.id}>{Learners.user}</p>))} */}
                    <List>
                        {subhostLearners.map((Learners) => (
                            <ListItem key={Learners.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={Learners.id} secondary={Learners.user.email} sx={{ opacity: open ? 1 : 0 }} >        </ListItemText>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        )
    }
  }

  return (<>
  <Grid container>
    <Grid item sm={10}  mb={4} mx="auto">
    <CustomBreadcrumbs heading="Profile" links={[
          { name: 'Dashboard', href: "/adminpage" },
          { name: 'User', href: "/adminpage/myProfile" },
          // { name: user.displayName },
          { name: subhostData?.user?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <Card sx={{ mb: 3, height: 290, }} > 
        <ProfileCover 
          // role={name}
          // name={name}
          avatarUrl={`http://127.0.0.1:8000/${subhostData?.user?.image}`}
          // coverUrl="https://source.unsplash.com/random?wallpapers"
          coverUrl="/assets/profile-header.jpg"
          />
          <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>
      {currentTab === 'profile' && <UserProfileDetails />}
      {currentTab === 'personal' && <PersonalDetails />} 
      {/* {/* {currentTab === 'professional' && <ProfessionalDetails />} */}
      {currentTab === 'learners' && <SubhostsLearners />}  

        
    
        </Grid>
      </Grid>
    </>)
}

export default SubHostDetails