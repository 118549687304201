// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/adminpage/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Learners',
    path: '/adminpage/learners',
    icon: icon('ic_user'), 
  },
  {
    title: 'Hosts',
    path: '/adminpage/hosts',
    icon: icon('ic_blog'),
  },  
  {
    title: 'SubHosts',
    path: '/adminpage/subhosts',
    icon: icon('ic_blog'),
  },
  {
    title: 'Clients',
    path: '/adminpage/clients',
    icon: icon('ic_user'),
  }, 
  {
    title: 'Projects',
    path: '/adminpage/projects',
    icon: icon('ic_user'),
  }, 
  {
    title: 'Academies',
    path: '/adminpage/academy',
    icon: icon('ic_user'),
  },
  
  {
    title: 'User Requests',
    path: '/adminpage/userRequests',
    icon: icon('ic_lock'),
  },
  {
    title: 'User Groups',
    path: '/adminpage/userGroups',
    icon: icon('ic_lock'),
  }, 
  {
    title: 'Courses',
    path: '/adminpage/courses',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'Enroll Requests',
  //   path: '/adminpage/requests',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'blog',
  //   path: '/adminpage/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
