/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Alert, Box, Button, Checkbox, CircularProgress, IconButton, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Stack, TextField, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuizIcon from '@mui/icons-material/Quiz';
import { getToken } from '../services/LocalStorageService'
import { useCreateQuizMutation, useCreateResourseMutation, useGetQuizPerModuleQuery, useGetResourcePerModuleQuery, useGetVideosPerModuleQuery, useUploadVideosMutation } from '../services/coursesApi'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UpdateModule = () => {
    const { id, slug } = useParams();
    const { accessToken } = getToken();
    const [videoList, setVideoList] = useState([])
    // console.log("🚀 ~ UpdateModule ~ videoList:", videoList)
    const [resourceList, setResourseList] = useState([])
    // console.log("🚀 ~ UpdateModule ~ resourceList:", resourceList)
    const [quizList, setQuizList] = useState([])
    // console.log("🚀 ~ UpdateModule ~ quizList:", quizList)
    const [checked, setChecked] = useState([0]);

    const [name, setName] = useState( )
    const [videoFile, setVideoFile] = useState('')
    const [url, setUrl] = useState( )
    const [resourceFile, setResourceFile] = useState('')

    const [openResource, setOpenResource] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    const [resourceError, setResourceError] = useState({});
    const [videoError, setVideoError] = useState({});
    const [quizError, setQuizError] = useState({});

    const getVideosPerModule = useGetVideosPerModuleQuery({id, slug, accessToken})
    const getResourcePerModule = useGetResourcePerModuleQuery({id, slug, accessToken})
    const getQuizPerModule = useGetQuizPerModuleQuery({id, slug, accessToken})

    const [createResourse] = useCreateResourseMutation()
    const [uploadVideos] = useUploadVideosMutation()
    const [createQuiz, {isLoading}]  = useCreateQuizMutation()

    // Storing data in local state 
    useEffect(() => {
      if(getVideosPerModule.data && getVideosPerModule.isSuccess){
        setVideoList(getVideosPerModule.data)
      }
    }, [getVideosPerModule.data, getVideosPerModule.isSuccess])

    useEffect(() => {
      if(getResourcePerModule.data && getResourcePerModule.isSuccess){
        setResourseList(getResourcePerModule.data)
      }
    }, [getResourcePerModule.data, getResourcePerModule.isSuccess])

    useEffect(() => {
      if(getQuizPerModule.data && getQuizPerModule.isSuccess){
        setQuizList(getQuizPerModule.data)
      }
    }, [getQuizPerModule.data, getQuizPerModule.isSuccess])

    const handleResourceOpen = () => setOpenResource(true)
    const handleResourceClose = () => setOpenResource(false)

    const handleVideoOpen = () => setOpenVideo(true)
    const handleVideoClose = () => setOpenVideo(false)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddResource = async (e) => {
    e.preventDefault();
    const actualData = new FormData();
      actualData.append("slug", slug)
      actualData.append("id", id)
      actualData.append("file", resourceFile)
      actualData.append("url", url) 
       
   
  const res = await createResourse({actualData, accessToken});
   if (res.error) {
        // console.log(typeof (res.error.data.errors))
        console.log(res.error)
       //  console.log(res.error.data.errors) 
        setResourceError(res.error.data.errors)
      }
    if (res.data) {
    console.log("data", res.data)
    //  console.log("key", res.data.session_key)
    handleResourceClose();
    window.location.reload();
    // navigate('/dashboard')
    }
  }

  const handleAddVideo = async (e) => {
    e.preventDefault();
    // console.log("add video", name)
    const actualData = new FormData();
      actualData.append("slug", slug)
      actualData.append("id", id)
      actualData.append("name", name)
      actualData.append("video", videoFile)
      // console.log(actualData)
    const res = await uploadVideos({actualData, accessToken})
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log(res.error)
     //  console.log(res.error.data.errors) 
      setVideoError(res.error.data.errors)
    }
  if (res.data) {
  console.log("data", res.data)
  //  console.log("key", res.data.session_key)
  handleVideoClose();
  window.location.reload();
  // navigate('/dashboard')
  }
  }

  const handleAddQuiz = async (e) => {
    e.preventDefault();
    const actualData = {
        slug: slug,
        id:id
    }
    const res = await createQuiz({actualData, accessToken});
    if (res.error) {
        // console.log(typeof (res.error.data.errors))
       //  console.log(res.error)
       //  console.log(res.error.data.errors) 
        setQuizError(res.error.data.errors)
      }
    if (res.data) {
    console.log("data", res.data)
    //  console.log("key", res.data.session_key)
    // handleClose();
    window.location.reload();
    // navigate('/dashboard')
    }
  }

  // Sections here 

  const ResourceSection = () => {
    if (getResourcePerModule.isError) { 
      return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No Resources were found. Please update...
          </Typography>
      )
      }else if (getResourcePerModule.isLoading){
      return (
          <p>Loading...</p>
      )
      }
      else if (getResourcePerModule.isSuccess){
       
      //  else{
        // if (resourceList?.length === 0){
        //   <Typography variant='h6' sx={{ flexShrink: 0 }}>
        //                No Resources were added in this module yet...
        //     </Typography>
        //  }
      return(
        <>
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      Resources 
          </Typography>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {resourceList.map((item, value, index) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Link to={item.url} target='_blank' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <IconButton edge="end" aria-label="comments">
                      <OpenInNewIcon />
                    </IconButton>
                    </Link>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value)}  >
                    <ListItemIcon>
                      <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`Resource ${value + 1}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={handleResourceOpen}> Add Resources</Button>
            </Box>
        </>
      )
    // }
    }
  } 
    
  const VideoSection = () => {
      if (getVideosPerModule.isError) { 
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Videos were found. Please update...
            </Typography>
        )
        }else if (getVideosPerModule.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (getVideosPerModule.isSuccess){
      return(
        <>
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      Videos 
          </Typography>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {videoList.map((item, value, index) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Link to={`http://127.0.0.1:8000/${item.video}`} target='_blank' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <IconButton edge="end" aria-label="comments">
                      <OpenInNewIcon />
                    </IconButton>
                    </Link>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <OndemandVideoIcon />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={item.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={handleVideoOpen}> Add Videos</Button>
          </Box>
        </>
      )
    }
  } 
  
  const QuizSection = () => {
      if (getQuizPerModule.isError) { 
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Quizes were found. Please update...
            </Typography>
        )
        }else if (getQuizPerModule.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (getQuizPerModule.isSuccess){
      return(
        <>
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      Quizes 
          </Typography>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {quizList.map((item, value, index) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Link to={`/adminpage/editquiz/${item.id}`} target='_blank' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <IconButton edge="end" aria-label="comments">
                      <OpenInNewIcon />
                    </IconButton>
                    </Link>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <QuizIcon />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`Quiz ${value + 1}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box textAlign="center">
            {isLoading ? <CircularProgress /> : <Button variant="contained" color="primary" onClick={handleAddQuiz}> Add Quiz</Button>}
          </Box>
          {quizError.non_field_errors ? <Alert severity='error'>{quizError.non_field_errors[0]}</Alert> : ''}

        </>
      )
    }
  }

  return (
    <> 
    <ResourceSection/>
    <hr/>
    <VideoSection/>
    <hr/>
    <QuizSection/>

    {/* Add Resource modal */}
    <Modal
        component="form"
        open={openResource}
        onClose={handleResourceClose}
        id='add-resource-form' 
        onSubmit={handleAddResource}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box
         
         noValidate
         sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Resource
          </Typography>
          {/* <label htmlFor="phoneOtp" className="col-form-label">OTP on Phone:</label>
          <input type="text" className="form-control" id="phoneOtp" name="phoneOtp" /> */}
          {/* <TextField margin='normal' required fullWidth id='phoneOtp' name='phoneOtp' label='Phone OTP'  onChange={(phoneOtp) => {setPhone(phoneOtp)}} /> */}
          <TextField margin='normal' required fullWidth id='url' name='url' label='Resource Link' onChange={(e) => setUrl(e.target.value)} />
          {resourceError.url ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{resourceError.url[0]}</Typography> : ""}
          {/* <label htmlFor="emailOtp" className="col-form-label">OTP on Email:</label>
          <input type="text" className="form-control" id="emailOtp" name="emailOtp"/>  */}
          <Stack direction="row" alignItems="center" spacing={4} py={2}>
            <Typography margin='normal' style={{width: "50%"}}>Upload File(optional)</Typography>
            <Input accept="*" id="file" type="file" onChange={(e) => { setResourceFile(e.target.files[0]) }} />
        </Stack>
          {resourceError.file ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{resourceError.file[0]}</Typography> : ""}
          <Box textAlign='center'>
            <Button onClick={handleResourceClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 2,  }}>Cancel</Button>
            <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>Add</Button>
          </Box>
          {resourceError.non_field_errors ? <Alert severity='error'>{resourceError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>

      {/* Add Video Modal */}
      <Modal
        component="form"
        open={openVideo}
        onClose={handleVideoClose}
        id='add-video-form' 
        onSubmit={handleAddVideo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box
         
         noValidate
         sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Video
          </Typography>
          {/* <label htmlFor="phoneOtp" className="col-form-label">OTP on Phone:</label>
          <input type="text" className="form-control" id="phoneOtp" name="phoneOtp" /> */}
          <TextField margin='normal' required fullWidth id='name' name='name' label='Name of the Video' onChange={(e) => setName(e.target.value)}  />
          {videoError.name ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{videoError.name[0]}</Typography> : ""}
          {/* <label htmlFor="emailOtp" className="col-form-label">OTP on Email:</label>
          <input type="text" className="form-control" id="emailOtp" name="emailOtp"/>  */}
          <Stack direction="row" alignItems="center" spacing={4} py={2}>
            <Typography margin='normal' style={{width: "50%"}}>Upload Video</Typography>
            <Input accept="*" id="video" type="file" onChange={(e) => { setVideoFile(e.target.files[0]) }} />
        </Stack>
          {videoError.video ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{videoError.video[0]}</Typography> : ""}
          <Box textAlign='center'>
            <Button onClick={handleVideoClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 2,  }}>Cancel</Button>
            <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>Add</Button>
          </Box>
          {videoError.non_field_errors ? <Alert severity='error'>{videoError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>
    </>
  )
}

export default UpdateModule 