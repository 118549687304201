/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
    Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Skeleton, Grid, List, ListItem, ListItemText, ListItemAvatar, Modal, Box, TextField, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, OutlinedInput, Select, DialogActions, Input,
    Alert,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux"
import { setUserInfo } from "../features/userSlice"
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from "../services/LocalStorageService"
import { useGetProjectListQuery } from "../services/coursesApi"

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import {
  useAssignHostMutation,
    useAssignProjectMutation,
    useCreateHostMutation,
    useCreateLearnerMutation,
    useGetAllUsersQuery,
    useGetHostListQuery,
    useGetLoggedUserQuery,
    useGetSubhostsLearnersQuery,
    useImportUsersMutation,
    useSubhostsLearnersQuery,
} from "../services/userAuthApi"
import currentNetwork from '../_mock/network';
import { TITLE, DISABILITY, COUNTRY_CHOICES, EMPLOYMENT } from "../_mock/dropdowns";

  const createLearnerStyle = {
    position: 'absolute',
    overflow: "hidden",
    overflowY: "scroll",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 43,
    p: 4,
    m: 2,
    
  
  };
 
 const TABLE_HEAD = [
     { id: 'name', label: 'Name', alignRight: false },
     { id: 'phone', label: 'Phone', alignRight: false },
     { id: 'email', label: 'Email', alignRight: false },
    //  { id: 'Host', label: 'Host', alignRight: false },
    //  { id: 'verified', label: 'Verified', alignRight: false },
     { id: 'assignProject', label: 'AssignProject', alignRight: false },
     { id: 'assignHost', label: 'AssignHost', alignRight: false },
     { id: '' },
   ];
   
   function descendingComparator(a, b, orderBy) {
     if (b[orderBy] < a[orderBy]) {
       return -1;
     }
     if (b[orderBy] > a[orderBy]) {
       return 1;
     }
     return 0;
   }
   
   function getComparator(order, orderBy) {
     return order === 'desc'
       ? (a, b) => descendingComparator(a, b, orderBy)
       : (a, b) => -descendingComparator(a, b, orderBy);
   }
   
   function applySortFilter(array, comparator, query) {
     const stabilizedThis = array.map((el, index) => [el, index]);
     stabilizedThis.sort((a, b) => {
       const order = comparator(a[0], b[0]);
       if (order !== 0) return order;
       return a[1] - b[1];
     });
     if (query) {
       return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
     }
     return stabilizedThis.map((el) => el[0]);
   }
   const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
 
const SubhostLearners = () => {
  const [learners, setLearners] = useState([])
  // console.log("🚀 ~ file: AllLearners.js:104 ~ learners:", learners)
  const [createHost] = useCreateHostMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { accessToken } = getToken()
  const [open, setOpen] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [qualification, setQualification] = useState("");

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [learnerforHost, setLearnerforHost] = useState("");

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false)
  const [importOpen, setImportOpen] = useState(false);
  const [importFile, setImportFile] = useState('')

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [projectList, setProjectList] = useState([])
  const [hostList, setHostsList] = useState([])
  const [clientsLearners, setClientsLearners] = useState([])

  const getAllUsers = useGetAllUsersQuery(accessToken)
  const myHosts = useGetHostListQuery(accessToken)

  const [createLearner] = useCreateLearnerMutation()
  const [importUsers, {isLoading}] = useImportUsersMutation()
  const [assignProject] = useAssignProjectMutation()
  const [assignHost] = useAssignHostMutation()
  

  const allProjects = useGetProjectListQuery()
  const getSubhostsLearners = useGetSubhostsLearnersQuery( accessToken)
  useEffect(() => {
    if (getSubhostsLearners.data && getSubhostsLearners.isSuccess) {
        setClientsLearners(getSubhostsLearners.data)
        setLearners(getSubhostsLearners.data)
    }
  }, [getSubhostsLearners.data, getSubhostsLearners.isSuccess])


  useEffect(() => {
    if(allProjects.data && allProjects.isSuccess){
      setProjectList(allProjects.data)
    }
  }, [allProjects.data, allProjects.isSuccess])

  useEffect(() => {
    if(myHosts.data && myHosts.isSuccess){
      setHostsList(myHosts.data)
    }
  }, [myHosts.data, myHosts.isSuccess])

  // useEffect(() => {
  //   if (getAllUsers.data && getAllUsers.isSuccess) {
  //     var learnerData = getAllUsers.data.filter((curElem) => {
  //       return curElem.is_learner === true;
  //       })
  //       setLearners(learnerData)
  //   }
  // }, [getAllUsers.data, getAllUsers.isSuccess]) 

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);  
  
  const handleImportOpen = () => setImportOpen(true);
  const handleImportClose = () => setImportOpen(false);
  
  const [assignOpen, setAssignOpen] = useState(false);
  const [hostOpen, setHostOpen] = useState(false);
  const [project, setProject] = useState('');
  const [host, setHost] = useState('');

  const [dateofBirth, setDob] = useState(null)
  const [title, setTitle] = useState("None")
  const [citizenship, setCitizenship] = useState("None of these")
  const [disability, setDisability] = useState("None of these")
  const [employmentStatus, setEmployment] = useState("None of these")

  const handleChange = (event) => {
    // setAge(Number(event.target.value) || '');
    setProject(event.target.value);
    console.log(project)
  };

  const handleHostChange = (event) => {
    // setAge(Number(event.target.value) || '');
    setHost(event.target.value);
    console.log(host)
  };

  const handleClickOpen = (e, id) => {
    setSelectedUser(id)
    setAssignOpen(true);
  };  
  
  const handleHostOpen = (e, id) => {
    setLearnerforHost(id)
    setHostOpen(true);
  };

  const handleAssignClose= (event, reason) => {
    if (reason !== 'backdropClick') {
        setAssignOpen(false);
    }
  };

  const handleHostClose= (event, reason) => {
    if (reason !== 'backdropClick') {
        setHostOpen(false);
    }
  };

  const handleProjectAssigned = async (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append('learner', selectedUser) 
    data.append('project', project) 
    console.log('handleProjectAssigned', project, selectedUser)
    const res = await assignProject({data, accessToken})
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log("error",res.error)
    //   setOtpError(res.error.data.errors)
    }
    if (res.data) {
      // console.log(typeof (res.data))
      console.log("data", res.data)
      // console.log("token", res.data.token)
      setAssignOpen(false);
      
    }
  }
  const handleHostAssigned = async (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append('learner', learnerforHost) 
    data.append('host', host) 
    console.log('handleHostAssigned', project, learnerforHost)
    const res = await assignHost({data, accessToken})
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log("error",res.error)
    //   setOtpError(res.error.data.errors)
    }
    if (res.data) {
      // console.log(typeof (res.data))
      console.log("data", res.data)
      // console.log("token", res.data.token)
      setHostOpen(false);
      
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen()
  }
  
  const handleImport = () => {
    handleImportOpen()
  }

  const handleImportSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append('file', importFile) 
    // console.log("🚀 ~ file: ListLearners.js:243 ~ handleImportSubmit ~ importFile:", importFile)
    
    const res = await importUsers({ data, accessToken })
    if (res.data) {
      console.log("data", res.data)
        // setError({ status: true, msg: "Files Uploaded Successfully", type: 'success' })
        // resetForm()
        // navigate("/requestPending")
    }else if (res.error) {
      console.log("error1", res.error)
      // setError({ status: true, msg: res.error.data.errors, type: 'error' })
    }else  {
      console.log("error2", res.error)
      // setError({ status: true, msg: "All Fields are Required", type: 'error' })
    }
  }
  const submitLearner = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
  
    const actualData = {
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone'),
      title: data.get('title'),
      id_number: data.get('id_number'),
      date_of_birth: data.get('phone'),
      ethnicity: data.get('ethnicity'),
      citizenship: data.get('citizenship'),
      disability: data.get('disability'),
      gender: data.get('gender'),
      tax_number: data.get('tax_number'),
      
      employment_status: data.get('employment_status'),
      home_language: data.get('home_language'),
      street_address: data.get('street_address'),
      residential_area: data.get('residential_area'),
      city: data.get('city'),
      province: data.get('province'),
      postal_code: data.get('postal_code'),
      postal_address: data.get('postal_address'),
      emergency_number: data.get('emergency_number'),
      whatsapp_number: data.get('whatsapp_number'),
      last_school: data.get('last_school'),
      last_school_year: data.get('last_school_year'),
    }
    console.log(actualData)
    const res = await createLearner({actualData, accessToken})
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log("error",res.error)
      setServerError(res.error.data.errors)
    }
    if (res.data) {
      console.log(typeof (res.data))
      console.log("data", res.data)
      // console.log("token", res.data.token)
      
      handleClose()
      window.location.reload();
      // navigate('/academy/user')
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

 if (getSubhostsLearners.isLoading) {
     ;<Stack spacing={1}>
         <h1 className="mt-4">Loading</h1>
         {/* For variant="text", adjust the height via font-size */}
         <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
         {/* For other variants, adjust the size with `width` and `height` */}
         <Skeleton variant="circular" width={40} height={40} />
         <Skeleton variant="rectangular" width={210} height={60} />
         <Skeleton variant="rounded" width={210} height={60} />
     </Stack>
 }
  
//  if (getAllUsers.isLoading){
//    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
//  }  
  
//  if (getAllUsers.isError){
//    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No User Data Found...</h1>)
//  }else{
  return (
      <>
    <Helmet>
      <title> Learners | Y-Plan </title>
    </Helmet>

    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>Learners</Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} spacing={2}>

            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>Add Learner</Button>  
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleImport}>Import Learners</Button>  
        </Stack>
      </Stack>
      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={learners.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                {learners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                  const {id, user, host } = row;
                  // const isActive = String(user.is_active)
                  const accountActivated = String(user.account_activated)
                  const selectedUser = selected.indexOf(name) !== -1;
                    if (user.is_admin){
                      var role = 'Admin';
                    }else if(user.is_learner){
                      var role =  "Learner";
                    }else if(user.is_host){
                      var role =  "Host";
                    }
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                      </TableCell>

                      {/* <NavLink to={`http://${currentNetwork}/clientpage/learners/${id}`}> */}
                      
                      <TableCell component="th" scope="row" >
                      <NavLink to={`/subhost/learners/${user.id}`}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={user.name} src={user.image} />
                          <Typography variant="subtitle2" sx={{color: "black"}} noWrap>
                            {user.name}
                          </Typography>
                        </Stack></NavLink>
                      </TableCell>

                      <TableCell align="left">{user.phone}</TableCell>

                      <TableCell align="left">{user.email}</TableCell>
        
                      {/* <TableCell align="left">{host}</TableCell> */}

                      {/* <TableCell align="left">
                        <Label color={(accountActivated === 'banned' && 'error') || 'success'}>{sentenceCase(accountActivated)}</Label>
                      </TableCell> */}
                      <TableCell align="left">
                      <Button onClick={(e) => handleClickOpen(e, user.id)}>Assign Project</Button>
                      </TableCell>
                      <TableCell align="left">
                      {host ? host: <Button onClick={(e) => handleHostOpen(e, id)}>Assign Host</Button>}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {getSubhostsLearners.isError && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={learners.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>

    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      
      <MenuItem>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Edit
      </MenuItem>

      <MenuItem sx={{ color: 'error.main' }}>
        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
        Delete
      </MenuItem>
    </Popover>


     {/* Create Learner Modal  */}
     <Modal
      component="form"
      open={openOTP}
      onClose={handleClose}
      id='otp-form' 
      onSubmit={submitLearner}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // style={{backgrou}}
    >
      <Box
      
      noValidate
      sx={createLearnerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a Learner 
        </Typography>
        
        <Stack direction="row" spacing={2}> 
        <TextField required fullWidth id='name' name='name' label='Name'  />
        {serverError.name ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography> : ""} 

        <TextField required fullWidth id='email' name='email' label='Email' />
        {serverError.email ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography> : ""}
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        <TextField required fullWidth id='phone' name='phone' label='Phone' />
        {serverError.phone ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography> : ""}

        <TextField fullWidth margin='normal' id="title" select name="title" label="Title" SelectProps={{ native: true, }} onChange={(e) => setTitle(e.target.value)}>
       {TITLE.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>
        </Stack>      

        <Stack direction="row" spacing={2} mt={2}> 
        <TextField required fullWidth id='id_number' name='id_number' label='Id Number'  />
        {serverError.id_number ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography> : ""} 

        <TextField margin='normal' fullWidth label="Select a Date" id="date_of_birth" name="Date of Birth" type="date" InputLabelProps={{ shrink: true, }} />
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        <TextField required fullWidth id='ethnicity' name='ethnicity' label='Ethnicity' />
        {serverError.ethnicity ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.ethnicity[0]}</Typography> : ""}

        <TextField fullWidth margin='normal' id="citizenship" select name="citizenship" label="Citizenship" SelectProps={{native: true, }} onChange={(e) => setCitizenship(e.target.value)}>          
        {COUNTRY_CHOICES.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>
        </Stack>

        <Stack direction="row" spacing={2} mt={2}> 
        <TextField fullWidth  id="disability" select name="disability" label="Disability" SelectProps={{native: true, }} onChange={(e) => setDisability(e.target.value)}>          
        {DISABILITY.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>

        <TextField required fullWidth id='gender' name='gender' label='gender' />
        {serverError.gender ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.gender[0]}</Typography> : ""}
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        <TextField required fullWidth id='tax_number' name='tax_number' label='tax_number' />
        {serverError.tax_number ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.tax_number[0]}</Typography> : ""}
        <TextField required fullWidth id='whatsapp_number' name='whatsapp_number' label='whatsapp_number' />
        {serverError.whatsapp_number ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.whatsapp_number[0]}</Typography> : ""}         
        </Stack>   
        
        <Stack direction="row" spacing={2} mt={2}> 
        <TextField required fullWidth id='home_language' name='home_language' label='home_language'  />
        {serverError.home_language ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.home_language[0]}</Typography> : ""} 

        <TextField required fullWidth id='street_address' name='street_address' label='street_address' />
        {serverError.street_address ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.street_address[0]}</Typography> : ""}
        </Stack>
        
        <Stack direction="row" spacing={2} mt={2}>
        <TextField required fullWidth id='residential_area' name='residential_area' label='Residential Area' />
        {serverError.residential_area ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.residential_area[0]}</Typography> : ""}

        <TextField required fullWidth id='city' name='city' label='City' />
        {serverError.city ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.city[0]}</Typography> : ""}
        </Stack>

        <Stack direction="row" spacing={2} mt={2}> 
        <TextField required fullWidth id='province' name='province' label='Province'  />
        {serverError.province ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.province[0]}</Typography> : ""} 

        <TextField required fullWidth id='postal_code' name='postal_code' label='Postal Code' />
        {serverError.postal_code ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.postal_code[0]}</Typography> : ""}
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        <TextField required fullWidth id='postal_address' name='postal_address' label='postal_address' />
        {serverError.postal_address ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.postal_address[0]}</Typography> : ""}

        <TextField required fullWidth id='emergency_number' name='emergency_number' label='Emergency Number' />
        {serverError.emergency_number ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.emergency_number[0]}</Typography> : ""}
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        

        <TextField required fullWidth id='last_school' name='last_school' label='Last School' />
        {serverError.last_school ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.last_school[0]}</Typography> : ""}
        <TextField required fullWidth id='last_school_year' name='last_school_year' label='Last School Year' />
        {serverError.last_school_year ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.last_school_year[0]}</Typography> : ""}

        
        </Stack>

        <Stack direction="row" spacing={2} mt={2}>
        <TextField fullWidth margin='normal' id="employment_status" select name="employment_status" label="Employment Status"  SelectProps={{native: true, }} onChange={(e) => setEmployment(e.target.value)}>          
       {EMPLOYMENT.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>
        </Stack>  
        

        <Box textAlign='right'>
          <Button onClick={handleClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 4,  }}>Cancel</Button>
          <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 4, mx: 2 }}>Create Learner</Button>
        </Box>
        {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
      </Box>
    </Modal>

    {/* Assign Project Box */}
    <Modal
    component="form"
    open={assignOpen}
    onClose={handleAssignClose}
    id='assign-project-form' 
    onSubmit={handleProjectAssigned}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    // style={{backgrou}}
  >
    <Box
    
    noValidate
    sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      Assign Project
      </Typography>

      {/* <Stack direction="row" alignItems="center" spacing={4} py={2}>
        <Typography style={{width: "50%"}}>Import Learner</Typography>
        <Input accept="*" id="import_learners" type="file" onChange={(e) => { setImportFile(e.target.files[0]) }} />
        </Stack> */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="demo-dialog-native">Projects</InputLabel>
            <Select
              native
              value={project}
              onChange={handleChange}
              input={<OutlinedInput label="Projects" id="demo-dialog-native" />}
            >
              <option aria-label="None" value="" />
            {projectList.map((project) => (<option key={project.id} value={project.id}>{project.name}</option>))}

            </Select>
          </FormControl>
      <Box textAlign='center'>
        <Button onClick={handleAssignClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 5,  }}>Cancel</Button>
        <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>Assign</Button>
      </Box>
      {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
    </Box>
    </Modal>
    
    {/* Assign Host Box */}
    <Modal
    component="form"
    open={hostOpen}
    onClose={handleHostClose}
    id='assign-host-form' 
    onSubmit={handleHostAssigned}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    // style={{backgrou}}
  >
    <Box noValidate sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      Assign Host
      </Typography>

      {/* <Stack direction="row" alignItems="center" spacing={4} py={2}>
        <Typography style={{width: "50%"}}>Import Learner</Typography>
        <Input accept="*" id="import_learners" type="file" onChange={(e) => { setImportFile(e.target.files[0]) }} />
        </Stack> */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="demo-dialog-native">Hosts</InputLabel>
            <Select
              native
              value={host}
              onChange={handleHostChange}
              input={<OutlinedInput label="Projects" id="demo-dialog-native" />}
            >
              <option aria-label="None" value="" />
            {hostList.map((host) => (<option key={host.id} value={host.id}>{host.user}</option>))}

            </Select>
          </FormControl>
      <Box textAlign='center'>
        <Button onClick={handleHostClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 5,  }}>Cancel</Button>
        <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>Assign</Button>
      </Box>
      {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
    </Box>
    </Modal>
     

       {/* Import Learners Modal  */}
    <Modal
      component="form"
      open={importOpen}
      onClose={handleImportClose}
      id='otp-form' 
      onSubmit={handleImportSubmit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // style={{backgrou}}
    >
      <Box
      
      noValidate
      sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Import Learners
        </Typography>

        <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography style={{width: "50%"}}>Import Learner</Typography>
         <Input accept="*" id="import_learners" type="file" onChange={(e) => { setImportFile(e.target.files[0]) }} />
         </Stack>
        <Box textAlign='center'>
          <Button onClick={handleImportClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 5,  }}>Cancel</Button>
          <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>Import</Button>
        </Box>
        {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
      </Box>
    </Modal>
  </>
  )
}

 
 
export default SubhostLearners
 