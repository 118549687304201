// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const sponsorNav = [
  {
    title: 'dashboard',
    path: '/clientpage/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'courses',
    path: '/clientpage/courses',
    icon: icon('ic_cart'),
  },
]

const hostingNav = [
  {
    title: 'dashboard',
    path: '/clientpage/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'hosts',
    path: '/clientpage/hosts',
    icon: icon('ic_lock'),
  },
  {
    title: 'courses',
    path: '/clientpage/courses',
    icon: icon('ic_cart'),
  },  
  {
    title: 'billing',
    path: '/clientpage/billing',
    icon: icon('ic_cart'),
  },  
  {
    title: 'stipends',
    path: '/clientpage/stipends',
    icon: icon('ic_cart'),
  },
]


const navConfig = [
  {
    title: 'dashboard',
    path: '/clientpage/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'My Learners',
    path: '/clientpage/learners',
    icon: icon('ic_user'),
  },
  {
    title: 'courses',
    path: '/clientpage/courses',
    icon: icon('ic_cart'),
  },
  {
    title: 'Billings And Wages',
    path: '/clientpage/blog',
    icon: icon('ic_user'),
  }, {
    title: 'Projects',
    path: '/clientpage/projects',
    icon: icon('ic_user'),
  }, 
  {
    title: 'hosts',
    path: '/clientpage/hosts',
    icon: icon('ic_user'),
  },
  
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
// export {sponsorNav, hostingNav, projectNav}
