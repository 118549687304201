import * as React from 'react';
import { Box, Button, Typography, Alert, Stack,  CircularProgress, Input } from '@mui/material';
import {  useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUploadDetailsMutation } from '../services/userAuthApi'
import { getToken } from '../services/LocalStorageService'



const UploadForm = ({id}) => {
    const [idUpload, setID] = useState('')
    const [cdUpload, setCV] = useState('')
    const [bankConfirmationUpload, setBank] = useState('')
    const [highestEducationCertificate, setEduCertificate] = useState('')
    const [sarsDocumentUpload, setSars] = useState('')
    const [disabilityDocument, setDisabilityDocument] = useState('')
    const [specialPowerAttorney, setSPA] = useState('')
    const navigate = useNavigate()
    const [uploadedData, { isLoading }] = useUploadDetailsMutation()
    const {accessToken} = getToken()
 
    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })

    const resetForm = () => {
        setID('')
        setCV('')
        setBank('')
        setEduCertificate('')
        setSars('')
        setDisabilityDocument('')
        setSPA('')
        document.getElementById('profileDetails-form').reset()
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('user', id)
        data.append('id_upload', idUpload)
        data.append('cv_upload', cdUpload)
        data.append('bank_confirmation_upload', bankConfirmationUpload)
        data.append('highest_education_certificate', highestEducationCertificate)
        data.append('sars_document_upload', sarsDocumentUpload)
        data.append('disability_document', disabilityDocument)
        data.append('special_power_attorney', specialPowerAttorney)
        
        
    // console.log("file", specialPowerAttorney)
    // console.log(idUpload, cdUpload, highestEducationCertificate) 
    if (idUpload && cdUpload && highestEducationCertificate) {  
      const res = await uploadedData({ data, accessToken })
      // console.log(res)
      // console.log(res.data)
      // console.log(res.error)
      if (res.data) {
        // console.log("data")
          setError({ status: true, msg: "Files Uploaded Successfully", type: 'success' })
          // resetForm()
          // navigate("/requestPending")
      }else if (res.error) {
        // console.log("error1", res.error)
        setError({ status: true, msg: res.error.data.errors, type: 'error' })
      }else  {
        // console.log("error2", res.error)
        setError({ status: true, msg: "All Fields are Required", type: 'error' })
      }
    }
    }

  return (<>
    <Box component="form" sx={{ p: 1 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}>
     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Upload Id</Typography>
         <Input style={{alignContent: "right"}} margin='normal' accept="*" id="idUpload" type="file" onChange={(e) => { setID(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row"  spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Upload CV</Typography>
         <Input accept="*" id="cdUpload" type="file" onChange={(e) => { setCV(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Upload Bank Confirmation</Typography>
         <Input accept="*" id="bankConfirmationUpload" type="file" onChange={(e) => { setBank(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Upload Highest Education Certificate</Typography>
         <Input accept="*" id="highestEducationCertificate" type="file" onChange={(e) => { setEduCertificate(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Upload Sars Document</Typography>
         <Input accept="*" id="sarsDocumentUpload" type="file" onChange={(e) => { setSars(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography style={{width: "50%"}}>Upload Disability Document</Typography>
         <Input accept="*" id="disabilityDocument" type="file" onChange={(e) => { setDisabilityDocument(e.target.files[0]) }} />
     </Stack>

     <Stack direction="row" alignItems="center" spacing={4} py={2}>
         <Typography margin='normal' style={{width: "50%"}}>Special Power Attorney</Typography>
         <Input accept="*" id="specialPowerAttorney" type="file" onChange={(e) => { setSPA(e.target.files[0]) }} />
     </Stack>
     <Box textAlign='center'>
        {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Submit</Button>}
      </Box>
      {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
     </Box>
   </>)
}

export default UploadForm