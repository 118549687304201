import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

const PopupVideo = () => {
  const [open, setOpen] = useState(true);
  const [position, setPosition] = useState({ x: 10, y: 10 });
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    setDragging(true);
    setDragOffset({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      setPosition({
        x: event.clientX - dragOffset.x,
        y: event.clientY - dragOffset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
  }, [dragging]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          position: "center",
          top: `${position.y}px`,
          left: `${position.x}px`,
          width: "50%",
          height: "50%",
          borderRadius: 30,
          cursor: dragging ? "grabbing" : "grab",
        },
        onMouseDown: handleMouseDown,
      }}
    >
      <DialogTitle>Welcome</DialogTitle>
      <DialogContent>
        {/* Embed your video component here */}
        {/* <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/your-video-id"
          title="Video Player"
          allowFullScreen
        /> */}
         <video
              controls // Add this attribute to show video controls (play, pause, etc.)
              autoPlay // Add this attribute to auto-play the video
              loop // Add this attribute to make the video loop
              muted // Add this attribute to mute the video (optional)
              preload="auto" // Add this attribute to preload the video
              style={{ width: '100%', height: '100%' }}
            >
              <source src="/assets/illustrations/gif.mp4" type="video/mp4" />
              {/* Add other source elements for different video formats if needed */}
              {/* <source src="/assets/illustrations/gif.webm" type="video/webm" /> */}
              {/* <source src="/assets/illustrations/gif.ogv" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
      </DialogContent>
    </Dialog>
  );
};

export default PopupVideo;
