




import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { useSelector } from 'react-redux'

import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getToken } from '../services/LocalStorageService'
import { useGetLoggedUserQuery } from '../services/userAuthApi'
import PopupVideo from './Popup'

// ----------------------------------------------------------------------

export default function RequestPending() {
  const theme = useTheme();
  const {accessToken} = getToken()
  const { currentUser } = useSelector(state => state.user)
  const [userData, setUserData] = useState({})
  const {data, isSuccess} = useGetLoggedUserQuery(accessToken)
  useEffect(() => {
    if (data && isSuccess) {
        setUserData( data)
    }
  }, [data, isSuccess]) 
  
  useEffect(() => {
    if (data && isSuccess) {
        setUserData( data)
    }
  }, [data, isSuccess])
  // console.log("useraegawe:",userData)
  // const name = currentUser.name
  return (
    <>
      <Helmet>
        <title> Learner | Y-Plan </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, {userData.name}!{userData.account_activated ? "": "       Your Account is Under verification, Please wait!"}
        </Typography>
                    
        {userData.account_activated ? "" : <PopupVideo mt={1} /> }

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Overall Progress" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="My Attendance" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Discussion Forums" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="My Courses" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}







// import { useEffect, useState } from "react"
// import { useDispatch } from "react-redux"
// import { NavLink, useNavigate } from "react-router-dom"
// import { Alert, Avatar, Box, Button, CircularProgress, CssBaseline, Grid, Input, Stack, Typography } from "@mui/material"

// import { unSetUserToken } from "../features/authSlice"
// import { getToken, removeToken } from "../services/LocalStorageService"

// import ChangePassword from "./ChangePassword"
// import { useGetLoggedUserQuery, useUploadImageMutation } from "../services/userAuthApi"
// import { setUserInfo, unsetUserInfo } from "../features/userSlice"
// import Welcome from "./Welcome" 
// import PopupVideo from "./Popup"

// const RequestPending = () => {
//     const handleLogout = () => {
//         dispatch(unsetUserInfo({ name: "", email: "" }))
//         dispatch(unSetUserToken({ accessToken: null }))
//         removeToken()
//         navigate("/login") 
//     }
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const { accessToken } = getToken()
//     const { data, isSuccess } = useGetLoggedUserQuery(accessToken)
    // const [uploadImage, {isLoading}] = useUploadImageMutation()
    // const [profileImage, setProfileImage] = useState("")
    // const [userData, setUserData] = useState({
    //     image: "",
    //     email: "",
    //     name: "", 
    //     phone: "",
    // })
    // const [error, setError] = useState({
    //     status: false,
    //     msg: "",
    //     type: ""
    // })
//     console.log(userData.image)

//     // Store User Data in Local State
//     useEffect(() => {
//         if (data && isSuccess) {
//             setUserData({
//                 email: data.email,
//                 name: data.name,
//                 phone: data.phone,
//                 image: data.image,
//             })
//         }
//     }, [data, isSuccess])

//     // Store User Data in Redux Store
//     useEffect(() => {
//         if (data && isSuccess) {
//             dispatch(
//                 setUserInfo({
//                     email: data.email,
//                     name: data.name,
//                     phone: data.phone,
//                 })
//             )
//         }
//     }, [data, isSuccess, dispatch])
    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     const data = new FormData()
    //     data.append('image', profileImage)
    //     if (profileImage){  
    //         const res = await uploadImage({data, accessToken})

    //         console.log(res)
    //         console.log(res.data)
    //         console.log(res.error)
    //         if (res.data) {
    //           console.log("data")
    //             setError({ status: true, msg: "Image Uploaded Successfully", type: 'success' })
    //             // resetForm()
    //             // navigate("/requestPending")
    //         }else if (res.error) {
    //           console.log("error1", res.error)
    //           setError({ status: true, msg: res.error.data.errors, type: 'error' })
    //         }else  {
    //           console.log("error2", res.error)
    //           setError({ status: true, msg: "Image Not Selected", type: 'error' })
    //         }
    //       }
//           // data[0].map(item =>console.log(item))
          
//           // console.log(specialPowerAttorney);
         

//     }

//     return (
//         <>
//             <CssBaseline />
//             <Grid container mt={8}>
//                 <Grid item sm={3}>
//                     {/* <Sidebar mt={4} /> */}
//                     <Grid item sm={2.3}>
//                     <PopupVideo mt={4} />
//                     </Grid>
//                 </Grid>
//                 <Grid item sm={6}>
//                     <Welcome/>
//                     <ChangePassword />
//                 </Grid>
//                 <Grid
//                     item
//                     sm={3}
//                     sx={{ backgroundColor: "gray", p: 5, color: "white" }}
//                 >
//                     <Avatar
//                         alt="Remy Sharp"
//                         src={`${currentServer}/${userData.image}`}
//                         sx={{ width: 56, height: 56 }}
//                     />
//                      <Box
//                         component="form"
//                         noValidate
//                         sx={{ mt: 1 }}
//                         id="login-form"
//                         onSubmit={handleSubmit}
//                     >
//                     <Stack direction="row" alignItems="center" spacing={4} py={4}>
//                     {/* <label htmlFor="highestEducationCertificate"> */}
//                         <p>Upload Image</p>
//                         <Input accept="*" id="profile_image" type="file" onChange={(e) => { setProfileImage(e.target.files[0]) }} />
//                         {/* <Button variant="contained" color='secondary' component="span">Select file...</Button> */}
//                     {/* </label> */}
//                     </Stack>
//                     <Box textAlign='center'>
//                         {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Submit</Button>}
//                     </Box>
//                     {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
//                     </Box>
//                     <Typography variant="h5">
//                         Email: {userData.email}
//                     </Typography>
//                     <Typography variant="h6">Name: {userData.name}</Typography>
//                     <Button
//                         variant="contained"
//                         color="warning"
//                         size="large"
//                         onClick={handleLogout}
//                         sx={{ mt: 8 }}
//                     >
//                         Logout
//                     </Button>
                    
//                     {/* <Button
//                         variant="contained"
//                         size="large"
//                         sx={{ mt: 8 }}
//                         component={NavLink}
//                         to="/learner/profileDetails"
//                     >
//                         Profile
//                     </Button> */}
//                 </Grid>
                
//             </Grid>
//         </>
//     )
// }

// export default RequestPending;