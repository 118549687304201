/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Container, Box, Grid } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import FeaturedPost from './FeaturePost'
import MainFeaturedPost from './MainFeaturePost'
import Footer from './Footer'
import HomeMinimal from '../sections/home/home-minimal'
import { HomeView } from '../sections/home/view'

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const featuredPosts = [
  {
    title: 'Y-Plan',
    date: 'Nov 12',
    description:
      'This is a platform that consists of Youth database on national Level',
    image: 'https://source.unsplash.com/random?wallpapers',
    imageLabel: 'Image Text',
  },
  {
    title: 'LMS',
    date: 'Nov 11',
    description:
      'A Learning Management System for enhancing the talent of Youths on national Level.',
    image: 'https://source.unsplash.com/random?wallpapers',
    imageLabel: 'Image Text',
  },
];
const mainFeaturedPost = {
  title: 'Welcome to Y-Plan, The Learning Management System',
  description:
    "An end to end Learning Management System for the youth of the nation.",
  image: 'https://source.unsplash.com/random?wallpapers',
  imageText: 'main image description',
  linkText: 'Continue reading…',
};

// ----------------------------------------------------------------------



export default function Home() {
  return (
    <>
      <Helmet>
        <title> Y-Plan </title>
      </Helmet>
      <HomeView/>
      {/* asdfdsf */}
      <br/>
      {/* App Bar */}
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '90vh',
        }}
      >
        <Container maxWidth="lg"> */}
        {/* <MainFeaturedPost post={mainFeaturedPost} /> */}
          {/* <Grid container spacing={4} > */}
            {/* {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))} */}
             {/* <Box
            sx={{
              overflow: 'hidden',
              position: 'relative',
              bgcolor: 'background.default',
            }}
            > */}
      {/* <HomeView/> */}
      {/* </Box>
          </Grid> */}
          {/* <Box
            sx={{
              overflow: 'hidden',
              position: 'relative',
              bgcolor: 'background.default',
            }}
            >
          <HomeMinimal/>
          </Box> */}

        {/* </Container>
        <Footer/>
      </Box> */}
    </>
  );
}
