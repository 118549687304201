import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import { Provider } from 'react-redux';

// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import {store} from './app/store';
import Router from './route1' 
// import { AppProvider } from './context/adminContext'
// import { CourseContextProvider } from './context/courseContext'

// ----------------------------------------------------------------------
export default function App() {
  return (<>
    {/* // <Provider store ={store}> */}
      {/* // <AppProvider> */}
        {/* <CourseContextProvider> */}
          <HelmetProvider>
            <BrowserRouter>
              <ThemeProvider>
                <ScrollToTop />
                <StyledChart /> 
                <Router />
              </ThemeProvider>
            </BrowserRouter>
          </HelmetProvider>
        {/* </CourseContextProvider> */}
      {/* // </AppProvider> */}
    {/* // </Provider> */}
    </>);
}
