// ----------------------------------------------------------------------

// import { useEffect, useState } from "react"
// import { getToken } from "../services/LocalStorageService"
// import { useGetLoggedUserQuery } from "../services/userAuthApi"
// import { setUserInfo } from "../features/userSlice"


// const dispatch = useDispatch()
//   const {accessToken} = getToken()
// const [userData, setUserData] = useState({
//   email: "",
//   name: "", 
//   phone: "",
// })
// const { data, isSuccess } = useGetLoggedUserQuery(accessToken)

// useEffect(() => {
//   if (data && isSuccess) {
//       setUserData({
//           email: data.email,
//           name: data.name,
//           phone: data.phone,
//       })
//   }
// }, [data, isSuccess])

// // Store User Data in Redux Store
// useEffect(() => {
//   if (data && isSuccess) {
//       dispatch(
//           setUserInfo({
//               email: data.email,
//               name: data.name,
//               phone: data.phone,
//           })
//       )
//   }
// }, [data, isSuccess, dispatch])

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
