/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../services/LocalStorageService'
import { useClientsLearnersQuery, useGetClientProfileQuery, useGetClientsHostQuery, useGetClientsLearnersQuery, useGetUserDetailsQuery, useUpdateUserDetailsMutation } from '../services/userAuthApi'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Iconify from '../components/iconify'
import ProfileCover from 'src/sections/user/profile-cover'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { Controller, useForm } from 'react-hook-form'

const TABS = [
    {
        value: 'profile',
        label: 'Profile',
        icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
        value: 'personal',
        label: 'Personal Details',
        icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    {
        value: 'learners',
        label: 'Learners ',
        icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    {
      value: 'provider',
      label: 'Provider',
      icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
    },
]

const ClientDetails = () => {
  const { id } = useParams()
  const [newBio, setBio] = useState()
  const [newDob, setDob] = useState()
  const [newNgo, setNgo] = useState()
  const [newQualification, setQualification] = useState()
  const [newLearners, setLearners] = useState()

  const navigate = useNavigate()
  const {accessToken} = getToken()
  const [userData, setUserData] = useState({})
  const [clientData, setClientData] = useState({})
  const [clientsHost, setClientsHost] = useState([])
  const [clientsLearners, setClientsLearners] = useState([])
  const [currentTab, setCurrentTab] = useState('profile');
  const [updateUserDetails] = useUpdateUserDetailsMutation()
  const [updateError, setUpdateError] = useState({})

//   console.log("🚀 ~ file: ClientDetails.js:22 ~ ClientDetails ~ clientsHost:", clientsLearners)
  
  const getClientProfile = useGetClientProfileQuery({id, accessToken})
//   const clientUser = useGetUserDetailsQuery({id, accessToken})
  // console.log("🚀 ~ file: clientData.js:11 ~ clientData ~ clientData:", getClientProfile)

  // Store User Data in Local State
//   useEffect(() => {
//     if (clientUser.data && clientUser.isSuccess) {
//         setUserData(clientUser.data)
//     }
//   }, [clientUser.data, clientUser.isSuccess])
  useEffect(() => {
    if (getClientProfile.data && getClientProfile.isSuccess) {
      setClientData(getClientProfile.data)
    }
  }, [getClientProfile.data, getClientProfile.isSuccess])

//   const {account_activated, created_at, email, email_verified, image,is_active, is_admin, is_client, is_host, is_learner, last_login, name, password, phone, phone_verified, tc, updated_at} = userData

//   const {  bio, date_of_birth, ngo, client_type, company_name, registration_no, vat_no, bee_level, qualification, address, no_of_host, learners, sponsor, hosting_provider, project_provider} = clientData

  const [expanded, setExpanded] = useState(false);
  const getClientsHost = useGetClientsHostQuery({id, accessToken})
  const getClientsLearners = useClientsLearnersQuery({id, accessToken})

  useEffect(() => {
    if (getClientsHost.data && getClientsHost.isSuccess) {
        setClientsHost(getClientsHost.data)
    }
  }, [getClientsHost.data, getClientsHost.isSuccess])

  useEffect(() => {
    if (getClientsLearners.data && getClientsLearners.isSuccess) {
        setClientsLearners(getClientsLearners.data)
    }
  }, [getClientsLearners.data, getClientsLearners.isSuccess])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const handleSubmit = () => {}
  



  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const fileInput = document.getElementById('idFile');
        console.log("fileInput", id_upload)
        fileInput.innerHTML =id_upload; // This line will trigger the error.
  };

  const UserProfileDetails = () => {
    const { control, handleSubmit, setValue } = useForm()

    const onSubmit = async (data) => {
      console.log(data);
      const res = await updateUserDetails({id, data, accessToken});
      if (res.error) {
        // setServerError(res.error.data.errors);
        // console.log(res.error);
        setUpdateError(res.error.data.errors);
      }
      if (res.data) {
        // console.log(typeof res.data);
        // console.log(res.data);
        window.location.reload();
      }
    };

    if (getClientProfile.isError) {
      return (
        <Typography variant='h6' sx={{ flexShrink: 0 }}>
                    No Upload Data was found. Please Inform to update...
        </Typography>
      )
    }else if (getClientProfile.isLoading){
      return (
        <p>Loading...</p>
      )
    }
    else if (getClientProfile.isSuccess){
      return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Personal Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                <Controller 
                  control={control}
                  name="name"
                  defaultValue={clientData?.user?.name}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField  {...field} fullWidth  margin='normal' id="name" name="name" /> 
              )}
              />
                 <Typography sx={{ color: 'text.secondary', mt:2  }}>Email</Typography>
                <Controller 
                  control={control}
                  name="email"
                  defaultValue={clientData?.user?.email}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                // <TextField value={field.email} fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }} onChange={(e) => setField({...field, email: e.target.value})}/>
                <TextField  {...field}  fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }}/>
              )}
              />
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Phone</Typography>
                <Controller 
                  control={control}
                  name="phone"
                  defaultValue={clientData?.user?.phone}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField {...field}   fullWidth id="phone" name="phone" InputLabelProps={{ shrink: true, }} />
              )}
              />
                <Box textAlign='center'>
                    <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                </Box>
                {updateError.non_field_errors ? <Alert severity='error'>{updateError.non_field_errors[0]}</Alert> : ''}
                </form>
            </AccordionDetails>
        </Accordion>
      )
    }
  }
    const PersonalDetails = () =>{
        if (getClientProfile.isError) {
            return (
                <Typography variant='h6' sx={{ flexShrink: 0 }}>
                            No Upload Data was found. Please Inform Learner to update...
                </Typography>
            )
        }else if (getClientProfile.isLoading){    
            return (
                <p>Loading...</p>
            )
        }else if (getClientProfile.isSuccess){
            return (
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Personal Details
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
                    </AccordionSummary>
                    <AccordionDetails> 

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Title</Typography>
                        <TextField fullWidth value={clientData.client_type} id="title" name="title"  onChange={(e) => setBio(e.target.value)}>
                        </TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Bio..</Typography>
                        <TextField fullWidth value={clientData.bio} id="bio" name="bio"  onChange={(e) => setBio(e.target.value)}>
                        </TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Date of Birth</Typography>
                        <TextField value={clientData.date_of_birth} type="date" required fullWidth id='date_of_birth' name='date_of_birth' onChange={(e) => setDob(e.target.value)}/>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>NGO</Typography>
                        <TextField value={clientData.ngo} fullWidth id="ngo" name="ngo" InputLabelProps={{ shrink: true, }} onChange={(e) => setNgo(e.target.value)} />

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Registration No</Typography>
                        <TextField value={clientData.registration_no} fullWidth id="registration_no" name="registration_no" InputLabelProps={{ shrink: true, }} onChange={(e) => setNgo(e.target.value)} />

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Vat No</Typography>
                        <TextField value={clientData.vat_no} fullWidth id="vat_no" name="vat_no" InputLabelProps={{ shrink: true, }} onChange={(e) => setNgo(e.target.value)} />

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>BEE Level</Typography>
                        <TextField value={clientData.bee_level} fullWidth id="bee_level" name="bee_level" InputLabelProps={{ shrink: true, }} onChange={(e) => setNgo(e.target.value)} />

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Qualification</Typography>
                        <TextField fullWidth value={clientData.qualification} id="qualification" name="qualification" onChange={(e) => setQualification(e.target.value)}>   </TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Company Name</Typography>
                        <TextField fullWidth value={clientData.company_name} id="company_name" name="company_name" onChange={(e) => setQualification(e.target.value)}>   </TextField>

                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Learners</Typography>
                        <TextField fullWidth value={clientData.learners} id="learners" name="learners"onChange={(e) => setLearners(e.target.value)}></TextField>
                    
                        {/* <Box textAlign='center'>
                            <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                        </Box> */}
                    </AccordionDetails>
                </Accordion>
            )
        }
    }

    const SponsorClient = () => {
        return (
            <>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Sponsor Details
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}> Sponsor details: </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        Sponsor Details: 
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
    const HostingProviderClient = () => {
        return (
            <>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Host Details
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}> Host details: </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* HostList: {clientsHost.map((host) => (<p key={host.id} value={host.id}>{host.company_name}</p>))} */}
                        <List>
                            {clientsHost.map((host) => (
                                <ListItem key={host.id} disablePadding sx={{ display: "block" }}>
                                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                    {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                    <AccountCircleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={host.id} sx={{ opacity: open ? 1 : 0 }} >  {host.user}       </ListItemText>
                                </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    const ProjectProviderClient = () => {
        return (
            <>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Project Details
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}> Project details: </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        Project list
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    const MyLearnersList = () => {
        if (getClientsLearners.isError) {
            return (
              <Typography variant='h6' sx={{ flexShrink: 0 }}>
                          No Learners were found. Please Inform to update...
              </Typography>
            )
          }else if (getClientsLearners.isLoading){
            return (
              <p>Loading...</p>
            )
          }
          else if (getClientsLearners.isSuccess){
        return (
            <>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Learners Details
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}> Learners details: </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* LearnersList: {clientsLearners.map((Learners) => (<p key={Learners.id} value={Learners.id}>{Learners.user}</p>))} */}
                        <List>
                            {clientsLearners.map((Learners) => (
                                <ListItem key={Learners.id} disablePadding sx={{ display: "block" }}>
                                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                    {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                    <AccountCircleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={Learners.id} secondary={Learners.user.email} sx={{ opacity: open ? 1 : 0 }} >        </ListItemText>
                                </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
    }

  return (<>
  <Grid container>
    <Grid item sm={10}  mb={4} mx="auto">
    <CustomBreadcrumbs heading="Profile" links={[
          { name: 'Dashboard', href: "/adminpage" },
          { name: 'User', href: "/adminpage/myProfile" },
          // { name: user.displayName },
          { name: clientData?.user?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <Card sx={{ mb: 3, height: 290, }} > 
        <ProfileCover 
          // role={name}
          // name={name}
          avatarUrl={`http://127.0.0.1:8000/${clientData?.user?.image}`}
          // coverUrl="https://source.unsplash.com/random?wallpapers"
          coverUrl="/assets/profile-header.jpg"
          />
          <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
        </Card>
        {currentTab === 'profile' && <UserProfileDetails />}
        {currentTab === 'personal' && <PersonalDetails />} 
        {/* {/* {currentTab === 'professional' && <ProfessionalDetails />} */}
        {currentTab === 'learners' && <MyLearnersList />}  
        {currentTab === 'provider' && <MyLearnersList />}  
        {clientData.sponsor ? <SponsorClient/>: "" }
        {clientData.hosting_provider ? <HostingProviderClient/>: "" }
        {clientData.project_provider ? <ProjectProviderClient/>: "" }
        </Grid> 

        {/* //////////////////////////////////  */}
    {/* <Grid container>
        <Grid item sm={9}  mb={4} mx="auto"
        sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}
        >
        <Stack direction="row" spacing={2}>
        <Avatar
            alt="Remy Sharp"
            src={`${clientData.user.image}`}
            sx={{ width: 80, height: 80 , mb:2, mt:2, ms:10}}
        />
        <Typography variant='h2' sx={{ flexShrink: 0 }}>
                    Name:  {clientData.user.name}
        </Typography>
        </Stack> */}
        {/* <Typography sx={{  flexShrink: 0 }}>
                    Email:  {email}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
        </Typography>
        <Typography sx={{  flexShrink: 0 }}>
                    Date of Birth:  {date_of_birth}
        </Typography>
        <Typography sx={{ flexShrink: 0 }}>
                    Status:  {account_activated? "Active" : "Not Active"}
        </Typography>
        {sponsor ? <Typography sx={{ flexShrink: 0 }}>
                   Is Sponsor
        </Typography>: "" }
        {hosting_provider ? <Typography sx={{ flexShrink: 0 }}>
                    Is Hosting Provider
        </Typography>: "" }
        {project_provider ? <Typography sx={{ flexShrink: 0 }}>
                    Is Project Provider
        </Typography>: "" } */}
        {/* </Grid> */}

        <Grid item sm={10} mx="auto" mb={10}>
        {/* <Box sx={{  flexShrink: 0 }} > */}
            {/* <Typography variant='h4' sx={{  flexShrink: 0 }}>
                   Any Correction in the below data requires admin approval:
            </Typography> */}
        {/* </Box> */}
        <Box component="form" sx={{ p: 3 }} noValidate id="profileDetails-form" onSubmit={handleSubmit}>

            {/* Personal Details  */}
            {/* <PersonalDetails />
            {clientData.user.sponsor ? <SponsorClient/>: "" }
            {clientData.user.hosting_provider ? <HostingProviderClient/>: "" }
            {clientData.user.project_provider ? <ProjectProviderClient/>: "" }
            <MyLearnersList/> */}

            
            

            {/* {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="success">Submit</Button>} */}
            {/* <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} color="info">Edit Details</Button> */}

        </Box>
        </Grid>
      </Grid>
    </>)
}

export default ClientDetails 


