import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
 

// ----------------------------------------------------------------------

export default function StudentLoginButton({ sx }) {
  return (
    <Button   href={'/student-login'} variant="outlined" sx={{ mr: 1, ...sx }}  >
       Login
    </Button>
  );
}

StudentLoginButton.propTypes = {
  sx: PropTypes.object,
};
