/* eslint-disable */

import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

// import { fetcher, endpoints } from 'src/utils/axios';
import { fetcher, endpoints } from '../utils/axios';

// ----------------------------------------------------------------------

const URL = endpoints.calendar;

const events = [
  {
    "allDay":false,
    "color":"#00A76F",
    "description":"Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "end":1713690024072,
    "id":"e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
    "start":1713684624072,
    "title":"The Ultimate Guide to Productivity Hacks"
  },
  {
    "allDay":false,
    "color":"#00B8D9",
    "description":"Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "end":1713690024072,
    "id":"e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
    "start":1713684624072,
    "title":"The Ultimate Guide to Productivity Hacks"
  },
  {
    "allDay":false,
    "color":"#22C55E",
    "description":"Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "end":1713690024072,
    "id":"e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
    "start":1713684624072,
    "title":"The Ultimate Guide to Productivity Hacks"
  },
  {
    "allDay":false,
    "color":"#8E33FF",
    "description":"Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "end":1713690024072,
    "id":"e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
    "start":1713684624072,
    "title":"The Ultimate Guide to Productivity Hacks"
  },
  {
    "allDay":false,
    "color":"#FFAB00",
    "description":"Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "end":1713690024072,
    "id":"e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
    "start":1713684624072,
    "title":"The Ultimate Guide to Productivity Hacks"
  },
]
const data = {"events": events}

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetEvents() {
  const {  isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  console.log("data", data)
  const memoizedValue = useMemo(() => {
    const events = data?.events.map((event) => ({
      ...event,
      textColor: event.color,
    }));

    return {
      events: events || [],
      eventsLoading: isLoading,
      eventsError: error,
      eventsValidating: isValidating,
      eventsEmpty: !isLoading && !data?.events.length,
    };
  }, [data?.events, error, isLoading, isValidating]);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function createEvent(eventData) {
  /**
   * Work on server
   */
  // const data = { eventData };
  // await axios.post(URL, data);

  /**
   * Work in local
   */
  
  // mutate(
  //   URL,
  //   (currentData) => {
      const events = [...currentData.events, eventData];

      return {
        ...currentData,
        events,
      };
  //   },
  //   false
  // );
}

// ----------------------------------------------------------------------

export async function updateEvent(eventData) {
  /**
   * Work on server
   */
  // const data = { eventData };
  // await axios.put(endpoints.calendar, data);

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData) => {
      const events = currentData.events.map((event) =>
        event.id === eventData.id ? { ...event, ...eventData } : event
      );

      return {
        ...currentData,
        events,
      };
    },
    false
  );
}

// ----------------------------------------------------------------------

export async function deleteEvent(eventId) {
  /**
   * Work on server
   */
  // const data = { eventId };
  // await axios.patch(endpoints.calendar, data);

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData) => {
      const events = currentData.events.filter((event) => event.id !== eventId);

      return {
        ...currentData,
        events,
      };
    },
    false
  );
}
