import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Skeleton,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    CircularProgress,
    Alert,
  } from '@mui/material';
import { useDispatch, useSelector } from "react-redux"
import { setUserInfo } from "../features/userSlice"
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from "../services/LocalStorageService"
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// import {
//     useGetAllUsersQuery,
//     useGetLoggedUserQuery,
// } from "../services/userAuthApi"
 
import { useApproveRequestMutation, useEnrollRequestsQuery } from "../services/coursesApi"


const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'course', label: 'Course', alignRight: false },
    // { id: 'role', label: 'Role', alignRight: false },
    // { id: 'approved', label: 'Approved', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

const Requests = () => {
  const [requests, setRequest] = useState([]);
  const { accessToken } = getToken()


    // const { isRequestLoading, isError, requests } = useCourseContext()
    // const {requests} = useSelector(state => state.course)
    const enrollRequests = useEnrollRequestsQuery(accessToken)

    useEffect(() => {
      if (enrollRequests.data && enrollRequests.isSuccess) {
        setRequest(enrollRequests.data)
      }
    }, [enrollRequests.data, enrollRequests.isSuccess])

    // console.log(isRequestLoading)
    // console.log(isError)
    // console.log("requests", requests)/
    const [approveRequest, {isLoading}] = useApproveRequestMutation()
    // console.log( activeUsers, verifiedUsers)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [error, setError] = useState({
      status: false,
      msg: "",
      type: ""
    })

    const handleOpenMenu = (event) => {
      setOpen(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setOpen(null);
    };
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = requests.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, learner) => {
      const selectedIndex = selected.indexOf(learner);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, learner);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };
  
    const handleFilterByName = (event) => {
      setPage(0);
      setFilterName(event.target.value);
    }; 
    const handleApprove = async (e, id) => {
      // const selectedIndex = selected.indexOf(id);

      // console.log(selectedIndex);
      // console.log("enrollmentId", id);
      const data ={"id": id}
      const res = await approveRequest({data, accessToken})
      if (res.error){
        console.log(res.error.data.errors)
        setError({ status: true, msg: res.error.data.errors[0], type: 'error' })
      }
      if (res.data){
        // console.log(res.data)
        setError({ status: true, msg: "Course Approved", type: 'success' })
        window.location.reload(); 
      }

    };
  
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST?.length) : 0;
  
    const filteredUsers = applySortFilter(requests, getComparator(order, orderBy), filterName);
  
    const isNotFound = !filteredUsers.length && !!filterName;

    if (enrollRequests.isLoading) {
        ;<Stack spacing={1}>
            <h1 className="mt-4">Loading</h1>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
        </Stack>
    }

    return (
        <>
      <Helmet>
        <title> Requests | Y-Plan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Requests:
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>   */}
         
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                   
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={requests.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    // eslint-disable-next-line
                    const { id, approved, learner, course } = row;
                    // console.log("approval", id, approved, learner, course)
                    
                    const isActive = "error"
                    const selectedUser = selected.indexOf(learner) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, learner)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={learner} src={image} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {learner}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{course}</TableCell>

                        {/* <TableCell align="left">{learner}</TableCell> */}
          {/* // eslint-disable-next-line */}
                        {/* <TableCell align="left">{approved ? 'Yes' : 'No'}</TableCell> */}
                        {/* <TableCell align="left">{approved ? 'Yes' : 'No'}</TableCell> */}

                        <TableCell align="left">
                          <Label color={(isActive === 'banned' && 'error') || 'success'}>{sentenceCase("NotApproved")}</Label>
                        </TableCell>

                        <TableCell align="left">
                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                          {isLoading ? <CircularProgress /> : <Button  onClick={(e) => handleApprove(e, id)}>Approve</Button>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}


                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={requests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          <Button >Approve</Button>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>

    )
}

export default Requests