import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import {useResponsive} from '../../../hooks/use-responsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
// import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import AdminNavSection from './AdminNavSection';
import { setUserInfo } from '../../../features/userSlice'
import { useGetLoggedUserQuery } from '../../../services/userAuthApi'
import { getToken } from '../../../services/LocalStorageService'
import currentServer from '../../../_mock/server'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  // const { access_token } = useSelector(state => state.auth)
  const { name, email, phone } = useSelector(state => state.user)
  // console.log("user:",name, email, phone)
  const { pathname } = useLocation();
   
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {accessToken} = getToken()
  const { data, isSuccess } = useGetLoggedUserQuery(accessToken)
  // console.log("userdata", data)
  const [userData, setUserData] = useState({
    email: "",
    name: "", 
    phone: "",
    image: "",
})
// Store User Data in Local State
useEffect(() => {
  if (data && isSuccess) {
      setUserData({
          email: data.email,
          name: data.name,
          phone: data.phone,
          image: data.image,
      })
  }
}, [data, isSuccess])

// Store User Data in Redux Store
useEffect(() => {
  if (data && isSuccess) {
      dispatch(
          setUserInfo({
              email: data.email,
              name: data.name,
              phone: data.phone,
              image: data.image,
          })
      )
  }
}, [data, isSuccess, dispatch])

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            {/* <Avatar src={`${currentServer}/${userData.image}`} alt="photoURL" /> */}
            <Avatar src={`${userData.image}`} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {/* {account.displayName} */}
                {userData.name}
                {/* {userData.email} */}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      {/* <NavSection data={navConfig} /> */}
      <AdminNavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
