import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'


// @mui
import { Button, Container,Grid, Stack, Typography } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import ShopProductCard from '../sections/@dashboard/products/ProductCard'
import Iconify from '../components/iconify';
import ApplyCourseCard from '../sections/@dashboard/products/CourseCard'
import section from "../images/Section.png"
import MyCourseCard from '../sections/@dashboard/products/MyCourseCard'
import { getToken } from '../services/LocalStorageService'
import { useGetAllCoursesQuery, useGetMyCoursesQuery } from '../services/coursesApi'
// import ShopProductCard from '';
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

// eslint-disable-next-line 
const ApplyCourse = () => {
  // const {courses, myCourses} = useSelector(state => state.course)
  const navigate = useNavigate()
  const [courses, setCourses] = useState([])
  const [myCourses, setMyCourses] = useState([])
  const [openFilter, setOpenFilter] = useState(false);
  const {accessToken} = getToken()
  const getAllCourses = useGetAllCoursesQuery(accessToken)
  // console.log("🚀 ~ file: ApplyCourse.js:33 ~ ApplyCourse ~ getAllCourses:", getAllCourses)
  const getMyCourses = useGetMyCoursesQuery(accessToken)
   console.log("🚀 ~ ApplyCourse ~ getMyCourses:", getMyCourses.data)
  

  // Store User Data in Local State
  useEffect(() => {
    if(getMyCourses.data && getMyCourses.isSuccess) {
      
      setMyCourses(getMyCourses.data)
    }
  }, [getMyCourses.data, getMyCourses.isSuccess])
  useEffect(() => {
    if (getAllCourses.data && getAllCourses.isSuccess) {
      setCourses(getAllCourses.data)
    }
  }, [getAllCourses.data, getAllCourses.isSuccess])  

  const handleOpenFilter = () => {
      setOpenFilter(true);
  };
  // console.log("handle", myCourses)
  const handleCloseFilter = () => {
  setOpenFilter(false);
  };
 
  return (
    <>
    <Helmet>
      <title> All Courses | Y-Plan </title>
    </Helmet>

    <Container>
      {/* <Typography variant="h4" sx={{ mb: 5 }}>
        Courses
      </Typography> */}
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ProductFilterSidebar
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
          <ProductSort />
        </Stack>
      </Stack>

      <hr/>
      {/* <h2 className="align-left" style={{backgroundImage:`url(${section})`}}> */}
      <h2 className="align-left" style={{backgroundColor:"info"}}>
        My Course:
      </h2>

      {/* <ProductList products={courses} /> */}
      <Grid container spacing={3}>
      {myCourses.map((course) => (
        <Grid key={course.course.id} item xs={12} sm={6} md={3}>
          <MyCourseCard course={course} />
          {/* <Button >Apply</Button> */}
        </Grid>
      ))}
      </Grid>


      <hr className="mt-4" />
      <h2 className="align-left" style={{backgroundColor:"info"}}>
        Featured Courses:
      </h2>
      {/* <ProductList products={courses} /> */}
      <Grid container spacing={3}>
      {courses.map((course) => (
        <Grid key={course.id} item xs={12} sm={6} md={3}>
          <ApplyCourseCard course={course} />
          {/* <Button >Apply</Button> */}
        </Grid>
      ))}
      </Grid>
      {/* <ProductCartWidget /> */}
    </Container>
  </>
  )
}

export default ApplyCourse