/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Avatar, Box, Button, Chip, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Rating, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
// import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import { getToken } from "../services/LocalStorageService"
import { useCourseDetailsQuery, useGetCourseModulesQuery, useGetCourseResoursesQuery, useGetCourseVideosQuery } from '../services/coursesApi'
import Iconify from '../components/iconify/Iconify'
import currentServer from '../_mock/server'
// import { TabPanel } from '@mui/lab'


const CourseDetails = () => {
    const { slug } = useParams()
    const {accessToken} = getToken()
    const theme = useTheme();
    const [courseData, setCourseData] = useState({})
    const [moduleList, setModuleList] = useState([])
    const [resourseList, setResourseList] = useState([])
    const [videosList, setVideosList] = useState([])
    const [value, setValue] = useState(0);

    const courseDetails = useCourseDetailsQuery({slug, accessToken})
    const moduleDetails = useGetCourseModulesQuery({slug, accessToken})
    const resourseDetails = useGetCourseResoursesQuery({slug, accessToken})
    const videoDetails = useGetCourseVideosQuery({slug, accessToken})
    console.log("🚀 ~ file: CourseDetails.js:18 ~ CourseDetails ~ videosList:", moduleList)
    // Storing data to the local state
    useEffect(() => {
        if(courseDetails.data && courseDetails.isSuccess){
            setCourseData(courseDetails.data)
        }
      }, [courseDetails.data, courseDetails.isSuccess])
    useEffect(() => {
        if(moduleDetails.data && moduleDetails.isSuccess){
            setModuleList(moduleDetails.data)
        }
      }, [moduleDetails.data, moduleDetails.isSuccess])
      useEffect(() => {
        if(resourseDetails.data && resourseDetails.isSuccess){
            setResourseList(resourseDetails.data)
        }
      }, [resourseDetails.data, resourseDetails.isSuccess])
      useEffect(() => {
        if(videoDetails.data && videoDetails.isSuccess){
            setVideosList(videoDetails.data)
        }
      }, [videoDetails.data, videoDetails.isSuccess])
    //   console.log("course", courseData) 
    
    const {id: alias, description, academy, category, subCategory, enroller_user, image_course, created_at, host, learned, name, organization, rating, small_description, tags, total_module, total_video, updated_at, vidoes_time} = courseData;
    // console.log("🚀 ~ file: CourseDetails.js:51 ~ CourseDetails ~ image_course:", image_course)


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const handleSubmit = () => {}

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  

    const CourseDetails = () =>  {
        if (courseDetails.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Course Details was found. Please update...
            </Typography>
        )
        }else if (courseDetails.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (courseDetails.isSuccess){
        return (
            <>
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                <TextField value={name} required fullWidth id='name' name='name' />

                {/* <Typography sx={{ color: 'text.secondary', mt:2  }}>Date of Birth</Typography>
                <TextField value={description} fullWidth id="date_of_birth" name="date_of_birth" type="date" InputLabelProps={{ shrink: true, }} /> */}

                <Typography sx={{ color: 'text.secondary', mt:2  }}>Host</Typography>
                <TextField value={host} required fullWidth id='host' name='host' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Academy</Typography>
                <TextField value={academy} required fullWidth id='academy' name='academy' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Category</Typography>
                <TextField value={category} required fullWidth id='category' name='category' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>SubCategory</Typography>
                <TextField value={subCategory} required fullWidth id='subCategory' name='subCategory' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Description</Typography>
                <TextField value={description} required fullWidth id='description' name='description' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Sub Description</Typography>
                <TextField value={small_description} required fullWidth id='small_description' name='small_description' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Created At</Typography>
                <TextField value={created_at} required fullWidth id='created_at' name='created_at' />
                
                <Typography sx={{ color: 'text.secondary', mt:2  }}>Updated At</Typography>
                <TextField value={updated_at} required fullWidth id='updated_at' name='updated_at' />
                
            </>
        )
        }
    }

    const ModuleDetails = () =>  {
        if (moduleDetails.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Course Details was found. Please update...
            </Typography>
        )
        }else if (moduleDetails.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (moduleDetails.isSuccess){
        return (
            <>
                <List>
                        {moduleList.map((modules) => (
                            <ListItem key={modules.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <TopicIcon />
                                </ListItemIcon>
                                <ListItemText primary={modules.name} sx={{ opacity: open ? 1 : 0 }} >  {modules.name} - {modules.description}       </ListItemText>
                                <ListItemText primary={modules.description} sx={{ opacity: open ? 1 : 0 }} >  {modules.name} - {modules.description}       </ListItemText>
                                <ListItemText primary={`Duration: ${modules.duration}`} sx={{ opacity: open ? 1 : 0 }} >  {modules.name} - {modules.description}       </ListItemText>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
        )
        }
    }

    const ResourseDetails = () =>  {
        if (resourseDetails.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Resource was found. Please update...
            </Typography>
        )
        }else if (resourseDetails.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (resourseDetails.isSuccess){
        return (
            <>
                <List>
                        {resourseList.map((modules) => (
                            <ListItem key={modules.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {modules.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <TopicIcon />
                                </ListItemIcon>
                                {/* <ListItemText primary={modules.name} sx={{ opacity: open ? 1 : 0 }} >  </ListItemText> */}
                                <Link target='_blank' to={`${modules.file}`}><ListItemText primary="File" sx={{ opacity: open ? 1 : 0 }} >         </ListItemText></Link>
                                <Link ms={10} target='_blank' to={`${modules.url}`}><ListItemText primary={modules.url} sx={{ opacity: open ? 1 : 0 }} >         </ListItemText></Link>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
        )
        }
    }
    const VideoDetails = () =>  {
        if (videoDetails.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Videos was found. Please update...
            </Typography>
        )
        }else if (videoDetails.isLoading){
        return (
            <p>Loading...</p>
        )
        }
        else if (videoDetails.isSuccess){
        return (
            <>
                <List>
                        {videosList.map((videos) => (
                            <ListItem key={videos.id} disablePadding sx={{ display: "block" }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                                {/* {videos.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                <TopicIcon />
                                </ListItemIcon>
                                <ListItemText primary={videos.name} secondary={videos.description} sx={{ opacity: open ? 1 : 0 }} >  {videos.name} - {videos.description}       </ListItemText>
                                <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText>
                                <ListItemText primary={videos.module} sx={{ opacity: open ? 1 : 0 }} >     </ListItemText>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
        )
        }
    }

 

  return (
    <>
    <Grid container mt={2}>
        <Grid item sm={6}  mb={2}
            sx={{  height:"400px", p: 4, color: "black", border: "rounded" }}
            >
            {/* <Avatar
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
                sx={{ width: 156, height: 156 , mb:5, mt:5}}
            /> */}
            <img
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
               // src={`${image_course}`}
                style={{ width: 550, height: 350, padding: 2 }}
            />
            {/* <Typography sx={{ flexShrink: 0 }}>
                        Name:  {name}
            </Typography> */}
            
        </Grid>
        <Grid item sm={6}  mb={2}
            sx={{  height:"400px", py: 4, px: 2, color: "black", border: "rounded" }}
            >
            {/* <Avatar
                alt="Remy Sharp"
                src={`${currentServer}/${image_course}`}
                sx={{ width: 156, height: 156 , mb:5, mt:5}}
            /> */}
            <Typography variant='h3' sx={{ flexShrink: 0 }}>
                        Name:  {name}
            </Typography>
            <Typography variant='h5' sx={{  flexShrink: 0 }} gutterBottom>
                        Description:  {description}
            </Typography>
            <Typography variant='h6' sx={{ flexShrink: 0, color:"grey" }} gutterBottom>
                        {small_description}
            </Typography>
            <Typography variant='p' sx={{  flexShrink: 0 }} gutterBottom>
            <Chip label="Ratings: "  color="secondary" />  {rating} <Rating mt={1} name="read-only" value={rating} readOnly/> 
            </Typography>
            {/* <Typography sx={{ flexShrink: 0 }}>
                        Status:  {account_activated? "Active" : "Not Active"}
            </Typography> */}
        </Grid>



        <Grid item sm={11} mx="auto" mb={10}>
            <Box sx={{  flexShrink: 0 }}  >
                <Typography variant='h4' sx={{  flexShrink: 0 }}>
                    Videos, resourse and Notes:
                </Typography>
            </Box>
            <Box component="form"  noValidate id="profileDetails-form" onSubmit={handleSubmit}>
            <Box sx={{ width: '100%' }}>
                {/* <AppBar position="static"> */}
                    <Tabs
                    value={value}
                    
                    onChange={handleTabsChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="Course Details" {...a11yProps(0)}/>
                    <Tab label="Modules" {...a11yProps(1)}/>
                    <Tab label="Resources" {...a11yProps(2)}/>
                    <Tab label="Videos" {...a11yProps(3)}/> 
                </Tabs>
                {/* </AppBar> */}
                </Box>
                {/* <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                > */}
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <CourseDetails />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <ModuleDetails/>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <ResourseDetails/>
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <VideoDetails/>
                    </TabPanel>
                     
                {/* </SwipeableViews> */}
    
                
            </Box>
            </Grid>
        </Grid>
    </>
  )
}

export default CourseDetails


  