import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    attendanceTrack: {}
}


export const attendanceSlice = createSlice({
    name: "attendance_info",
    initialState,
    reducers: {
        setAttendanceInfo: (state, action) => {
            state.attendanceTrack = action.payload.attendanceTrack
        }, 
        unSetAttendanceInfo: (state, action) => {
            state.attendanceTrack = action.payload.attendanceTrack
        },
    },
})

export const { setAttendanceInfo, unSetAttendanceInfo } = attendanceSlice.actions

export default attendanceSlice.reducer