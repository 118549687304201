// import { info, error, primary, success, warning, secondary } from './theme/palette';
// import { info, error, primary, success, warning, secondary } from '../theme/palette';

import palette from "../theme/palette";

// import { info, error, primary, success, warning, secondary } from "../theme/palette"
const { info, error, primary, success, warning, secondary } = palette



// ----------------------------------------------------------------------
export const CALENDAR_COLOR_OPTIONS = [
  primary.main,
  secondary.main,
  info.main,
  info.darker,
  success.main,
  warning.main,
  error.main,
  error.darker,
  // primary,
  // secondary,
  // info, 
  // success,
  // warning,
  // error
];

