import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    categories: {},
    subCategories: {},
    courses: [],
    myCourses: [],
    requests: [],
    modules: {},
    videos: {},
    resources: {},
    enrollments: {},
    myEnrollments: {},
}

export const courseSlice = createSlice({
  name: 'course_info',
  initialState,
  reducers: {
    setCourseInfo: (state, action) => {
      state.courses = action.payload.courses
      state.myCourses = action.payload.myCourses
      state.requests = action.payload.requests
    },
    unSetCourseInfo: (state, action) => {
      state.courses = action.payload.courses
      state.myCourses = action.payload.myCourses
      state.requests = action.payload.requests
    },
  },
})

export const { setCourseInfo, unSetCourseInfo } = courseSlice.actions

export default courseSlice.reducer