import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { useEnrollRequestsQuery, useGetAllCoursesQuery } from '../../services/coursesApi'
import { getToken } from '../../services/LocalStorageService'
import { setCourseInfo } from '../../features/courseSlice'
import { useGetAllUsersQuery } from '../../services/userAuthApi'
import { setUserInfo } from '../../features/userSlice'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function AdminLayout() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [course, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  // const [newUsers, setNewUsers] = useState([]);
  const {accessToken} = getToken()
  const getAllUsers = useGetAllUsersQuery(accessToken)
  const getAllCourses = useGetAllCoursesQuery(accessToken)
  const enrollRequests = useEnrollRequestsQuery(accessToken)
  // console.log("🚀 ~ file: AdminLayout.js:52 ~ AdminLayout ~ enrollRequests:", enrollRequests)
  let activeData
  let deletedData
  let newData
  let verifiedData
  let learnerData
  let hostData
  let clientData

  // Store User Data in Local State
  useEffect(() => {
    if (getAllCourses.data && getAllCourses.isSuccess) {
      setCourses(getAllCourses.data)
    }
  }, [getAllCourses.data, getAllCourses.isSuccess])  
  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess) {
      setUsers(getAllUsers.data)
      activeData = getAllUsers.data.filter((curElem) => {
      return curElem.is_active === true;
      })
      deletedData = getAllUsers.data.filter((curElem) => {
      return curElem.is_active === false;
      })
      newData = getAllUsers.data.filter((curElem) => {
      return curElem.account_activated === false;
      })
      verifiedData = getAllUsers.data.filter((curElem) => {
      return curElem.account_activated === true;
      }) 
      learnerData = getAllUsers.data.filter((curElem) => {
      return curElem.is_learner === true;
      }) 
      hostData = getAllUsers.data.filter((curElem) => {
      return curElem.is_host === true;
      }) 
      clientData = getAllUsers.data.filter((curElem) => {
      return curElem.is_client === true;
      })
    }
  }, [getAllUsers.data, getAllUsers.isSuccess])  
  
  // Categorize Users into verified, unverified, learner, hosts cliens etc.

  
  
    // console.log("getAllCourses", getAllUsers.data[0].is_admin)
 


  // Store User Data in Redux Store
  useEffect(() => {
    if (getAllCourses.data && getAllCourses.isSuccess){
        dispatch(
          setCourseInfo({
            courses: getAllCourses.data
        }))
    }
  }, [getAllCourses.data, getAllCourses.isSuccess]) 
  useEffect(() => {
    if (enrollRequests.data && enrollRequests.isSuccess){
        dispatch(
          setCourseInfo({
            requests: enrollRequests.data
        }))
    }
  }, [enrollRequests.data, enrollRequests.isSuccess]) 
  
  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess){
        dispatch(
          setUserInfo({
            allUsers: getAllUsers.data,
            activeUsers: activeData,
            deletedUsers: deletedData,
            verifiedUsers: verifiedData,
            newUsers: newData,
            learners: learnerData,
            hosts: hostData,
            clients: clientData,

        }))
    }
  }, [getAllUsers.data, getAllUsers.isSuccess ])

  const {learners} = useSelector(state => state.user)
    // console.log("getAllCourses", learners)

  
   

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
