/* eslint-disable */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

// @mui
import { Button, Container,Grid, Stack, Typography } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import Iconify from '../components/iconify';
import { useSelector } from 'react-redux'
import { getToken } from '../services/LocalStorageService'
import { useGetAllCoursesQuery } from '../services/coursesApi'
import AllCourseCard from '../sections/@dashboard/products/AllCourseCard'
// import ShopProductCard from '';
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

// eslint-disable-next-line
const AllCourses = () => {
  // const {courses} = useSelector(state => state.course)
  const [courses, setCourses] = useState([])
  const {accessToken} = getToken()
  const getAllCourses = useGetAllCoursesQuery(accessToken)

  // Store User Data in Local State
  useEffect(() => {
    if (getAllCourses.data && getAllCourses.isSuccess) {
      setCourses(getAllCourses.data)
    }
  }, [getAllCourses.data, getAllCourses.isSuccess])  

  const navigate = useNavigate()
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
      setOpenFilter(true);
  };
  const newCourse = () => {
    console.log("new Course")
    navigate("/adminpage/addCourse")
  }

  const handleCloseFilter = () => {
  setOpenFilter(false);
  };

  if (getAllCourses.isLoading){
    return(<h1 className="text-center" ><br/><br/><br/>Loading...</h1>)
  }if (getAllCourses.isError){
    return(<h1 className="text-center" ><br/><br/><br/>Error...</h1>)
  }else{
    
  return (
    <>
    <Helmet>
      <title> All Courses | Y-Plan </title>
    </Helmet>

    <Container>
      {/* <Typography variant="h4" sx={{ mb: 5 }}>
        Courses
      </Typography> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Courses
          </Typography>
          <Button variant="contained" onClick={newCourse} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Courses  
          </Button>
        </Stack>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ProductFilterSidebar
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
          <ProductSort />
        </Stack>
      </Stack>

      {/* <ProductList products={courses} /> */}
      <Grid container spacing={3}>
      {courses.map((course) => (
        <Grid key={course.id} item xs={12} sm={6} md={3}>
          <AllCourseCard course={course} />
        </Grid>
      ))}
    </Grid>
     {/*  <ProductCartWidget /> */}
    </Container>
  </>
  )
  }
}

export default AllCourses