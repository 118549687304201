import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import {
  Container,
  Typography,
  Stack,
  Button,
  TextField,
  Box,
  Alert,
  CircularProgress,
  Avatar,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useLoginUserMutation } from '../services/userAuthApi';
import { storeToken, getToken } from '../services/LocalStorageService';
import { setUserToken } from '../features/authSlice';
import { useResponsive } from '../hooks/use-responsive';
import Iconify from '../components/iconify';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LearnerLogin() {
  const mdUp = useResponsive('up', 'md');
  const [locationCoordinates, setLocationCoordinates] = useState(null);
  const [locationError, setLocationError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [learnerLogin, { isLoading }] = useLoginUserMutation();
  const [serverError, setServerError] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLocationError('');
        },
       (error) => {
          setLocationError('Please enable location services to continue.');
        }  
      );
    };

    getLocation();

    // Monitor permission changes
   /*  navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      result.onchange = () => {
        if (result.state === 'granted') {
          getLocation();
        }
      };
    }); */
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
   /*  if (!locationCoordinates) {
      setLocationError('Please enable location services to continue.');
      return;
    } */

    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
      password: data.get('password'),
      lat: locationCoordinates.lat,
      lng: locationCoordinates.lng,
    };

    const res = await learnerLogin(actualData);
    if (res?.error) {
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      storeToken(res.data.token);
      const { accessToken } = getToken();
      dispatch(setUserToken({ accessToken }));

      const userType = res.data.user;
      switch (userType) {
        case 'admin':
          navigate('/adminpage/app');
          break;
        case 'learner':
          navigate('/learnerpage/app');
          break;
        case 'host':
          navigate('/hostpage/app');
          break;
        case 'subhost':
          navigate('/subhost/app');
          break;
        case 'client':
          navigate('/clientpage/app');
          break;
        case 'academy':
          navigate('/academy/app');
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Log In and Start Learning | Y-Plan </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 2, mb: 5 }}>
              Hey There! Welcome Back!!!
            </Typography>
            {/* <img src="/assets/illustrations/login.gif" alt="login" /> */}
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}
        <Container maxWidth="sm">
          <StyledContent>
            <Box sx={{ my: 4, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main', alignContent: 'center' }}>
                <LockOpenIcon />
              </Avatar>
              <Typography variant="h4" gutterBottom>
                Log in to your Y-Plan Account 
              </Typography>
            </Box>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Typography variant="subtitle2" component={NavLink} to="/register">
                Get started
              </Typography>
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }} id="login-form" onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField name="email" label="Email address" />
                {serverError.email && (
                  <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                    {serverError.email[0]}
                  </Typography>
                )}
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {serverError.password && (
                  <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                    {serverError.password[0]}
                  </Typography>
                )}
              </Stack>

              <Stack direction="row" justifyContent="right" sx={{ my: 2 }}>
                <Typography
                  variant="subtitle2"
                  justifyItems="right"
                  component={NavLink}
                  to="/sendPasswordResetMail"
                  underline="hover"
                >
                  Forgot Password
                </Typography>
              </Stack>

              {locationError && <Alert severity="error">{locationError}</Alert>}

              <Box textAlign="right">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Login
                  </Button>
                )}
              </Box>
              {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
