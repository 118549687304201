const storeToken = (value) => {
  if (value) {
    // console.log("Store Token")
    const { access, refresh } = value
    localStorage.setItem('accessToken', access)
    localStorage.setItem('refreshToken', refresh)
  }
}

const getToken = () => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  return { accessToken, refreshToken }
}

const removeToken = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

const storeSessionKey = (value) => {
  if (value) {
    // console.log("Store Token")
    // const { session_key } = value
    localStorage.setItem('session_key', value)
  }
}

const getSessionKey = () => {
  const sessionKey = localStorage.getItem('session_key')
  return { sessionKey }
}

const removeSessionKey = () => {
  localStorage.removeItem('session_key')
}

export { storeToken, getToken, removeToken, storeSessionKey, getSessionKey, removeSessionKey }