// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/hostpage/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Manage Users/Learners',
  //   path: '/hostpage/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'courses',
    path: '/hostpage/courses',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'Learners',
  //   path: '/hostpage/blog',
  //   icon: icon('ic_user'),
  // }, 
  {
    title: 'Learners',
    path: '/hostpage/learners',
    icon: icon('ic_user'),
  },
  {
    title: 'Monitoring and Attendance',
    path: '/hostpage/monitoring',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
