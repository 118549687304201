/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Modal,
  Box,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../features/userSlice';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from '../services/LocalStorageService';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import currentNetwork from '../_mock/network';
import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';

import {
  useCreateHostMutation,
  useGetAllUsersQuery,
  useGetLoggedUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../services/userAuthApi';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllUsers = () => {
  const [createHost] = useCreateHostMutation();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();
  const [open, setOpen] = useState(null);
  const getAllUsers = useGetAllUsersQuery(accessToken);
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [qualification, setQualification] = useState('');
  const [dob, setDob] = useState('');

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Store User Data in Local State
  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess) {
      setUsers(getAllUsers.data);
    }
  }, [getAllUsers.data, getAllUsers.isSuccess]);

  const [selectedUser, setSelectedUser] = useState([]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedUser(row);
  };

  console.log('selecetedUser', selectedUser);

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen();
  };
  const submitHost = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const actualData = {
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone'),
      password: data.get('password'),
      department: data.get('department'),
      qualification: data.get('qualification'),
      date_of_birth: data.get('dob'),
    };
    console.log(actualData);
    const res = await createHost({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();
      navigate('/adminpage/user');
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (getAllUsers.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (getAllUsers.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  //  if (getAllUsers.isError){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No User Data Found...</h1>)
  //  }else{

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const [editModal, setEditModal] = useState(false);
  const handleEditClose = () => setEditModal(!editModal);
  const handleDeleteModalClose = () => setDeleteModal(!deleteModal);
  const [selectedSubHost, setSelectedSubHost] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedUser?.id;
    console.log('data from update host:', actualData);

    const res = await deleteUser({ id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      handleDeleteModalClose();
       window.location.reload();
    }
  };

  const [academyList, setAcademyList] = useState([]);

  // Get all users and filter subhost

  const editLearnersSubmit = async (values) => {
    console.log('values', values);

    // const data = new FormData(e.currentTarget);

    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      tc: values.tc,
      is_active: values.is_active,
      is_admin: values.is_admin,
      is_learner: values.is_learner,
      is_host: values.is_host,
      is_subhost: values.is_subhost,
      is_client: values.is_client,
      academy_manager: values.academy_manager,
    };
    const id = selectedUser?.id;
    console.log('data from update learner:', values);

    const res = await updateUser({ data, id, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res.data) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleEditClose();

      //window.location.reload();
      // navigate('/adminpage/hosts');
    }
  };
  return (
    <>
      <Helmet>
        <title> User | Y-Plan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>New Host</Button>   */}
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    const {
                      id,
                      name,
                      email,
                      image,
                      phone,
                      is_active,
                      is_admin,
                      is_client,
                      is_host,
                      is_subhost,
                      is_learner,
                      account_activated,
                    } = row;
                    const isActive = String(is_active);
                    const accountActivated = String(account_activated);
                    const selectedUser = selected.indexOf(name) !== -1;
                    if (is_admin) {
                      var role = 'Admin';
                    } else if (is_learner) {
                      var role = 'Learner';
                    } else if (is_host) {
                      var role = 'Host';
                    } else if (is_subhost) {
                      var role = 'SubHost';
                    } else if (is_client) {
                      var role = 'Client';
                    } else {
                      var role = 'New Learner';
                    }
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <NavLink to={`http://${currentNetwork}/adminpage/user/${id}`}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={image} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </NavLink>

                        <TableCell align="left">{phone}</TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{role}</TableCell>

                        <TableCell align="left">
                          <Label color={(accountActivated === 'banned' && 'error') || 'success'}>
                            {sentenceCase(accountActivated)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {getAllUsers.isError && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            {/* <strong>&quot;{filterName}&quot;</strong>. */}
                            <strong>&quot;Users&quot;</strong>.
                            <br /> Try to Log In again with an Admin Id...
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setEditModal(!editModal)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* Create host Modal  */}
      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="otp-form"
        onSubmit={submitHost}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a Host
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            label="name"
            onChange={(name) => {
              setPhone(name);
            }}
          />
          {serverError.name ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label="email"
            onChange={(email) => {
              setPhone(email);
            }}
          />
          {serverError.email ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            name="phone"
            label="phone"
            onChange={(phone) => {
              setEmail(phone);
            }}
          />
          {serverError.phone ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            name="password"
            label="password"
            onChange={(password) => {
              setPhone(password);
            }}
          />
          {serverError.password ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.password[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="department"
            name="department"
            label="department"
            onChange={(department) => {
              setPhone(department);
            }}
          />
          {serverError.department ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.department[0]}</Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="qualification"
            name="qualification"
            label="qualification"
            onChange={(qualification) => {
              setEmail(qualification);
            }}
          />
          {serverError.qualification ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
              {serverError.qualification[0]}
            </Typography>
          ) : (
            ''
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="date_of_birth"
            type="date"
            name="dob"
            label="date_of_birth"
            onChange={(date_of_birth) => {
              setEmail(emdate_of_birthadate_of_birthilOtp);
            }}
          />
          {serverError.dob ? (
            <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
              {serverError.date_of_birth[0]}
            </Typography>
          ) : (
            ''
          )}

          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
              Create Host
            </Button>
          </Box>
          {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>

      {/* /////////////////////////////////////////////////////////////////////////////// */}

      {/* Edit host Modal  */}
      <Modal
        open={editModal}
        onClose={handleEditClose}
        id="otp-form"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Formik
          initialValues={{
            name: selectedUser?.name || '',
            email: selectedUser?.email || '',
            phone: selectedUser?.phone || '',
            tc: selectedUser?.tc || '',
            is_active: selectedUser?.is_active || '',
            is_admin: selectedUser?.is_admin || '',
            is_learner: selectedUser?.is_learner || '',
            is_host: selectedUser?.is_host || '',
            is_subhost: selectedUser?.is_subhost || '',
            is_client: selectedUser?.is_client || '',
            academy_manager: selectedUser?.academy_manager || '',
            password: selectedUser?.password || '',
          }}
          onSubmit={(values) => editLearnersSubmit(values)}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form>
              <Box noValidate sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit User
                </Typography>
                {console.log(selectedSubHost)}

                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label="  Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.name && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.name[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.email && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.email[0]}
                    </Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                  />
                  {serverError.phone && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.phone[0]}
                    </Typography>
                  )}
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">TC</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="tc"
                      value={values.tc}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Active</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_active"
                      value={values.is_active}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Admin</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_admin"
                      value={values.is_admin}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Learner</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_learner"
                      value={values.is_learner}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Host</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_host"
                      value={values.is_host}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">SubHost</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_subhost"
                      value={values.is_subhost}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Client</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_client"
                      value={values.is_client}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Academy Manager</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="academy_manager"
                      value={values.academy_manager}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                  />
                  {serverError.password && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.password[0]}
                    </Typography>
                  )}
                </Stack>

                <Box textAlign="right">
                  <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    onSubmit={handleSubmit}
                    onClick={(values) => editLearnersSubmit(values)}
                    color="secondary"
                    sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}
                  >
                    Edit User
                  </Button>
                </Box>
                {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Modal Popup */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete User
          </Typography>

          <form onSubmit={handleDeleteModalSubmit} noValidate>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  background: '#bf3f57',
                  padding: '1rem',
                  marginBottom: '.5rem',
                }}
              >
                <AiOutlineDelete size={23} color="#fff" />
              </div>
              <h5>
                {' '}
                Do you want to Delete{' '}
                <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedUser?.name}</span> User
                ?{' '}
              </h5>
            </div>

            <Box textAlign="center">
              <Button onClick={handleDeleteModalClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                Delete
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
//  }

export default AllUsers;
