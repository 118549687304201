/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import {Card,Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Grid, Box, TextField, Modal, CircularProgress, Skeleton, AccordionDetails, AccordionSummary, Accordion, Input,
} from "@mui/material"
import { useGetUserDetailsQuery, useGetUserProfessionalDataQuery, useGetUserProfileDataQuery, useGetUserUploadDataQuery, useNotifyUserMutation } from "../services/userAuthApi"
import { getToken } from "../services/LocalStorageService"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import currentServer from "../_mock/server"


const SelectedProfile = () => {
    // const { getProfileData, getProfessionalData, getUploadData, isSingleLoading, profileDetails, professionalDetails, uploadDetails, isError } = useUserContext()
    const { id } = useParams()
    const [application_status, setApplicationStatus] = useState("")
    const [message, setMessage] = useState("")
    const [openOTP, setOpenOTP] = useState(false)
    const handleOpen = () => setOpenOTP(true);
    const handleClose = () => setOpenOTP(false);
    const navigate = useNavigate()
    const {accessToken} = getToken()
    const [notifyUser, {isLoading}] = useNotifyUserMutation()
    const [serverError, setServerError] = useState({})
    const [userDetails, setUserDetails] = useState([])
    const [profileData, setProfileData] = useState([])
    const [professionalData, setProfessionalData] = useState([])
    const [uploadData, setUploadData] = useState([])
    const [expanded, setExpanded] = useState(false);

    const TITLE = [
        ['None', 'None'],
        ['Mr.', 'Mr.'],
        ['Miss.', 'Miss.'],
        ['Mrs.', 'Mrs.'],
        ['Prof.', 'Prof.'],
        ['Dr.', 'Dr.'],
      ]
      const EMPLOYMENT = [
        [
          'None of these',
          'None of these',
        ],
        [
          'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
          'I am not presently employed in any capacity and do not anticipate becoming employed within the next 12 months.',
        ],
        [
          'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
          'I am not presently employed in any capacity but anticipate becoming employed within the next 12 months, however, I do not yet have a job offer.',
        ],
        ['I am presently employed', 'I am presently employed'],
      ];
      const DISABILITY = [
        ['None of these', 'None of these'],
        ['VI', 'Visual Impairment'],
        ['HI', 'Hearing Impairment'],
        ['MI', 'Mobility Impairment'],
        ['SLI', 'Speech and Language Impairment'],
      ];
    
      const COUNTRY_CHOICES = [
        ['None of these', 'None of these'],
        ['Nigeria', 'Nigeria'],
        ['Ethiopia', 'Ethiopia'],
        ['Egypt', 'Egypt'],
        ['DR Congo', 'DR Congo'],
        ['Tanzania', 'Tanzania'],
        ['South Africa', 'South Africa'],
        ['Kenya', 'Kenya'],
        ['Uganda', 'Uganda'],
        ['Algeria', 'Algeria'],
        ['Sudan', 'Sudan'],
        ['Morocco', 'Morocco'],
        ['Angola', 'Angola'],
        ['Mozambique', 'Mozambique'],
        ['Madagascar', 'Madagascar'],
        ['Cameron', 'Cameron'],
        ["Cote d'Ivoire", "Cote d'Ivoire"],
        ['Niger', 'Niger'],
        ['Burkina Faso', 'Burkina Faso'],
        ['Mali', 'Mali'],
        ['Malawi', 'Malawi'],
        ['Zambia', 'Zambia'],
        ['Senegal', 'Senegal'],
        ['Chad', 'Chad'],
        ['Somalia', 'Somalia'],
        ['Zimbabwe', 'Zimbabwe'],
        ['Guinea', 'Guinea'],
        ['Rwanda', 'Rwanda'],
        ['Benin', 'Benin'],
        ['Burundi', 'Burundi'],
        ['Tunisia', 'Tunisia'],
        ['South Sudan', 'South Sudan'],
        ['Togo', 'Togo'],
        ['Sierra Leone', 'Sierra Leone'],
        ['Libya', 'Libya'],
        ['Congo', 'Congo'],
        ['Liberia', 'Liberia'],
        ['Central African Republic', 'Central African Republic'],
        ['Mauritania', 'Mauritania'],
        ['Eritrea', 'Eritrea'],
        ['Namibia', 'Namibia'],
        ['Gambia', 'Gambia'],
        ['Botswana', 'Botswana'],
        ['Gabon', 'Gabon'],
        ['Lesotho', 'Lesotho'],
        ['Guinea-Bissau', 'Guinea-Bissau'],
        ['Equatorial Guinea', 'Equatorial Guinea'],
        ['Mauritius', 'Mauritius'],
        ['Eswatini', 'Eswatini'],
        ['Djibouti', 'Djibouti'],
        ['Comoros', 'Comoros'],
        ['Cabo Verde', 'Cabo Verde'],
        ['Sao Tome & Principe', 'Sao Tome & Principe'],
        ['Sychelles', 'Sychelles'],
        // ... other country choices
      ];

  const getUserDetails = useGetUserDetailsQuery(  { id, accessToken } )
  const fetchProfileData = useGetUserProfileDataQuery(  { id, accessToken } )
  const fetchProfessionalData = useGetUserProfessionalDataQuery(  { id, accessToken } )
  const fetchUploadData = useGetUserUploadDataQuery(  { id, accessToken } )
  useEffect(() => {
    if(getUserDetails.data && getUserDetails.isSuccess){
        setUserDetails(getUserDetails.data)
    }
  }, [getUserDetails.data, getUserDetails.isSuccess]) 
  useEffect(() => {
    if(fetchProfileData.data && fetchProfileData.isSuccess){
      setProfileData(fetchProfileData.data)
    }
  }, [fetchProfileData.data, fetchProfileData.isSuccess]) 
  useEffect(() => {
    if(fetchProfessionalData.data && fetchProfessionalData.isSuccess){
      setProfessionalData(fetchProfessionalData.data)
    }
  }, [fetchProfessionalData.data, fetchProfessionalData.isSuccess])
  
  useEffect(() => {
    if(fetchUploadData.data && fetchUploadData.isSuccess){
      setUploadData(fetchUploadData.data)
    }
  }, [fetchUploadData.data, fetchUploadData.isSuccess])

//   console.log("fetchProfileData", fetchProfileData, profileData)
//   console.log("fetchProfileData1", fetchProfessionalData, professionalData)
//   console.log("fetchProfileData2", fetchUploadData, uploadData)

    const isApproved = () => {
        setApplicationStatus("Approval");
        handleOpen()
        
    }
    const notApproved = () => {
        setApplicationStatus("Rejection");
        handleOpen()
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleUploadChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        // const fileInput = document.getElementById('idFile');
        //     console.log("fileInput", id_upload)
        //     fileInput.innerHTML =id_upload; // This line will trigger the error.
    };

    const handleNotify = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const actualData = {
          to_user: user,
          application_status: application_status,
          message: data.get('message'),
           
        }
        console.log(actualData)
        const res = await notifyUser({ actualData, accessToken })
        if (res.error) {
            // console.log(typeof (res.error.data.errors))
            console.log("error",res.error)
            setServerError(res.error.data.errors)
        }
        if (res.data){
            // console.log(res.data)
            navigate("/adminpage/userRequests")
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const {id: alias, account_activated, created_at, email, email_verified, image,is_active, is_admin, is_client, is_host, is_learner, last_login, name, password, phone, phone_verified, tc, updated_at} = userDetails
    const {
         user,  title, id_number, date_of_birth, ethnicity, foreign_national, citizenship, disability, gender
    } = profileData;
    const {
        // id: alias, user, title, id_number, date_of_birth, ethnicity, foreign_national, citizenship, disability, gender, tax_number, employment_status, home_language, street_address, residential_area,city, province, postal_code, postal_address, emergency_number, whatsapp_number, last_school, last_school_year, school_highest_grade, id_upload, cv_upload, bank_confirmation_upload, highest_education_certificate, sars_document_upload, disability_document, special_power_attorney,
        tax_number, employment_status, home_language, street_address, residential_area,city, province, postal_code, postal_address, emergency_number, whatsapp_number, last_school, last_school_year, school_highest_grade
    } = professionalData;
    const {
         id_upload, cv_upload, bank_confirmation_upload, highest_education_certificate, sars_document_upload, disability_document, special_power_attorney
    } = uploadData;

    // console.log("error", isError)
    if (fetchProfileData.isLoading){
    //   return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading...</h1>)
      return(<Stack spacing={1} mt={10} mx={10}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="rectangular" width={210} height={100} />
        <Skeleton variant="rounded" width={210} height={100} />
      </Stack>)
    }  
    
    // if (fetchProfileData.isError){
    //   return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>No Data Found for the Selected User...</h1>)
    // }else{

    const PersonalDetails = () =>  {
        if (fetchProfileData.isError) {
          return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Profile Data was found. Please Inform Learner to update...
            </Typography>
          )
        }else if (fetchProfessionalData.isLoading){
          return (
            <p>Loading...</p>
          )
        }
        else if (fetchProfessionalData.isSuccess){
          return (
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Your Personal details:
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          Your Personal details: 
                          </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Title</Typography>
                            <TextField fullWidth value={title} id="title" select name="title" SelectProps={{ native: true, }} onChange={(e) => setTitle(e.target.value)}>
                            {TITLE.map(([value, label]) => (<option key={value} value={value}>{label}</option>))}</TextField>
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Id Number</Typography>
                            <TextField value={id_number} required fullWidth id='id_number' name='id_number' onChange={(e) => setIdnumber(e.target.value)}/>
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Date of Birth</Typography>
                            <TextField value={date_of_birth} fullWidth id="date_of_birth" name="date_of_birth" type="date" InputLabelProps={{ shrink: true, }} onChange={(e) => setDob(e.target.value)} />
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Ethnicity</Typography>
                            <TextField value={ethnicity} required fullWidth id='ethnicity' name='ethnicity' onChange={(e) => setEthnicity(e.target.value)}/>
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Citizenship</Typography>
                            <TextField fullWidth value={citizenship} id="citizenship" select name="citizenship" SelectProps={{native: true, }} onChange={(e) => setCitizenship(e.target.value)}>          
                            {COUNTRY_CHOICES.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Disability</Typography>
                            <TextField fullWidth value={disability}  id="disability" select name="disability" SelectProps={{native: true, }} onChange={(e) => setDisability(e.target.value)}>          
                            {DISABILITY.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>
    
                            <Typography sx={{ color: 'text.secondary', mt:2  }}>Gender</Typography>
                            <TextField id="gender" value={gender} required fullWidth  onChange={(e) => setGender(e.target.value)} />
                            <Box textAlign='center'>
                                <input type='checkbox'  sx={{ mt: 3, mb: 2}}/>
                                <span>  Mark Checked</span>
                            </Box>
                    </AccordionDetails>
                </Accordion>
          )
        }
      }
    
      const ProfessionalDetails = () =>  {
        if (fetchProfessionalData.isError) {
          return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Professional Data was found. Please Inform Learner to update...
            </Typography>
          )
        }else if (fetchProfessionalData.isLoading){
          return (
            <p>Loading...</p>
          )
        }
        else if (fetchProfessionalData.isSuccess){
          return (
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          Your Professional Details:
                          </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Your Professional Details:
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Tax Number</Typography>
                        <TextField value={tax_number} id="tax_number" required fullWidth  onChange={(e) => setTax(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Employment Status</Typography>
                        <TextField value={employment_status} id="employment_status" fullWidth select name="employment_status" SelectProps={{native: true, }} onChange={(e) => setEmployment(e.target.value)}>          
                        {EMPLOYMENT.map(([value, label]) => (<option key={value}value={value}>{label}</option>))}</TextField>
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Home Language</Typography>
                        <TextField value={home_language} id="home_language"  required fullWidth onChange={(e) => setHomeLanguage(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Street Address</Typography>
                        <TextField value={street_address} id="street_address" required fullWidth onChange={(e) => setStreet(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Residential Area</Typography>
                        <TextField value={residential_area} id="residential_area" required fullWidth onChange={(e) => setResidentalArea(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>City</Typography>
                        <TextField value={city} id="city" required fullWidth  onChange={(e) => setCity(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Province</Typography>
                        <TextField value={province} id="province" required fullWidth  onChange={(e) => setProvince(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Postal Code</Typography>
                        <TextField value={postal_code} id="postal_code" required fullWidth  onChange={(e) => setPostalCode(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Postal Address</Typography>
                        <TextField value={postal_address} id="postal_address" required fullWidth  onChange={(e) => setPostalAddress(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Emergency Number</Typography>
                        <TextField value={emergency_number} id="emergency_number" required fullWidth  onChange={(e) => setEmergencyNumber(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Whatsapp Number</Typography>
                        <TextField value={whatsapp_number} id="whatsapp_number" required fullWidth  onChange={(e) => setWhatsappNumber(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Last School</Typography>
                        <TextField value={last_school} id="last_school" required fullWidth onChange={(e) => setLastSchool(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>Last School Year</Typography>
                        <TextField value={last_school_year} id="last_school_year" required fullWidth  onChange={(e) => setLastSchoolYear(e.target.value)} />
    
                        <Typography sx={{ color: 'text.secondary', mt:2  }}>School Highest Grade</Typography>
                        <TextField value={school_highest_grade} id="school_highest_grade" required fullWidth  onChange={(e) => setSchoolHighestGrade(e.target.value)} />

                        <Box textAlign='right'>
                            <input type='checkbox'  sx={{ mt: 3, mb: 2}}/>
                            <span>  Mark Checked</span>
                        </Box>
                        
                    </AccordionDetails>
                </Accordion>
          )
        }
      }
    
      const UploadDetails = () =>  {
        if (fetchUploadData.isError) {
          return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Upload Data was found. Please Inform Learner to update...
            </Typography>
          )
        }else if (fetchUploadData.isLoading){
          return (
            <p>Loading...</p>
          )
        }
        else if (fetchUploadData.isSuccess){
          return (
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Your Files:
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Your Files:
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails> 
                    {/* <Stack direction="row" spacing={10}>
                    <Typography sx={{ color: 'text.secondary' }}>ID</Typography>
                    <Link target="_blank" to={`${id_upload}`} >View</Link>
                    </Stack>
                    
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>CV:</Typography>
                    <Link sx={{justifyContent:"right"}} target="_blank" to={`${cv_upload}`} >View</Link>
                    
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Bank Confirmation Upload</Typography>
                    <Link target="_blank" to={`${id_upload}`} >bank_confirmation_upload</Link> */}
                    <table className="table table-primary table-borderless">
                            {/* <table className="table"> */}
                        
                            <thead>
                                <tr>
                                    <th scope="col">Document Type</th>
                                    <th scope="col">File</th>
                                    {/* <th scope="col">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ID:</td>
                                    <td ><Link target="_blank" to={`${id_upload}`} >View</Link>
                                    {/* <td><p style={{alignContent: "right"}} margin='normal'   id="idFile" ></p> */}
                                    </td>
                                    {/* <th><Button variant="contained" color="warning" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                </tr>
                                <tr>
                                    <td>CV:</td>
                                    <td ><Link target="_blank" to={`${cv_upload}`} >View</Link></td>
                                    {/* <th><Button variant="contained" color="grey" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                </tr>
                                <tr>
                                    <td>Bank Confirmation</td>
                                    <td ><Link target="_blank" to={`${bank_confirmation_upload}`} >View</Link></td>
                                    {/* <th><Button variant="contained" color="info" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                </tr>
                                <tr>
                                    <td>Highest Education Certificate</td>
                                    <td ><Link target="_blank" to={`${highest_education_certificate}`} >View</Link></td>
                                    {/* <th><Button variant="contained" color="warning" size="large" onClick={handleDiscard} sx={{ mt: 8 }} >Discard</Button></th> */}
                                    {/* <th><Button variant="contained" color="warning" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                </tr> 
                                <tr>
                                    <td>Sars Document</td>
                                    <td ><Link target="_blank" to={`${sars_document_upload}`} >View</Link></td>
                                    {/* <th><Button variant="contained" color="primary" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                </tr> 
                                <tr>
                                    <td>Disability Document</td>
                                    <td ><Link target="_blank" to={`${disability_document}`} >View</Link></td>
                                    {/* <td><Button variant="contained" color="success" size="medium" osx={{ mt: 8 }} >Discard</Button></td> */}
                                </tr> 
                                <tr>
                                    <td>Special Power Attorney</td>
                                    <td ><Link target="_blank" to={`${special_power_attorney}`} >View</Link></td>
                                    {/* <th><Button variant="contained" color="grey" size="medium" osx={{ mt: 8 }} >Discard</Button></th> */}
                                    {/* <th><div className="form-check form-switch"><input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"></input></div></th> */}
                                </tr>
                            </tbody>
                        </table>
                        <Box textAlign='right'>
                            <input type='checkbox'  sx={{ mt: 3, mb: 2}}/>
                            <span>  Mark Checked</span>
                        </Box>
                    </AccordionDetails>
                </Accordion>
          )
        }
      }
    



    return (<>
            <Grid container >
            <Grid item sm={9}  mb={4} mx="auto"
            sx={{ backgroundColor: "#cfd8dc", p: 2, color: "black", border: "rounded", borderRadius: "25px" }}
            >
            {/* <Item>  */}
            <Stack direction="row" spacing={2}>
            <Avatar
                alt="Remy Sharp"
                src={`${image}`}
                sx={{ width: 80, height: 80 , mb:2, mt:2, ms:10}}
            />
            <Typography variant='h2' sx={{ flexShrink: 0 }}>
                        Name:  {name}
            </Typography>
            </Stack>
            </Grid>

            <Grid item sm={10} mx="auto" mb={2}>
            <Box sx={{  flexShrink: 0 }} >
                {/* <Typography variant='h4' sx={{  flexShrink: 0 }}>
                    Learners Data:
                </Typography> */}
            </Box>
            <Box  sx={{ p: 1 }}  >
            <PersonalDetails/>
            <ProfessionalDetails/>
            <UploadDetails/>
            </Box>
        </Grid>
        </Grid>
        <Grid container>
        <Grid
            item
            sm={10}
            // mx="auto"
            textAlign='right'
            sx={{ backgroundColor: "info", mx: "auto", color: "white" }}
                >
                    <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    onClick={notApproved}
                    sx={{ mt: 2 }}
                >
                    Reject
                </Button>
                
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ mt: 2, mx:4 }}
                    onClick={isApproved}
                >
                    Approve
                </Button>
            </Grid>

        {/* OTP modal */}
    <Modal
        component="form"
            open={openOTP}
            onClose={handleClose}
            id='notify-form' 
            onSubmit={handleNotify}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // style={{backgrou}}
      >
        <Box
         noValidate
         sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Notify User Regarding {application_status} of their application
          </Typography>
          {/* <label htmlFor="phone_otp" className="col-form-label">OTP on Phone:</label>
          <input type="text" className="form-control" id="phone_otp" name="phone_otp" /> */}
          {/* <TextField margin='normal' required fullWidth id='phone_otp' name='phone_otp' label='Phone OTP'  onChange={(phone_otp) => {}} /> */}
          {/* {serverError.phone_otp ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone_otp[0]}</Typography> : ""} */}
          {/* <label htmlFor="email_otp" className="col-form-label">OTP on Email:</label>
          <input type="text" className="form-control" id="email_otp" name="email_otp"/>  */}
          <TextField margin='normal' required fullWidth id='message' name='message' label='Describe ...' onChange={(message) => {setMessage(message)}}/>
          {/* {serverError.email_otp ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.email_otp[0]}</Typography> : ""} */}
          <Box textAlign='right'>
            <Button onClick={handleClose} variant='contained' color='primary' sx={{ mt: 3, mb: 2, px: 5,  }}>Cancel</Button>
            {isLoading ? <CircularProgress /> : <Button type='submit' variant='contained'color='secondary' sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>Notify User</Button>}
          </Box> 
          {serverError.non_field_errors ? <Alert severity='error'>{serverError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>
            </Grid>
</>)}
// }

export default SelectedProfile

