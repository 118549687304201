import { Alert, Button } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'

import Iconify from '../components/iconify'
import { getToken } from '../services/LocalStorageService'
import { useSaveLocationMutation } from '../services/attendanceApi'

const HostSettings = () => {
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const { accessToken } = getToken()
    const [output, setOutput] = useState(false)
    const [color, setColor] = useState("error")
    const [message, setMessage] = useState("")
    const [saveLocation, { isLoading }] = useSaveLocationMutation()
    const geo = navigator.geolocation

    // Get user Current Location
    geo.getCurrentPosition(userCoords)
    function userCoords (position) {
        const userLatitude = position.coords.latitude 
        const userLongitude = position.coords.longitude  
        // console.log("userLatitude", userLatitude)
        // console.log("userLongitude", userLongitude)
        setLatitude(userLatitude)
        setLongitude(userLongitude)
    }

    const saveHostLocation = async () => {
        console.log("userLatitude", latitude)
        console.log("userLongitude", longitude)
        const data = {
            "lat": latitude,
            "lng": longitude
        }
        const res = await saveLocation({data, accessToken})

        // console.log("success", res)
        if (res.error) {
          setOutput(true)
          setMessage("Failed to save location...")
          setColor("error")
        }
        if (res.data) {
          setOutput(true)
          setMessage("Location saved successfully...")
          setColor("success")
         }
         
    }

  return (
    <>
    <h1>HostSettings</h1>
    <h2>My Current Location: {latitude}, {longitude}</h2>
    <Button variant="contained" onClick={saveHostLocation} startIcon={<Iconify icon="eva:plus-fill" />}>
            Save Location
          </Button>
          {output ? <Alert severity={color}>{message}</Alert>: ""}
    {/* <h2>My Latitude: {latitude}</h2>
    <h2>My Longitude: {longitude}</h2> */}
    </>
  )
}

export default HostSettings