// const account = {
//     loca: 'Jaydon Frankie',
//     email: 'demo@minimals.cc',
//     photoURL: '/assets/images/avatars/avatar_default.jpg',
//   };

  // const currentServer = 'http://40.127.11.109:8000';
  // const currentServer = "http://api.natiive.in"
  // const currentServer = 'http://127.0.0.1:8000/';
  const currentServer = 'https://darkafrica.co.za/lms';
  
  export default currentServer;
  