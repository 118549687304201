/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Input,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../features/userSlice';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getToken } from '../services/LocalStorageService';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import {
  useCreateClientMutation,
  useCreateHostMutation,
  useDeleteClientMutation,
  useGetAllUsersQuery,
  useGetClientListQuery,
  useGetClientViewMutation,
  useGetClientViewQuery,
  useGetLoggedUserQuery,
  useUpdateClientMutation,
} from '../services/userAuthApi';
import currentNetwork from '../_mock/network';
import { Formik, Form, Field } from 'formik';
import { AiOutlineDelete } from 'react-icons/ai';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllClients = () => {
  const [clients, setClients] = useState([]);
  const [createClient, { isLoading }] = useCreateClientMutation();
  // console.log( activeUsers, verifiedUsers)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = getToken();

  const getClientList = useGetClientListQuery(accessToken);

  const [open, setOpen] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [qualification, setQualification] = useState('');
  const [dob, setDob] = useState('');
  const [sponsor, setSponsor] = useState('false');
  const [hostingProvider, setHostingProvider] = useState('false');
  const [projectProvider, setProjectProvider] = useState('false');
  const [updateClient] = useUpdateClientMutation();
  const [deleteCient] = useDeleteClientMutation()

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [serverError, setServerError] = useState({});
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getAllUsers = useGetAllUsersQuery(accessToken);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (getAllUsers.data && getAllUsers.isSuccess) {
      var clientData = getAllUsers.data.filter((curElem) => {
        return curElem.is_client === true;
      });
        //setClients(clientData);
      setAllUsers(getAllUsers.data);
    }
  }, [getAllUsers.data, getAllUsers.isSuccess]);

   useEffect(() => {
      if (getClientList.data && getClientList.isSuccess) {
        setClients(getClientList.data);
      }
    }, [getClientList.data, getClientList.isSuccess]); 

  const handleSponsor = (event) => {
    setSponsor(event.target.value);
  };

  const handleHostingProvider = (event) => {
    setHostingProvider(event.target.value);
  };

  const handleProjectProvider = (event) => {
    setProjectProvider(event.target.value);
  };

  const [selectedClient, setSelectedClient] = useState([]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedClient(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpen = () => setOpenOTP(true);
  const handleClose = () => setOpenOTP(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleCreateHost = () => {
    handleOpen();
  };
  const submitClient = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const actualData = {
      name: data.get('name'),
      company_name: data.get('company_name'),
      email: data.get('email'),
      phone: data.get('phone'),
      ngo: data.get('ngo'),
      registration_no: data.get('registration_no'),
      vat_no: data.get('vat_no'),
      // client: data.get('client'),
      sponsor: sponsor,
      hosting_provider: hostingProvider,
      project_provider: projectProvider,
    };
    console.log(actualData);
    const res = await createClient({ actualData, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      setServerError(res.error.data.errors);
    }
    if (res.data) {
      console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleClose();

      // navigate('/adminpage/clients')
      window.location.reload();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (getAllUsers.isLoading) {
    <Stack spacing={1}>
      <h1 className="mt-4">Loading</h1>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>;
  }

  //  if (getAllUsers.isLoading){
  //    return(<h1 className="text-center" mt={10} mx={10}><br/><br/><br/>Loading User...</h1>)
  //  }

  // if (getAllUsers.isError) {
  //   return (
  //     <h1 className="text-center" mt={10} mx={10}>
  //       <br />
  //       <br />
  //       <br />
  //       No User Data Found...
  //     </h1>
  //   );
  // } else {

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

   

  const [editModal, setEditModal] = useState(false);
  const handleEditClose = () => setEditModal(!editModal);
  const handleDeleteModalClose = () => setDeleteModal(!deleteModal);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalSubmit = async (e) => {
    e.preventDefault();
    const actualData = {};
    const id = selectedClient?.id;
    console.log('data from update host:', actualData);

    const res = await deleteCient({ actualData, id, accessToken });
    if (res.error) {
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)
      window.location.reload();
      handleDeleteModalClose();
    }
  };

  // Get all users and filter subhost

  const editClientSubmit = async (values) => {
    const actualData = {
      user: values?.user || '',
      company_name: values?.company_name || '',
      client_type: values?.client_type || '',
      date_of_birth: values?.date_of_birth || '',
      ngo: values?.ngo || '',
      registration_no: values?.registration_no || '',
      vat_no: values?.vat_no || '',
      bee_level: values?.bee_level || '',
      address: values?.address || '',
      qualification: values?.qualification || '',
      bio: values?.bio || '',
      learners: values?.learners || '',
      no_of_host: values?.no_of_host || '',
      sponsor: values?.sponsor || '',
      hosting_provider: values?.hosting_provider || '',
    };
    const id = selectedClient?.id;
    console.log('data from update learner:', actualData);
   
    const res = await updateClient({ actualData, id, accessToken });
    if (res.error) {
      // console.log(typeof (res.error.data.errors))
      console.log('error', res.error);
      // setServerError(res.error.data.errors);
    }
    if (res.data) {
      // console.log(typeof res.data);
      console.log('data', res.data);
      // console.log("token", res.data.token)

      handleEditClose();

        window.location.reload();
      // navigate('/adminpage/hosts');
    }
  };
  return (
    <>
      <Helmet>
        <title> Clients | Y-Plan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Clients
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateHost}>
            New Client
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={clients.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    const {
                      id,
                      name,
                      email,
                      image,
                      phone,
                      is_active,
                      is_admin,
                      is_host,
                      is_learner,
                      account_activated,
                      user,
                    } = row;
                    const isActive = String(is_active);
                    const accountActivated = String(account_activated);
                    const selectedUser = selected.indexOf(name) !== -1;
                    if (is_admin) {
                      var role = 'Admin';
                    } else if (is_learner) {
                      var role = 'Learner';
                    } else if (is_host) {
                      var role = 'Host';
                    }
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          {/* <NavLink to={`http://${currentNetwork}/adminpage/clients/${id}`}> */}
                          <NavLink to={`/adminpage/clients/${id}`}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={user.name} src={user.image} />
                              <Typography variant="subtitle2" sx={{ color: 'black' }} noWrap>
                                {user.name}
                              </Typography>
                            </Stack>
                          </NavLink>
                        </TableCell>

                        <TableCell align="left">{user.phone}</TableCell>

                        <TableCell align="left">{user.email}</TableCell>

                        {/* <TableCell align="left">Client</TableCell> */}

                        <TableCell align="left">
                          <Label color={(accountActivated === 'banned' && 'error') || 'success'}>
                            {sentenceCase(accountActivated)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {getAllUsers.isError && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            {/* <strong>&quot;{filterName}&quot;</strong>. */}
                            <strong>&quot;Clients&quot;</strong>.
                            <br /> Try to Log In again with an Admin Id...
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={clients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setEditModal(!editModal)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteModal(!deleteModal)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* Create Client Modal  */}
      <Modal
        component="form"
        open={openOTP}
        onClose={handleClose}
        id="otp-form"
        onSubmit={submitClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{backgrou}}
      >
        <Box noValidate sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a Client
          </Typography>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="name" name="name" label="Name" />
            {serverError.name ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
            ) : (
              ''
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="company_name"
              name="company_name"
              label="Name of Company"
            />
            {serverError.name ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="email" name="email" label="Email" />
            {serverError.email ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phoneOtp[0]}</Typography>
            ) : (
              ''
            )}
            <TextField margin="normal" required fullWidth id="phone" name="phone" label="Phone" />
            {serverError.phone ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.phone[0]}</Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row">
            <TextField
              margin="normal"
              required
              fullWidth
              id="registration_no"
              name="registration_no"
              label="Registration No."
            />
            {serverError.registration_no ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                {serverError.registration_no[0]}
              </Typography>
            ) : (
              ''
            )}
            <TextField margin="normal" required fullWidth id="vat_no" name="vat_no" label="Vat No." />
            {serverError.vat_no ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.vat_no[0]}</Typography>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction="row">
            <TextField margin="normal" required fullWidth id="ngo" name="ngo" label="NGO" />
            {serverError.ngo ? (
              <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.ngo[0]}</Typography>
            ) : (
              ''
            )}
            {/* <TextField type="checkbox" margin='normal' required halfWidth id='name' name='name' label='asdf' />
          {serverError.name ? <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>{serverError.name[0]}</Typography> : ""}  */}
          </Stack>

          <Stack margin="normal" direction="row">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">Sponsor</InputLabel>
              <Select
                native
                value={sponsor}
                onChange={handleSponsor}
                input={<OutlinedInput label="Sponsor" id="demo-dialog-native" />}
              >
                {/* <option aria-label="None" value="" /> */}
                {/* <option value="unknown">Unknown</option> */}
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
            {/* </Stack>

            <Stack margin="normal" direction="row"> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">Hosting Provider</InputLabel>
              <Select
                native
                value={hostingProvider}
                onChange={handleHostingProvider}
                input={<OutlinedInput label="Hosting Provider" id="demo-dialog-native" />}
              >
                {/* <option aria-label="None" value="" /> */}
                {/* <option value="unknown">Unknown</option> */}
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
            {/* </Stack>           
            
            <Stack margin="normal" direction="row"> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">Project Provider</InputLabel>
              <Select
                native
                value={projectProvider}
                onChange={handleProjectProvider}
                input={<OutlinedInput label="Project Provider" id="demo-dialog-native" />}
              >
                {/* <option aria-label="None" value="" /> */}
                {/* <option value="unknown">Unknown</option> */}
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
          </Stack>

          {/* <Stack direction="row">
              <div>
                <input type="checkbox" name="sponsor" sx={{ mt: 3, mb: 2, mx: 2 }} />
                <span> Sponsor?</span>
              </div>
              <div>
                <input type="checkbox" name="hostingProvider" sx={{ mt: 3, mb: 2, mx: 2 }} />
                <span> Hosting Provider?</span>
              </div>
              <div>
                <input type="checkbox" name="projectProvider" sx={{ mt: 3, mb: 2, mx: 2 }} />
                <InputLabel htmlFor="demo-dialog-native">Age</InputLabel>
              </div>
            </Stack> */}

          <Box textAlign="right">
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
              Cancel
            </Button>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 2 }}>
                Create Client
              </Button>
            )}
          </Box>
          {serverError.non_field_errors ? <Alert severity="error">{serverError.non_field_errors[0]}</Alert> : ''}
        </Box>
      </Modal>

      {/* /////////////////////////////////////////////////////////////////////////////// */}

      {/* Edit host Modal  */}
      <Modal
        open={editModal}
        onClose={handleEditClose}
        id="otp-form"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Formik
          initialValues={{
            user: selectedClient?.user || '',
            company_name: selectedClient?.company_name || '',
            client_type: selectedClient?.client_type || '',
            date_of_birth: selectedClient?.date_of_birth || '',
            ngo: selectedClient?.ngo || '',
            registration_no: selectedClient?.registration_no || '',
            vat_no: selectedClient?.vat_no || '',
            bee_level: selectedClient?.bee_level || '',
            address: selectedClient?.address || '',
            qualification: selectedClient?.qualification || '',
            bio: selectedClient?.bio || '',
            learners: selectedClient?.learners || '',
            no_of_host: selectedClient?.no_of_host || '',
            sponsor: selectedClient?.sponsor || '',
            hosting_provider: selectedClient?.hosting_provider || '',
          }}
          onSubmit={(values) => editClientSubmit(values)}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Box noValidate sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Client
                </Typography>
                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="user"
                      value={values.user}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {allUsers.map((academy) => (
                        <MenuItem key={academy.id} value={academy.id}>
                          {academy.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="client_type"
                    name="client_type"
                    label="Client Type"
                    value={values.client_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.client_type && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.client_type[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    id="date_of_birth"
                    name="date_of_birth"
                    label="Date Of Birth"
                    value={values.date_of_birth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: new Date().toISOString().split('T')[0],
                      },
                    }}
                  />
                  {serverError.date_of_birth && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.date_of_birth[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="ngo"
                    name="ngo"
                    label="NGO"
                    value={values.ngo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.ngo && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.ngo[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="registration_no"
                    name="registration_no"
                    label="Registration no"
                    value={values.registration_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.registration_no && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.registration_no[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="vat_no"
                    name="vat_no"
                    label="Vat no"
                    value={values.vat_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.vat_no && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.vat_no[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bee_level"
                    name="bee_level"
                    label="Bee level"
                    value={values.bee_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.bee_level && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.bee_level[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.address && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.address[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="qualification"
                    name="qualification"
                    label="Qualification"
                    value={values.qualification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.qualification && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.qualification[0]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bio"
                    name="bio"
                    label="Bio"
                    value={values.bio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.bio && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.bio[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="learners"
                    name="learners"
                    label="Learners"
                    value={values.learners}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.learners && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.learners[0]}
                    </Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Sponsor</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="sponsor"
                      value={values.sponsor}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth mx={2}>
                    <InputLabel id="demo-simple-select-label">Hosting Provider</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="hosting_provider"
                      value={values.hosting_provider}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="no_of_host"
                    name="no_of_host"
                    label="No Of host"
                    value={values.no_of_host}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.no_of_host && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.no_of_host[0]}
                    </Typography>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="company_name"
                    name="company_name"
                    label="Company Name"
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {serverError.company_name && (
                    <Typography style={{ fontSize: 12, color: 'red', paddingLeft: 10 }}>
                      {serverError.company_name[0]}
                    </Typography>
                  )}
                </Stack>

                <Box textAlign="right">
                  <Button onClick={handleEditClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                    Cancel
                  </Button>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                      Edit Client
                    </Button>
                  )}
                </Box>
                {serverError.non_field_errors && <Alert severity="error">{serverError.non_field_errors[0]}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Modal Popup */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: 'none' }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Client
          </Typography>

          <form onSubmit={handleDeleteModalSubmit} noValidate>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  background: '#bf3f57',
                  padding: '1rem',
                  marginBottom: '.5rem',
                }}
              >
                <AiOutlineDelete size={23} color="#fff" />
              </div>
              <h5>
                {' '}
                Do you want to Delete{' '}
                <span style={{ textDecoration: 'underline', color: '#bf3f57' }}>{selectedClient?.name}</span> Sub-Host ?{' '}
              </h5>
            </div>

            <Box textAlign="center">
              <Button onClick={handleDeleteModalClose} variant="contained" color="primary" sx={{ mt: 3, mb: 2, px: 5 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, px: 5, mx: 4 }}>
                Delete
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
// };

export default AllClients;
