/* eslint-disable */
import React, { useState } from 'react'
import about1 from "../images/about/About1.jpeg"
import about2 from "../images/about/About2.jpeg"
import about3 from "../images/about/About3.jpeg"
import about4 from "../images/about/About4.jpeg"
import about5 from "../images/about/About5.jpeg"
import { Container, Paper } from '@mui/material'
import Footer from './Footer'
import { Helmet } from 'react-helmet-async'
import { AboutView } from '../sections/about/view'

const AboutUs = () => {

    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()

    const geo = navigator.geolocation

    // Get user Current Location
    geo.getCurrentPosition(userCoords)
    function userCoords (position) {
        const userLatitude = position.coords.latitude 
        const userLongitude = position.coords.longitude 
        // console.log("userLatitude", userLatitude)
        // console.log("userLongitude", userLongitude)
        setLatitude(userLatitude)
        setLongitude(userLongitude)
    }
  return (
    <>
    <Helmet>
        <title> About us</title>
      </Helmet>
      <AboutView />

    {/* <Container component="main" maxWidth="md" sx={{ mb: 1 }}>
    <h1>HostSettings</h1>
    <h2>My Current Location: {latitude}, {longitude}</h2>

    <Paper variant="outlined" sx={{  p: { xs: 2, md: 3 } }}>
        <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner" style={{alignContent: "center"}}>
            <div className="carousel-item active">
            <img src={about1} className="d-block w-70" alt="..." style={{height: "700px", alignContent: "center"}}/>
            </div>
            <div className="carousel-item">
            <img src={about2} className="d-block w-70" alt="..." />
            </div>
            <div className="carousel-item">
            <img src={about3} className="d-block w-70" alt="..." />
            </div>
            <div className="carousel-item">
            <img src={about4} className="d-block w-70" alt="..." />
            </div>
            <div className="carousel-item">
            <img src={about5} className="d-block w-70" alt="..." />
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
            <span className="visually-hidden" >Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" style={{color: "gray"}}></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>
        </Paper>
        </Container> */}
        {/* <Footer/> */}
    </>
  )
}

export default AboutUs