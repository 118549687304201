/* eslint-disable */
import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Webcam from "react-webcam";

import { setUserInfo } from '../features/userSlice'
import { getToken } from '../services/LocalStorageService'
import { useFetchProfessionalDetailsQuery, useFetchProfileDataQuery, useFetchUploadDetailsQuery, useGetLoggedUserQuery, useHostsLearnersQuery, useUpdateMyDetailsMutation, useUpdateUserDetailsMutation, useUpdateUserProfessionalDataQuery, useUpdateUserProfileDataQuery, useUploadImageMutation } from '../services/userAuthApi'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CircularProgress, Grid, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Stack, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Link } from 'react-router-dom'
import { Image } from '@mui/icons-material'
import EditForm from '../Learner/EditForm'
import currentServer from '../_mock/server'
import CustomBreadcrumbs from '../components/custom-breadcrumbs/custom-breadcrumbs';
import ProfileCover from '../sections/user/profile-cover';
import { Controller, useForm } from 'react-hook-form';
import Iconify from '../components/iconify';

const style = {
    position: 'absolute',
    overflow: 'scroll',
    height: 800,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'learners',
    label: 'Learners',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  
];
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};
const HostProfile = () => {


  const dispatch = useDispatch()
  const {accessToken} = getToken()
  const { data, isSuccess, isError } = useGetLoggedUserQuery(accessToken)
  const [updateMyDetails] = useUpdateMyDetailsMutation()
  const [openEdit, setOpenEdit] = useState(false)

  const [userData, setUserData] = useState({})
  const [profileData, setProfileData] = useState([])

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);
  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const [myLearners, setMyLearners] = useState([])
  const getMyLearners = useHostsLearnersQuery(accessToken)

  useEffect(() => {
    if (getMyLearners.data && getMyLearners.isSuccess) {
      setMyLearners(getMyLearners.data)
    }
  }, [getMyLearners.data, getMyLearners.isSuccess])
  
  const [fielda, setField] =  useState({
    // ...field,
    name: "", 
    email: "",
    phone: "",
    image: "",
  })
  // Store User Data in Local State
  useEffect(() => {
    if (data  &&  isSuccess) {
        setUserData( data.data)
        setField({
           
            email: data.email,
            name: data.name,
            phone: data.phone,
            image: data.image,
        })
    }
  }, [data ,  isSuccess])

  // Store User Data in Redux Store
  useEffect(() => {
    if ( data &&  isSuccess) {
        dispatch(
            setUserInfo(  data )
        )
    }
  }, [ data,  isSuccess, dispatch])
//   console.log("profileData", userData)
// const {id, account_activated, created_at, email, email_verified, image,is_active, is_admin, is_client, is_host, is_learner, last_login, name, password, phone, phone_verified, tc, updated_at} = userData
// console.log("🚀 ~ file: MyProfile.js:194 ~ MyProfile ~ userData:", userData)
 
  const [expanded, setExpanded] = useState(false);
  const [uploadImage, {isLoading}] = useUploadImageMutation()
    const [profileImage, setProfileImage] = useState("")

  const [error, setError] = useState({
      status: false,
      msg: "",
      type: ""
  })
  const [updateError, setUpdateError] = useState({})

  const handleOpen = () => setOpenEdit(true);
  const handleClose = () => setOpenEdit(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const handleUploadChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    // const fileInput = document.getElementById('idFile');
        // console.log("fileInput", id_upload)
        // fileInput.innerHTML =id_upload; // This line will trigger the error.
  };

  const handleProfilePic = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('image', profileImage)
    if (profileImage){  
        const res = await uploadImage({data, accessToken})

        // console.log(res)
        // console.log(res.data)
        // console.log(res.error)
        if (res.data) {
        //   console.log("data")
            setError({ status: true, msg: "Image Uploaded Successfully", type: 'success' })
            // resetForm()
            // navigate("/requestPending")
        }else if (res.error) {
        //   console.log("error1", res.error)
          setError({ status: true, msg: res.error.data.errors, type: 'error' })
        }else  {
        //   console.log("error2", res.error)
          setError({ status: true, msg: "Image Not Selected", type: 'error' })
        }
      }
    }
    
  const PersonalDetails = () => {
    const { control, handleSubmit, setValue } = useForm()
      
    const onSubmit = async (data) => {
      console.log(data);
      const res = await updateMyDetails({data, accessToken});
      if (res.error) {
        // setServerError(res.error.data.errors);
        // console.log(res.error);
        setUpdateError(res.error.data.errors);
      }
      if (res.data) {
        // console.log(typeof res.data);
        console.log(res.data);
        window.location.reload();
      }
    };

    if (isError) {
        return (
          <Typography variant='h6' sx={{ flexShrink: 0 }}>
                      No Upload Data was found. Please Inform Learner to update...
          </Typography>
        )
      }else if (isLoading){
        return (
          <p>Loading...</p>
        )
      }
      else if (isSuccess){
      return (
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Personal Details
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>Your Personal details: </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Typography sx={{ color: 'text.secondary', mt:2  }}>Name</Typography>
                  <Controller 
                    control={control}
                    name="name"
                    defaultValue={fielda.name}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <TextField  {...field} fullWidth  margin='normal' id="name" name="name" /> 
                )}
                />
                    <Typography sx={{ color: 'text.secondary', mt:2  }}>Email</Typography>
                  <Controller 
                    control={control}
                    name="email"
                    defaultValue={fielda.email}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  // <TextField value={fielda.email} fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }} onChange={(e) => setField({...field, email: e.target.value})}/>
                  <TextField  {...field}  fullWidth id="email" name="email" InputLabelProps={{ shrink: true, }}/>
                )}
                />
                  <Typography sx={{ color: 'text.secondary', mt:2  }}>Phone</Typography>
                  <Controller 
                    control={control}
                    name="phone"
                    defaultValue={fielda.phone}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField {...field}   fullWidth id="phone" name="phone" InputLabelProps={{ shrink: true, }} />
                )}
                />
                  <Box textAlign='center'>
                      <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Save</Button>
                  </Box>
                {updateError.non_field_errors ? <Alert severity='error'>{updateError.non_field_errors[0]}</Alert> : ''}
                  </form>
              </AccordionDetails>
          </Accordion>
        )
      }
    }
  const LearnersDetails = () =>{
    if (getMyLearners.isError) {
        return (
            <Typography variant='h6' sx={{ flexShrink: 0 }}>
                        No Upload Data was found. Please Inform Learner to update...
            </Typography>
        )
    }else if (getMyLearners.isLoading){    
        return (
            <p>Loading...</p>
        )
    }else if (getMyLearners.isSuccess){
        return (
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel2bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Learners Details
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}> Learners details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* LearnersList: {academysLearners.map((Learners) => (<p key={Learners.id} value={Learners.id}>{Learners.user}</p>))} */}
                <List>
                    {myLearners?.map((Learners) => (
                        <ListItem key={Learners.id} disablePadding sx={{ display: "block" }}>
                        <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                            {/* {Learners.id % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                            <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary={Learners.id} secondary={Learners.user.email}  sx={{ opacity: open ? 1 : 0 }} >  {Learners.user}       </ListItemText>
                        </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
        )
    }
  }


  return (
    <>
       <Grid container>
      
      <Grid item sm={10}  mb={4} mx="auto">
        <CustomBreadcrumbs
        heading="Profile"
        links={[
          { name: 'Dashboard', href: "/academy" },
          { name: 'User', href: "/academy/myProfile" },
          { name: fielda.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card
          sx={{
            mb: 3,
            height: 290,
          }}
        > 
      <ProfileCover 
        role={fielda.name}
        name={fielda.name}
        avatarUrl={`http://127.0.0.1:8000/${fielda.image}`}
        coverUrl="/assets/profile-header.jpg"
        />
        <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          width: 1,
          bottom: 0,
          zIndex: 9,
          position: 'absolute',
          bgcolor: 'background.paper',
          [`& .${tabsClasses.flexContainer}`]: {
            pr: { md: 3 },
            justifyContent: {
              sm: 'center',
              md: 'flex-end',
            },
          },
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
        ))}
      </Tabs>
    </Card>
    {currentTab === 'profile' && <PersonalDetails />}
    {currentTab === 'learners' && <LearnersDetails />} 
    {/* {currentTab === 'subhosts' && <SubHostData />}
    {currentTab === 'clients' && <ClientData />}
    {currentTab === 'academy' && <AcademyData />} */}

      </Grid>
      
      </Grid>
    </>
  )
}

export default HostProfile